import React, { useState, useRef } from "react";
import SettingsButton from "../SettingsButton/SettingsButton";
import SettingsButtonSelect from "../SettingsButton/SettingsButtonSelect";
import { useOutsideClick } from "@chakra-ui/react";
const viewModeOptions = {
  "histogram view": "histogram",
  "JSON view": "JSON"
};
export const HistogramSettings = (props) => {
  const { viewMode, onViewModeChange } = props;
  const menuRef = useRef(null);
  const [openSettingsOptions, setOpenSettingsOptions] = useState(null);
  const showSettingsOptions = function(options) {
    if (openSettingsOptions === options) {
      setOpenSettingsOptions(null);
    } else {
      setOpenSettingsOptions(options);
    }
  };
  useOutsideClick({
    ref: menuRef,
    handler: () => {
      setOpenSettingsOptions(null);
    }
  });
  return /* @__PURE__ */ React.createElement("div", { ref: menuRef, className: "HistogramSettings" }, /* @__PURE__ */ React.createElement(
    SettingsButton,
    {
      icon: "view",
      open: openSettingsOptions === "viewMode",
      name: "results-view-mode",
      onClick: () => showSettingsOptions("viewMode")
    },
    viewMode,
    openSettingsOptions === "viewMode" && /* @__PURE__ */ React.createElement(
      SettingsButtonSelect,
      {
        options: viewModeOptions,
        onSelect: onViewModeChange,
        active: viewMode
      }
    )
  ));
};
