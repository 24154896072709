import React, { useContext, useMemo, useState } from "react";
import { Box, Button, Flex, Spacer, Spinner, Text } from "@chakra-ui/react";
import { Icon } from "@chakra-ui/icons";
import { IonTable } from "../shared/IonTable";
import { FilterButton } from "../shared/FilterButton";
import { BudgetMeter } from "../BudgetMeter/BudgetMeter";
import { useProject } from "./hooks/useProject";
import { MdArrowOutward, MdOutlineCancel } from "react-icons/md";
import { useOrganization } from "../shared/Organization/useOrganization";
import { IonPanel } from "../shared/IonPanel";
import { useLocation, useNavigate } from "react-router-dom";
import { useOrgMembers } from "./hooks/useOrgMembers";
import api from "../../api";
import { getNoiseModelForRow } from "../shared/getNoiseModelForRow.js";
import { sharedColumns } from "../shared/sharedColumns.js";
import { JobsRowActionPopover } from "../shared/JobsRowActionPopover.js";
import { getDisplayUserName } from "../shared/utils/utils.js";
import { IdText } from "../shared/IdText/index.js";
import { JobsEmptyView } from "../shared/JobsEmptyView.js";
import {
  cancelableItemsArray,
  selectedProjectsSet
} from "../CancelMultipleJobsConfirmationModal/cancelJobsUtils.js";
import { useProjects } from "./hooks/useProjects.js";
import { useBaseModal } from "../shared/baseModals/useBaseModal.js";
import { MODALS } from "../shared/baseModals/constants.js";
import { NameCell } from "../shared/IonTableCells/NameCell.js";
import { EmptyFilterView } from "../shared/IonTable/EmptyFilterView.js";
import { DefaultErrorView } from "../shared/IonTable/DefaultErrorView.js";
import { ProjectJobsContext } from "../../contexts/ProjectJobsContext.js";
import { createProjectJobsParamsObject } from "../../util/createProjectJobsParamsObject.js";
const projectJobsFilterOptions = [
  {
    name: "Date",
    property: "date",
    isDate: true
  },
  {
    name: "Status",
    property: "status",
    options: [
      "completed",
      "canceled",
      "failed",
      "running",
      "submitted",
      "ready"
    ]
  },
  {
    name: "ID",
    property: "id",
    label: "enter full ID"
  }
];
const PROJECT_JOBS_TABLE_KEY_LABELS = [
  {
    key: "request",
    label: "Date",
    isSortable: true
  },
  {
    key: "status",
    label: "Status"
  },
  { key: "name", label: "Name" },
  { key: "target", label: "Target" },
  {
    key: "id",
    label: "ID",
    isCopyable: true,
    getDisplay: (id) => /* @__PURE__ */ React.createElement(IdText, { id })
  },
  {
    key: "submitted_by",
    label: "Submitted by",
    isTruncated: true
  },
  {
    key: "usage",
    label: "Cost"
  },
  { key: "circuits", label: "Circuits" },
  { key: "action", label: "" }
];
export const ProjectJobs = () => {
  const { pathname } = useLocation();
  const [, projectId] = pathname.split("/").filter(Boolean);
  const navigate = useNavigate();
  const {
    jobs,
    filters,
    setFilters,
    setIsLoading,
    jobsError,
    next,
    loadMore,
    hasMore,
    isJobsLoading
  } = useContext(ProjectJobsContext);
  const [isExporting, setIsExporting] = useState(false);
  const [isCanceling, setIsCanceling] = useState(false);
  const { open: openCancelMultipleJobsConfirmation } = useBaseModal(
    MODALS.cancelMultipleJobsConfirmation
  );
  const { open: openCancelJobConfirmationModal } = useBaseModal(
    MODALS.cancelJobConfirmation
  );
  const {
    organization,
    error: organizationError,
    isLoading: isOrganizationLoading
  } = useOrganization();
  const {
    project,
    error: projectError,
    isLoading: isProjectLoading
  } = useProject();
  const {
    projects,
    isLoading: isProjectsLoading,
    error: projectsError
  } = useProjects();
  const updateFilters = (filters2) => {
    setIsLoading(true);
    setFilters(filters2);
  };
  const { orgMembers } = useOrgMembers();
  const getSubmittedBy = ({ orgMembers: orgMembers2, submittedBy }) => {
    if (!orgMembers2?.length) return "";
    const foundMember = orgMembers2.find((m) => m.id === submittedBy);
    if (!foundMember) return "Deleted member";
    return getDisplayUserName(foundMember);
  };
  const rows = useMemo(() => {
    return jobs.map((row) => {
      return {
        ...sharedColumns({
          row,
          hasAccessToPaidBackend: organization?.hasAccessToPaidBackend,
          noiseModelName: getNoiseModelForRow(row)
        }),
        ...{
          onRowClick: (project2, navigate2) => {
            navigate2(`/projects/${project2.id}/jobs/${row.id}`);
          },
          action: /* @__PURE__ */ React.createElement(JobsRowActionPopover, { job: row }),
          submitted_by: /* @__PURE__ */ React.createElement(
            NameCell,
            {
              name: getSubmittedBy({
                orgMembers,
                submittedBy: row.submitted_by
              })
            }
          )
        }
      };
    });
  }, [jobs, organization?.hasAccessToPaidBackend, orgMembers]);
  const [rowSelection, setRowSelection] = useState([]);
  const error = jobsError || projectError || projectsError || organizationError;
  if (error) return /* @__PURE__ */ React.createElement(Text, null, error);
  const selectedCancelableItems = cancelableItemsArray({ jobs, rowSelection });
  const selectedProjects = selectedProjectsSet({
    selectedCancelableItems,
    projects,
    jobs
  });
  return /* @__PURE__ */ React.createElement(Box, { pt: "24px" }, /* @__PURE__ */ React.createElement(Text, { color: "ionDark", textStyle: "heading-4" }, "Project Jobs"), /* @__PURE__ */ React.createElement(Flex, { justifyContent: "space-between", gap: 5, mt: "24px" }, /* @__PURE__ */ React.createElement(Box, { w: project.description ? "50%" : "100%" }, /* @__PURE__ */ React.createElement(BudgetMeter, null)), project.description && /* @__PURE__ */ React.createElement(Box, { w: "50%", position: "relative" }, /* @__PURE__ */ React.createElement(IonPanel, null, /* @__PURE__ */ React.createElement(Box, { h: "80px" }, /* @__PURE__ */ React.createElement(
    Text,
    {
      textStyle: "body-2",
      color: "ionDark",
      textAlign: "top"
    },
    "Project description"
  ), /* @__PURE__ */ React.createElement(Text, { noOfLines: 3, textStyle: "body-3" }, project.description))))), /* @__PURE__ */ React.createElement(Flex, { mb: "12px" }, /* @__PURE__ */ React.createElement(
    FilterButton,
    {
      filters,
      filterOptions: projectJobsFilterOptions,
      setFilters: updateFilters
    }
  ), /* @__PURE__ */ React.createElement(Spacer, null), /* @__PURE__ */ React.createElement(
    Button,
    {
      size: "md",
      variant: "grey",
      onClick: () => {
        setIsExporting(true);
        const params = createProjectJobsParamsObject({
          projectId,
          filters,
          nextParam: next
        });
        api.exportProjectJobs({
          params,
          selectedIds: Object.keys(rowSelection)
        }).then(
          () => {
            setRowSelection([]);
            setIsExporting(false);
          },
          () => setIsExporting(false)
        );
      },
      leftIcon: /* @__PURE__ */ React.createElement(
        Icon,
        {
          as: MdArrowOutward,
          width: "16px",
          height: "16px",
          color: "gray.600"
        }
      )
    },
    isExporting ? "Exporting" : "Export",
    isExporting && /* @__PURE__ */ React.createElement(Spinner, { size: "sm", ml: "0.3em" })
  ), selectedCancelableItems.length > 0 && /* @__PURE__ */ React.createElement(
    Button,
    {
      ml: "8px",
      size: "md",
      variant: "grey",
      color: "uiRed",
      leftIcon: /* @__PURE__ */ React.createElement(
        Icon,
        {
          as: MdOutlineCancel,
          width: "16px",
          height: "16px",
          color: "uiRed"
        }
      ),
      onClick: () => {
        if (selectedCancelableItems.length === 1) {
          openCancelJobConfirmationModal({
            jobId: selectedCancelableItems[0],
            ctaPreAction: () => {
              setIsCanceling(true);
            },
            ctaSuccessAction: () => {
              setRowSelection([]);
              setIsCanceling(false);
            },
            ctaErrorAction: () => {
              setIsCanceling(false);
            }
          });
          return;
        }
        openCancelMultipleJobsConfirmation({
          jobs: selectedCancelableItems,
          projects: [...selectedProjects],
          ctaPreAction: () => {
            setIsCanceling(true);
          },
          ctaSuccessAction: () => {
            setRowSelection([]);
            setIsCanceling(false);
          },
          ctaErrorAction: () => {
            setIsCanceling(false);
          }
        });
      }
    },
    isCanceling ? "Canceling" : "Cancel",
    isCanceling && /* @__PURE__ */ React.createElement(Spinner, { size: "sm", ml: "0.3em" })
  )), /* @__PURE__ */ React.createElement(
    IonTable,
    {
      isLoadingItems: isJobsLoading || isOrganizationLoading || isProjectLoading || isProjectsLoading,
      rows: error ? [] : rows,
      keyLabels: PROJECT_JOBS_TABLE_KEY_LABELS,
      onRowClick: (row) => row.onRowClick(project, navigate),
      hasMoreItems: hasMore,
      emptyView: error ? /* @__PURE__ */ React.createElement(DefaultErrorView, { error }) : Object.keys(filters).length > 0 ? /* @__PURE__ */ React.createElement(EmptyFilterView, null) : /* @__PURE__ */ React.createElement(JobsEmptyView, null),
      rowSelection,
      setRowSelection,
      isRowSelectEnabled: true,
      loadMore
    }
  ));
};
