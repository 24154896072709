import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { fetchProject, postUpdateProject } from "./utils";
const FETCH_PROJECTS_FAILED = "Could not fetch the project. Please try again later.";
export const ProjectContext = createContext();
export const ProjectProvider = ({ children, id }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [project, setProject] = useState({});
  const [error, setError] = useState(null);
  useEffect(() => {
    setIsLoading(true);
    fetchProject({
      id,
      onSuccess: (project2) => {
        setProject(project2);
        setIsLoading(false);
      },
      onError: () => {
        setError(FETCH_PROJECTS_FAILED);
        setIsLoading(false);
      }
    });
  }, [id]);
  const updateProject = useCallback(
    ({ projectId, updatedFields, onUpdateSuccess, onUpdateError }) => {
      postUpdateProject({
        id: projectId,
        updatedFields,
        onSuccess: () => {
          const updatedProject = {
            ...project,
            ...updatedFields
          };
          setProject(updatedProject);
          onUpdateSuccess();
        },
        onError: () => {
          onUpdateError();
        }
      });
    },
    [project]
  );
  const value = useMemo(
    () => ({
      project,
      isLoading,
      error,
      updateProject
    }),
    [project, isLoading, error, updateProject]
  );
  return /* @__PURE__ */ React.createElement(ProjectContext.Provider, { value }, children);
};
export const useProject = () => {
  const context = useContext(ProjectContext);
  if (!context) {
    throw new Error("useProject must be used within a ProjectProvider");
  }
  return context;
};
