import { useCallback, useEffect, useState } from "react";
import {
  fetchOrgInvites,
  postSendInvites,
  resendOrgInvite,
  revokeInvite
} from "./utils";
import { getStandardError } from "../../shared/utils/utils";
export const useOrgInvites = ({ isOwner }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [orgInvites, setOrgInvites] = useState([]);
  const [error, setError] = useState(null);
  useEffect(() => {
    if (!isOwner) {
      setIsLoading(false);
      return;
    }
    if (isOwner) {
      setIsLoading(true);
      fetchOrgInvites({
        onSuccess: (res) => {
          setIsLoading(false);
          setOrgInvites(res);
        },
        onError: (e) => {
          setIsLoading(false);
          setError(getStandardError(e));
        }
      });
    }
  }, [isOwner]);
  const inviteOrgMembers = useCallback(
    ({ userIds, onAddSuccess, onAddError }) => {
      postSendInvites({
        userIds,
        onSuccess: (newOrgInvites) => {
          setOrgInvites([...orgInvites, ...newOrgInvites]);
          onAddSuccess();
        },
        onError: (e) => onAddError(getStandardError(e))
      });
    },
    [orgInvites]
  );
  const removeInvite = useCallback(
    ({ userId, onRemoveSuccess, onRemoveError }) => {
      revokeInvite({
        userId,
        onSuccess: () => {
          setOrgInvites(
            orgInvites.filter(
              (orgInvite) => orgInvite.id !== userId
            )
          );
          onRemoveSuccess();
        },
        onError: (e) => onRemoveError(getStandardError(e))
      });
    },
    [orgInvites]
  );
  const resendInvite = useCallback(
    ({ userId, onResendSuccess, onResendError }) => {
      resendOrgInvite({
        userId,
        onSuccess: onResendSuccess,
        onError: (e) => onResendError(getStandardError(e))
      });
    },
    []
  );
  return {
    resendInvite,
    removeInvite,
    inviteOrgMembers,
    orgInvites,
    error,
    isLoading
  };
};
