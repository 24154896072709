import React, { useRef } from "react";
import {
  AbsoluteCenter,
  Box,
  Center,
  Flex,
  Spacer,
  Spinner,
  Text,
  useClipboard,
  useToast
} from "@chakra-ui/react";
import { IonPanel } from "../shared/IonPanel";
import UnknownCircuitOutput from "./JobUnknownCircuitOutput";
import JobResults from "./JobResults";
import AceEditor from "react-ace";
import Console from "../Console/Console";
import { useJob } from "../CancelJobConfirmationModal/useJob";
import { Link, useLocation } from "react-router-dom";
import { useJobProject } from "../Projects/hooks/useJobProject";
import { useJobResults } from "../Projects/hooks/useJobResults";
import { useJobProgram } from "../Projects/hooks/useJobProgram";
import { IonVisualizer } from "../IonVisualizer/IonVisualizer";
import {
  getIonDateFormat,
  getTooltipFormattedDate,
  USD,
  MillisecondTime
} from "../../util/format";
import { JobStatusTag } from "../shared/JobStatusTag";
import { JobDetailResultsPopover } from "./JobDetailResultsPopover";
import downloadCode from "../../lib/downloadCode";
import { IonToast } from "../shared/IonToast";
import { PageContainer } from "../shared/PageContainer/index.js";
import { IonTooltip } from "../shared/IonTooltip/index.js";
const PanelRow = ({ label, value }) => /* @__PURE__ */ React.createElement(Flex, { m: "8px 0", _notFirst: { mt: "10px" } }, /* @__PURE__ */ React.createElement(Center, null, /* @__PURE__ */ React.createElement(
  Text,
  {
    as: "span",
    ml: "8px",
    textStyle: "body-1",
    color: "gray.600",
    w: "170px"
  },
  label
), /* @__PURE__ */ React.createElement(
  Text,
  {
    as: "span",
    textStyle: "body-8",
    color: "gray.800",
    maxWidth: "700px",
    wordBreak: "break-all"
  },
  value
)));
export const MyJobDetail = () => {
  const location = useLocation();
  const jobId = location.pathname.split("/").filter(Boolean).pop();
  const { job, isLoading: isJobLoading, error: jobError } = useJob(jobId);
  const {
    project,
    isLoading: isProjectLoading,
    error: projectError
  } = useJobProject(job?.project_id);
  const {
    results,
    isLoading: isResultsLoading,
    error: resultsError
  } = useJobResults({ jobId, shouldFetchResults: job?.status === "completed" });
  const {
    program,
    isLoading: isProgramLoading,
    error: programError
  } = useJobProgram(jobId);
  const toast = useToast();
  const toastIdRef = useRef();
  const { onCopy } = useClipboard(JSON.stringify(results));
  if (programError || jobError || resultsError || projectError) {
    return /* @__PURE__ */ React.createElement(AbsoluteCenter, null, /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3", color: "uiRed" }, programError || jobError || resultsError || projectError));
  }
  if (isProgramLoading || isJobLoading || isResultsLoading || isProjectLoading) {
    return /* @__PURE__ */ React.createElement(AbsoluteCenter, null, /* @__PURE__ */ React.createElement(Spinner, null));
  }
  return /* @__PURE__ */ React.createElement(PageContainer, null, /* @__PURE__ */ React.createElement(
    Text,
    {
      as: "span",
      color: "uiBlue",
      fontStyle: "heading-4",
      fontWeight: 500,
      mr: "4px"
    },
    /* @__PURE__ */ React.createElement(Link, { to: "/jobs/" }, "Jobs")
  ), /* @__PURE__ */ React.createElement(Text, { color: "gray.600", fontStyle: "heading-4", mr: "4px", as: "span" }, "/"), /* @__PURE__ */ React.createElement(Text, { color: "ionDark", fontStyle: "heading-4", as: "span" }, job.id), /* @__PURE__ */ React.createElement(Box, { mt: "24px" }, /* @__PURE__ */ React.createElement(Flex, { w: "100%", mb: "12px" }, /* @__PURE__ */ React.createElement(
    Text,
    {
      textStyle: "heading-5",
      mb: "8px",
      color: "gray.800",
      lineHeight: "38px"
    },
    "Job Summary"
  ), /* @__PURE__ */ React.createElement(Spacer, null), job.status !== "failed" && job.status !== "canceled" && /* @__PURE__ */ React.createElement(
    JobDetailResultsPopover,
    {
      menuItems: [
        {
          key: "download",
          node: "Download as JSON",
          onClick: () => {
            downloadCode(
              JSON.stringify(results),
              job.id || `${job.name}`,
              "json"
            );
          }
        },
        {
          key: "copy",
          node: "Copy to clipboard",
          onClick: () => {
            onCopy();
            toastIdRef.current = toast({
              duration: 5e3,
              position: "bottom-left",
              render: () => /* @__PURE__ */ React.createElement(
                IonToast,
                {
                  message: "Results copied to clipboard",
                  status: "success",
                  toast,
                  toastIdRef
                }
              )
            });
          }
        }
      ]
    }
  )), /* @__PURE__ */ React.createElement(IonPanel, null, /* @__PURE__ */ React.createElement(PanelRow, { label: "Job Name", value: job.name }), /* @__PURE__ */ React.createElement(PanelRow, { label: "Job ID", value: job.id }), /* @__PURE__ */ React.createElement(PanelRow, { label: "Project Name", value: project.name }), /* @__PURE__ */ React.createElement(PanelRow, { label: "Project ID", value: project.id }), /* @__PURE__ */ React.createElement(
    PanelRow,
    {
      label: "Status",
      value: /* @__PURE__ */ React.createElement(JobStatusTag, { status: job.status })
    }
  ), /* @__PURE__ */ React.createElement(PanelRow, { label: "Target", value: job.target }), job.noise ? /* @__PURE__ */ React.createElement(
    PanelRow,
    {
      label: "Noise Model",
      value: job.noise.model
    }
  ) : null, job.noise?.seed ? /* @__PURE__ */ React.createElement(PanelRow, { label: "Noise Seed", value: job.noise.seed }) : null, /* @__PURE__ */ React.createElement(PanelRow, { label: "Circuits", value: job.circuits ?? "1" }), /* @__PURE__ */ React.createElement(
    PanelRow,
    {
      label: "Cost",
      value: USD.format(job.cost_usd ?? 0)
    }
  ), /* @__PURE__ */ React.createElement(
    PanelRow,
    {
      label: "Requested",
      value: job.request ? /* @__PURE__ */ React.createElement(
        IonTooltip,
        {
          label: /* @__PURE__ */ React.createElement(Text, { textStyle: "body-6" }, `${getTooltipFormattedDate(
            job.request
          )} ago`)
        },
        getIonDateFormat(job.request)
      ) : void 0
    }
  ), /* @__PURE__ */ React.createElement(
    PanelRow,
    {
      label: "Completed",
      value: job.response ? /* @__PURE__ */ React.createElement(
        IonTooltip,
        {
          label: /* @__PURE__ */ React.createElement(Text, { textStyle: "body-6" }, `${getTooltipFormattedDate(
            job.response
          )} ago`)
        },
        getIonDateFormat(job.response)
      ) : void 0
    }
  ), /* @__PURE__ */ React.createElement(
    PanelRow,
    {
      label: "Executed in",
      value: /* @__PURE__ */ React.createElement(MillisecondTime, { time: job.execution_time })
    }
  ), /* @__PURE__ */ React.createElement(
    PanelRow,
    {
      label: "Predicted",
      value: /* @__PURE__ */ React.createElement(
        MillisecondTime,
        {
          time: job.predicted_execution_time
        }
      )
    }
  ))), job.metadata && /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(Text, { textStyle: "heading-5", mb: "8px", color: "gray.800" }, "Metadata"), /* @__PURE__ */ React.createElement(IonPanel, null, Object.keys(job.metadata).map((key) => {
    return /* @__PURE__ */ React.createElement(
      PanelRow,
      {
        key,
        label: key,
        value: JSON.stringify(job.metadata[key])
      }
    );
  }))), (job.circuits === 1 || job.children?.length === 0) && /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(Text, { textStyle: "heading-5", mb: "8px", color: "gray.800" }, "Results"), /* @__PURE__ */ React.createElement(
    Box,
    {
      bg: "white",
      boxShadow: "0 0 30px 0 rgba(0, 0, 0, 0.04)",
      border: "1px solid #E8E8E8",
      borderRadius: "5px",
      mb: "64px"
    },
    /* @__PURE__ */ React.createElement("div", null, !results && /* @__PURE__ */ React.createElement(UnknownCircuitOutput, { status: job.status }), results && /* @__PURE__ */ React.createElement(JobResults, { job, results }))
  )), (job.circuits === 1 || job.children?.length === 0) && /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(Text, { textStyle: "heading-5", mb: "8px", color: "gray.800" }, "Program"), /* @__PURE__ */ React.createElement(
    Box,
    {
      bg: "white",
      boxShadow: "0 0 30px 0 rgba(0, 0, 0, 0.04)",
      border: "1px solid #E8E8E8",
      borderRadius: "5px",
      mb: "24px"
    },
    program.body && /* @__PURE__ */ React.createElement(Flex, null, /* @__PURE__ */ React.createElement(Box, { w: "50%" }, /* @__PURE__ */ React.createElement(
      AceEditor,
      {
        setOptions: {
          useWorker: false
        },
        value: JSON.stringify(
          program.body.circuit,
          null,
          4
        ),
        mode: program.lang,
        theme: "ionq",
        readOnly: true,
        name: "CodeDisplay",
        editorProps: {
          $blockScrolling: true
        },
        wrapEnabled: true,
        fontSize: 16,
        style: {
          width: "100%",
          height: "250px",
          fontFamily: "'Ubuntu Mono', monospace"
        }
      }
    )), /* @__PURE__ */ React.createElement(Box, { w: "50%" }, /* @__PURE__ */ React.createElement(
      IonVisualizer,
      {
        title: job.name || "Untitled",
        visualizerHeight: "250px",
        code: program.body,
        language: program.lang,
        job,
        script: program,
        results
      }
    )))
  )), /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(Text, { textStyle: "heading-5", mb: "8px", color: "gray.800" }, "Output"), /* @__PURE__ */ React.createElement(
    Box,
    {
      bg: "white",
      boxShadow: "0 0 30px 0 rgba(0, 0, 0, 0.04)",
      border: "1px solid #E8E8E8",
      borderRadius: "5px",
      mb: "64px"
    },
    /* @__PURE__ */ React.createElement(
      Console,
      {
        removeBottomPadding: true,
        consoleHeight: null,
        consoleData: job
      }
    )
  )));
};
