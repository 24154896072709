import React from "react";
import getPreviewValueView from "./utils/getPreviewValueView";
const ArrayPreview = ({ array }) => {
  const preview = array.map((value, i) => {
    const outputValue = getPreviewValueView(i, value);
    if (i === 0) {
      return /* @__PURE__ */ React.createElement("span", { key: `${outputValue}-${i}` }, outputValue);
    } else {
      return /* @__PURE__ */ React.createElement("span", { key: `${outputValue}-${i}` }, /* @__PURE__ */ React.createElement("span", { className: "punctuation -comma" }, ","), " ", outputValue);
    }
  });
  return preview;
};
export default ArrayPreview;
