import React from "react";
import Tooltip from "./HistogramTooltip";
import Line from "./HistogramLine";
const HistogramLinesAndTooltips = ({
  histogram = {},
  qubitCount,
  shotCount,
  measurements
}) => {
  let lines = [];
  let tooltips = [];
  const tallestResultValue = 1 / [...Object.values(histogram)].reduce(
    (a, b) => a > b ? a : b,
    -Infinity
  );
  Object.keys(histogram).forEach((key) => {
    const histogramKey = key;
    const histogramValue = histogram[histogramKey];
    if (histogramValue * 1e4 < 0.1) return;
    const totalPossibleValuesInBaseTen = Math.pow(2, qubitCount);
    const keyInBaseTen = +histogramKey;
    const keyInBinary = keyInBaseTen.toString(2).padStart(qubitCount, "0");
    const valueInShots = shotCount ? Math.round(histogramValue * shotCount) + " \u2014 " : "";
    const linePeriod = (measurements.graphWidth - 1) / (totalPossibleValuesInBaseTen - 1);
    const lineXPosition = measurements.horizontalPadding + linePeriod * keyInBaseTen;
    let lineHeight = histogramValue * measurements.graphHeight * tallestResultValue;
    if (lineHeight < 5) lineHeight = 5;
    const lineYPosition = measurements.baselineYValue - lineHeight;
    let barWidth = (measurements.graphWidth - 1) / (totalPossibleValuesInBaseTen - 1);
    if (barWidth > 10) {
      barWidth = 10;
    }
    if (barWidth < 5) {
      barWidth = 5;
    }
    const tooltipMessage = keyInBinary + " ~ " + valueInShots + Math.round(histogramValue * 100 * 100) / 100 + `%`;
    const tooltipWidth = tooltipMessage.length * measurements.tooltipLetterWidth + measurements.tooltipHorizontalPadding * 2;
    const tooltipHoverArea = barWidth * 2 > linePeriod ? barWidth * 2 : linePeriod;
    let tooltipTextXPosition;
    let tooltipRectangleXPosition;
    if (lineXPosition - tooltipWidth / 2 < measurements.horizontalPadding) {
      tooltipTextXPosition = tooltipWidth / 2;
      tooltipRectangleXPosition = 0;
    } else if (lineXPosition + tooltipWidth / 2 > measurements.svgWidth) {
      tooltipTextXPosition = measurements.svgWidth - tooltipWidth / 2;
      tooltipRectangleXPosition = measurements.svgWidth - tooltipWidth;
    } else {
      tooltipTextXPosition = lineXPosition;
      tooltipRectangleXPosition = lineXPosition - tooltipWidth / 2;
    }
    lines.push(
      /* @__PURE__ */ React.createElement(
        Line,
        {
          key,
          histogramKey,
          lineXPosition,
          measurements,
          lineYPosition,
          barWidth
        }
      )
    );
    tooltips.push(
      /* @__PURE__ */ React.createElement(
        Tooltip,
        {
          key,
          histogramKey,
          lineXPosition,
          measurements,
          tooltipRectangleXPosition,
          tooltipWidth,
          tooltipTextXPosition,
          tooltipMessage,
          tooltipHoverArea
        }
      )
    );
  });
  return /* @__PURE__ */ React.createElement("g", null, lines, tooltips);
};
export default HistogramLinesAndTooltips;
