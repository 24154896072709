import { useState, useEffect } from "react";
import { useApi } from "../../../Api/ApiProvider";
export const useJobProject = (projectId) => {
  const [isLoading, setIsLoading] = useState(true);
  const [project, setProject] = useState([{}]);
  const [error, setError] = useState(null);
  const { Api, isLoading: isApiLoading } = useApi();
  useEffect(() => {
    if (!projectId || isApiLoading) return;
    setIsLoading(true);
    Api.getProject({
      projectId,
      onSuccess: (res) => {
        setProject(res);
        setIsLoading(false);
      },
      onError: (e) => {
        setError(e);
        setIsLoading(false);
      }
    });
  }, [projectId, Api, isApiLoading]);
  return {
    project,
    error,
    isLoading
  };
};
