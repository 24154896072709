import React from "react";
import JSONNestedNode from "./JSONNestedNode";
function createItemString(data, limit) {
  let count = 0;
  let hasMore = false;
  if (Number.isSafeInteger(data.size)) {
    count = data.size;
  } else {
    for (const entry of data) {
      if (limit && count + 1 > limit) {
        hasMore = true;
        break;
      }
      count += 1;
    }
  }
  return ` ${hasMore ? ">" : ""}${count} ${count !== 1 ? "entries" : "entry"}`;
}
export default function JSONIterableNode({ ...props }) {
  return /* @__PURE__ */ React.createElement(
    JSONNestedNode,
    {
      ...props,
      nodeType: "Iterable",
      createItemString
    }
  );
}
