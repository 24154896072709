import { Link } from "@chakra-ui/react";
import React from "react";
export const LinkButtonLarge = ({ text, isExternal, href }) => /* @__PURE__ */ React.createElement(
  Link,
  {
    isExternal: !!isExternal,
    href,
    p: "8px 12px",
    _active: {
      bg: "uiDarkBlue"
    },
    _hover: {
      bg: "uiDarkBlue"
    },
    color: "white",
    backgroundColor: "uiBlue",
    _disabled: {
      _hover: {
        bg: "uiBlue !important"
      }
    },
    fontWeight: "500",
    lineHeight: "1.375rem",
    fontFamily: "Colfaxx",
    maxHeight: "38px",
    borderRadius: "6px",
    fontSize: "0.9375rem"
  },
  text
);
