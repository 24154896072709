import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  Heading,
  Input,
  Text,
  Textarea,
  useToast
} from "@chakra-ui/react";
import { Formik } from "formik";
import { useBaseModal } from "../shared/baseModals/useBaseModal";
import { MODALS } from "../shared/baseModals/constants";
import { IonPanel } from "../shared/IonPanel";
import { IonPanelHeading } from "../shared/IonPanelHeading";
import { IonDivider } from "../shared/IonDivider";
import { IonLabelHeading } from "../shared/IonLabelHeading";
import { IonLabelBeneath } from "../shared/IonLabelBeneath";
import { useProject } from "./hooks/useProject";
import { IonToast } from "../shared/IonToast";
import { isNameInvalid } from "../../util/project";
const ARCHIVE_WARNING = "Archiving a project prevents further jobs from being submitted to it by any members. The Project will still be accessible, and the jobs (and their results) will still be viewable.";
const UNARCHIVE_WARNING = "By unarchiving a project, project members will be able to submit jobs to it. Organization owner can archive the project again at any time.";
const UPDATE_PROJECT_ERROR = "Error updating project. Please try again later.";
const ArchiveProjectPanel = () => {
  const { project, updateProject } = useProject();
  const { isArchived, id, name } = project;
  const { open: openArchiveProject } = useBaseModal(
    MODALS.archiveProjectConfirmation
  );
  const { open: openUnarchiveProject } = useBaseModal(
    MODALS.unarchiveProjectConfirmation
  );
  return /* @__PURE__ */ React.createElement(IonPanel, null, /* @__PURE__ */ React.createElement(IonPanelHeading, null, `${isArchived ? "Unarchive" : "Archive"} project`), /* @__PURE__ */ React.createElement(IonDivider, { mt: "16px", mb: "16px" }), /* @__PURE__ */ React.createElement(Text, { mb: "16px", color: "#333", fontSize: "0.875rem", maxW: "600px" }, isArchived ? UNARCHIVE_WARNING : ARCHIVE_WARNING), !isArchived && /* @__PURE__ */ React.createElement(Text, { mb: "16px", color: "#333", fontSize: "0.875rem" }, "Note: Any jobs currently in queue will be cancelled."), /* @__PURE__ */ React.createElement(
    Button,
    {
      onClick: () => (isArchived ? openUnarchiveProject : openArchiveProject)({
        projectId: id,
        projectName: name,
        updateProject
      }),
      variant: "red",
      w: "320px",
      size: "lg",
      fontSize: "1rem"
    },
    isArchived ? "Unarchive" : "Archive",
    " this project"
  ));
};
export const ProjectSettings = () => {
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [updateError, setUpdateError] = useState(null);
  const toast = useToast();
  const toastIdRef = useRef();
  const {
    project,
    error,
    isLoading: projectIsLoading,
    updateProject
  } = useProject();
  if (projectIsLoading) return /* @__PURE__ */ React.createElement(Text, null, "Loading\u2026");
  if (error) return /* @__PURE__ */ React.createElement(Text, null, error);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    Heading,
    {
      fontFamily: "Colfaxx",
      fontWeight: 500,
      fontSize: "1.0625rem",
      color: "#333",
      mb: "24px",
      mt: "24px"
    },
    "Project Settings"
  ), /* @__PURE__ */ React.createElement(IonPanel, null, /* @__PURE__ */ React.createElement(IonPanelHeading, null, "Basic Information"), /* @__PURE__ */ React.createElement(IonDivider, null), /* @__PURE__ */ React.createElement(
    Formik,
    {
      initialValues: {
        name: project?.name || "",
        id: project?.id || "",
        description: project?.description || ""
      },
      enableReinitialize: true,
      onSubmit: (values) => {
        setIsUpdateLoading(true);
        updateProject({
          projectId: project.id,
          updatedFields: {
            name: values.name,
            description: values.description
          },
          onUpdateSuccess: () => {
            setIsUpdateLoading(false);
            toastIdRef.current = toast({
              duration: 5e3,
              position: "bottom-left",
              render: () => /* @__PURE__ */ React.createElement(
                IonToast,
                {
                  message: `Project ${values.name} has been updated`,
                  status: "success",
                  toast,
                  toastIdRef
                }
              )
            });
          },
          onUpdateError: () => {
            setIsUpdateLoading(false);
            setUpdateError(UPDATE_PROJECT_ERROR);
          }
        });
      }
    },
    ({
      dirty,
      values,
      handleBlur,
      handleChange,
      handleSubmit
    }) => {
      const isCtaDisabled = values?.name === "" || !dirty;
      return /* @__PURE__ */ React.createElement("form", { onSubmit: handleSubmit }, /* @__PURE__ */ React.createElement(Box, { mb: "16px" }, /* @__PURE__ */ React.createElement(IonLabelHeading, { title: "Project name" }), /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "name",
          type: "text",
          placeholder: "My First Project",
          maxWidth: "400px",
          fontSize: "0.875rem",
          onChange: handleChange,
          onBlur: handleBlur,
          value: values?.name,
          isInvalid: dirty && isNameInvalid(values?.name),
          isDisabled: project.isArchived
        }
      ), dirty && isNameInvalid(values?.name) && /* @__PURE__ */ React.createElement(
        Text,
        {
          pt: 2,
          color: "red",
          textStyle: "body-6",
          maxWidth: "400px"
        },
        "Project name is required."
      ), /* @__PURE__ */ React.createElement(
        IonLabelBeneath,
        {
          title: "Changing project name won\u2019t change project\n                                ID"
        }
      )), /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(IonLabelHeading, { title: "Project description" }), /* @__PURE__ */ React.createElement(
        Textarea,
        {
          name: "description",
          fontSize: "0.875rem",
          type: "text",
          placeholder: "First Quantum Project",
          maxWidth: "400px",
          onChange: handleChange,
          onBlur: handleBlur,
          value: values?.description,
          isDisabled: project.isArchived,
          maxLength: 150
        }
      ), /* @__PURE__ */ React.createElement(
        IonLabelBeneath,
        {
          title: `Max 150 characters: ${values?.description?.length || 0}/150`
        }
      )), updateError && /* @__PURE__ */ React.createElement(Text, { pt: 2, color: "red" }, updateError), /* @__PURE__ */ React.createElement(
        Button,
        {
          mt: 2,
          isLoading: isUpdateLoading,
          onClick: handleSubmit,
          variant: "blue",
          w: "320px",
          size: "lg",
          type: "submit",
          lineHeight: "38px",
          isDisabled: project.isArchived || isCtaDisabled || dirty && isNameInvalid(values?.name)
        },
        "Update project information"
      ));
    }
  )), /* @__PURE__ */ React.createElement(ArchiveProjectPanel, null));
};
