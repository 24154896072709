import React, { useState } from "react";
import download from "../../lib/downloadCode";
import SettingsButton from "../SettingsButton/SettingsButton";
import SettingsButtonSelect from "../SettingsButton/SettingsButtonSelect";
import copy from "copy-to-clipboard";
const ConsoleSettings = (props) => {
  const [openSettingsOptions, setOpenSettingsOptions] = useState(null);
  const [copiedMessageIsVisible, setCopiedMessageIsVisible] = useState(false);
  const showSettingsOptions = (options) => {
    if (JSON.stringify(openSettingsOptions) === JSON.stringify(options)) {
      setOpenSettingsOptions(null);
    } else {
      setOpenSettingsOptions(options);
    }
  };
  const downloadConsoleOutput = (style) => {
    const output = JSON.stringify(props.consoleData, null, "  ");
    if (style === "JSON") {
      download(
        output,
        props.consoleData.id || `${props.consoleData.name} - simulation`,
        "json"
      );
    } else if (style === "clipboard") {
      copy(output);
      setCopiedMessageIsVisible(true);
      setTimeout(() => {
        setCopiedMessageIsVisible(false);
      }, 1500);
    }
  };
  let { consoleData } = props;
  consoleData = consoleData || {};
  const circuitViewModeOptions = {
    "explorer view": "explorer",
    "JSON view": "JSON"
  };
  const autoRunViewModeOptions = {
    "histogram view": "histogram",
    "explorer view": "explorer",
    "JSON view": "JSON"
  };
  const downloadOptions = {
    "copy to clipboard": "clipboard",
    "download as JSON": "JSON"
  };
  let viewModeOptions = circuitViewModeOptions;
  if (consoleData.simulated) {
    viewModeOptions = autoRunViewModeOptions;
  }
  return /* @__PURE__ */ React.createElement("div", { className: "ConsoleSettings" }, /* @__PURE__ */ React.createElement(
    SettingsButton,
    {
      icon: "view",
      open: openSettingsOptions === "viewMode",
      name: "visualizer-view-mode",
      onClick: () => {
        showSettingsOptions("viewMode");
      }
    },
    props?.viewMode,
    openSettingsOptions === "viewMode" && /* @__PURE__ */ React.createElement(
      SettingsButtonSelect,
      {
        options: viewModeOptions,
        onSelect: props?.setViewMode,
        active: props?.viewMode
      }
    )
  ), /* @__PURE__ */ React.createElement(
    SettingsButton,
    {
      icon: "download",
      open: openSettingsOptions === "download",
      name: "download-editor-text",
      onClick: () => {
        showSettingsOptions("download");
      },
      onMouseLeave: () => setCopiedMessageIsVisible(false)
    },
    copiedMessageIsVisible && /* @__PURE__ */ React.createElement("span", { className: "copied-message" }, "copied"),
    "download",
    openSettingsOptions === "download" && /* @__PURE__ */ React.createElement(
      SettingsButtonSelect,
      {
        options: downloadOptions,
        onSelect: downloadConsoleOutput
      }
    )
  ));
};
export default ConsoleSettings;
