import React, { useState } from "react";
import {
  Box,
  Input,
  InputGroup,
  InputLeftAddon,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Textarea
} from "@chakra-ui/react";
import { Formik } from "formik";
import { useBaseModal } from "../../shared/baseModals/useBaseModal";
import { MODALS } from "../../shared/baseModals/constants";
import { BaseModal, BaseModalBody, BaseModalFooter } from "../../shared/Modal";
import {
  getUnformattedNumber,
  isBudgetInvalid,
  isBudgetValid,
  isNameInvalid,
  isNameValid
} from "../../../util/project";
import { NumericFormat } from "react-number-format";
import { useStatus } from "../../shared/Status/useStatus";
import { useNavigate } from "react-router-dom";
const budgetError = "Limited budget must be greater than or equal to 0";
const nameError = "Project name is required";
const projectCharactersError = "Project names can only contain letters, numbers, spaces, and the following special characters: ()@&+:._-\u2019,";
export const CreateProjectModal = ({ createProject }) => {
  const [isSubmitting, setIsSubmitting] = useState(null);
  const [submitError, setSubmitError] = useState(null);
  const navigate = useNavigate();
  const { isOpen, close } = useBaseModal(MODALS.createProject);
  const { data: status } = useStatus();
  const { isFreeAccess } = status || {};
  const handleModalClose = () => {
    setSubmitError(null);
    setIsSubmitting(false);
    close();
  };
  const handleSubmit = ({ name, description, quotaLimitUsd }) => {
    setIsSubmitting(true);
    createProject({
      name,
      description,
      quotaLimitUsd,
      onCreateSuccess: (newProject) => {
        setIsSubmitting(false);
        handleModalClose();
        navigate(`/projects/${newProject.id}`);
      },
      onCreateError: (error) => {
        setIsSubmitting(false);
        setSubmitError(error);
      }
    });
  };
  return /* @__PURE__ */ React.createElement(
    BaseModal,
    {
      title: "Create new project",
      isOpen,
      close: handleModalClose
    },
    /* @__PURE__ */ React.createElement(BaseModalBody, { errorBannerContent: submitError }, /* @__PURE__ */ React.createElement(
      Formik,
      {
        initialValues: {
          name: "",
          id: "",
          description: "",
          budget: 0,
          radio: "1"
        }
      },
      ({ values, touched, handleBlur, handleChange }) => /* @__PURE__ */ React.createElement("form", null, /* @__PURE__ */ React.createElement(Text, { textStyle: "body-2", mb: "4px", color: "ionDark" }, "Project name*"), /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "name",
          isInvalid: touched.name && isNameInvalid(values.name),
          textStyle: "body-3",
          type: "text",
          placeholder: "My First Project",
          maxWidth: "400px",
          fontSize: "sm",
          onChange: handleChange,
          onBlur: handleBlur,
          value: values.name,
          mb: "4px"
        }
      ), touched.name && isNameInvalid(values.name) && /* @__PURE__ */ React.createElement(Text, { textStyle: "body-6", color: "uiRed" }, values.name.trim() ? projectCharactersError : nameError), /* @__PURE__ */ React.createElement(Box, { display: isFreeAccess ? "none" : "initial" }, /* @__PURE__ */ React.createElement(
        Text,
        {
          mt: "24px",
          textStyle: "body-2",
          color: "ionDark",
          mb: "8px"
        },
        "Project budget*"
      ), /* @__PURE__ */ React.createElement(RadioGroup, { name: "radio", defaultValue: "1" }, /* @__PURE__ */ React.createElement(Stack, null, /* @__PURE__ */ React.createElement(
        Radio,
        {
          size: "sm",
          value: "1",
          onChange: handleChange,
          onBlur: handleBlur
        },
        /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3" }, "Unlimited")
      ), /* @__PURE__ */ React.createElement(
        Text,
        {
          textStyle: "body-3",
          color: "ionDark"
        },
        "Do not set a budget limit for this project. Users can submit QPU jobs as long as the organization has remaining budget."
      ), /* @__PURE__ */ React.createElement(
        Radio,
        {
          size: "sm",
          mt: "16px",
          value: "2",
          onChange: handleChange,
          onBlur: handleBlur
        },
        /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3" }, "Limited")
      ), /* @__PURE__ */ React.createElement(
        Text,
        {
          textStyle: "body-3",
          color: "ionDark"
        },
        "Users can run jobs until the limit is hit for the project.",
        /* @__PURE__ */ React.createElement(
          Text,
          {
            fontStyle: "italic",
            as: "span",
            textStyle: "body-3",
            ml: "0.5rem"
          },
          "Note: a budget of $0.00 translates to no available budget, meaning QPU jobs cannot be run."
        )
      ))), /* @__PURE__ */ React.createElement(InputGroup, { mt: "8px" }, /* @__PURE__ */ React.createElement(
        InputLeftAddon,
        {
          bg: values.radio === "1" ? "white" : "gray.50",
          borderColor: values.radio === "1" ? "gray.100" : "gray.200",
          color: values.radio === "1" ? "gray.400" : "gray.600"
        },
        "$"
      ), /* @__PURE__ */ React.createElement(
        NumericFormat,
        {
          bg: values.radio === "1" ? "gray.50" : "white",
          customInput: Input,
          fixedDecimalScale: true,
          thousandSeparator: true,
          allowLeadingZeros: true,
          decimalScale: 2,
          textStyle: "body-3",
          isInvalid: touched.name && isBudgetInvalid(values.budget),
          isDisabled: values.radio === "1",
          name: "budget",
          onChange: handleChange,
          onBlur: handleBlur,
          value: values.budget,
          errorBorderColor: "uiRed",
          min: 0,
          color: values.radio === "1" ? "gray.500" : "gray.600",
          maxWidth: "356px",
          type: "string",
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          borderColor: "gray.200"
        }
      )), touched.budget && isBudgetInvalid(values.budget) && /* @__PURE__ */ React.createElement(
        Text,
        {
          mt: "4px",
          textStyle: "body-6",
          color: "uiRed"
        },
        budgetError
      )), /* @__PURE__ */ React.createElement(
        Text,
        {
          color: "ionDark",
          mt: "24px",
          mb: "4px",
          textStyle: "body-2"
        },
        "Project description"
      ), /* @__PURE__ */ React.createElement(
        Textarea,
        {
          size: "md",
          name: "description",
          type: "text",
          textStyle: "body-3",
          color: "ionDark",
          placeholder: "First Quantum Project",
          maxWidth: "400px",
          maxLength: "150",
          onChange: handleChange,
          onBlur: handleBlur,
          value: values.description,
          borderColor: "gray.200"
        }
      ), /* @__PURE__ */ React.createElement(Text, { mt: "4px", textStyle: "body-6", color: "gray.600" }, "Max 150 characters"), /* @__PURE__ */ React.createElement(
        BaseModalFooter,
        {
          isLoading: isSubmitting,
          isCtaDisabled: !touched.name || isNameValid(values.name) === false || isBudgetValid(values.budget) === false,
          onClose: handleModalClose,
          onCtaClick: () => {
            handleSubmit({
              name: values.name,
              description: values.description,
              quotaLimitUsd: values.radio === "1" ? null : getUnformattedNumber(
                values.budget
              )
            });
          },
          ctaLabel: "Create project"
        }
      ))
    ))
  );
};
