import Papa from "papaparse";
const localizeDateTime = ({ date, locale = "en", explicitFormat }) => new Intl.DateTimeFormat(locale, {
  ...explicitFormat
}).format(date);
const DEFAULT_EXPLICIT_FORMAT = {
  weekday: "short",
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  timeZoneName: "short",
  hour12: false
};
const YMD_DATE_EXPLICIT_FORMAT = {
  locale: "sv",
  explicitFormat: {
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
  }
};
const YMDHMS_DATE_EXPLICIT_FORMAT = {
  locale: "en-US",
  explicitFormat: {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "short",
    hourCycle: "h23"
  }
};
export const DATE_FORMAT_OPTIONS = {
  YMD: YMD_DATE_EXPLICIT_FORMAT,
  YMDHMS: YMDHMS_DATE_EXPLICIT_FORMAT
};
export const formatDateString = (timestampUnix, { timeZone } = {}) => {
  const timestampUTC = new Date(timestampUnix * 1e3);
  const formatOptionOverwrites = timeZone ? { timeZone } : {};
  return localizeDateTime({
    date: timestampUTC,
    explicitFormat: {
      ...DEFAULT_EXPLICIT_FORMAT,
      ...formatOptionOverwrites
    }
  });
};
export const formatUnixToDateString = (timestampUnix, displayOption) => {
  const timestampUTC = new Date(timestampUnix * 1e3);
  const {
    locale = "en",
    explicitFormat: explicitFormatOverwrite = DEFAULT_EXPLICIT_FORMAT
  } = displayOption;
  return localizeDateTime({
    date: timestampUTC,
    locale,
    explicitFormat: explicitFormatOverwrite
  });
};
export const formatISOStringToDateString = (isoString, displayOption) => {
  const timestampUTC = new Date(isoString);
  const {
    locale = "en",
    explicitFormat: explicitFormatOverwrite = DEFAULT_EXPLICIT_FORMAT
  } = displayOption;
  return localizeDateTime({
    date: timestampUTC,
    locale,
    explicitFormat: explicitFormatOverwrite
  });
};
export const parseCsv = (csvString, headers) => {
  return new Promise((resolve, reject) => {
    let checkKeys = true;
    const data = [];
    const step = (results, parser) => {
      if (!checkKeys) return data.push(results.data);
      parser.pause();
      const matched = headers.every(
        (k) => results.meta.fields.includes(k)
      );
      if (!matched) return parser.abort();
      parser.resume();
      data.push(results.data);
      checkKeys = false;
    };
    Papa.parse(csvString, {
      header: true,
      encoding: "UTF-8",
      error: reject,
      step,
      complete: () => resolve(data)
    });
  });
};
export const validateEmail = (value) => {
  return value && value.includes("@") && value.includes(".") && value.trim();
};
export const getDisplayUserName = (user) => {
  const { firstName, lastName, email } = user;
  if (firstName && lastName) {
    return `${firstName} ${lastName} (${email})`;
  }
  const name = firstName || lastName;
  if (name) {
    return `${name} (${email})`;
  }
  return email || "";
};
export const getStandardError = (e) => {
  return `${e?.response ? `${e.response?.statusCode}/${e.response?.error}: ${e.response?.message}` : e?.message ? e.message : e}`;
};
