import React from "react";
import PropTypes from "prop-types";
import JSONNestedNode from "./JSONNestedNode";
import ArrayPreview from "./ArrayPreview";
function createItemString(data) {
  return /* @__PURE__ */ React.createElement("span", { className: "previewValue -array" }, /* @__PURE__ */ React.createElement("span", { className: "punctuation -left-bracket" }, "["), /* @__PURE__ */ React.createElement(ArrayPreview, { array: data }), /* @__PURE__ */ React.createElement("span", { className: "punctuation -right-bracket" }, "]"));
}
const JSONArrayNode = ({ data, ...props }) => /* @__PURE__ */ React.createElement(
  JSONNestedNode,
  {
    ...props,
    data,
    nodeType: "Array",
    nodeTypeIndicator: "[]",
    createItemString,
    expandable: data.length > 0
  }
);
JSONArrayNode.propTypes = {
  data: PropTypes.array
};
export default JSONArrayNode;
