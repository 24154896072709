import { Image, Text, VStack } from "@chakra-ui/react";
import quantumJobsWhiteBackground from "../../assets/images/Quantum_Jobs_White_Background.png";
import { LinkButtonLarge } from "./LinkButton.js";
import React from "react";
export const JobsEmptyView = () => {
  return /* @__PURE__ */ React.createElement(VStack, { w: "458px", ml: "auto", mr: "auto", mb: "40px" }, /* @__PURE__ */ React.createElement(
    Text,
    {
      textStyle: "heading-2",
      color: "gray.900",
      textAlign: "center",
      mb: "20px",
      mt: "40px"
    },
    "All of your quantum jobs in one place"
  ), /* @__PURE__ */ React.createElement(Image, { src: quantumJobsWhiteBackground, w: 300, mb: "30px" }), /* @__PURE__ */ React.createElement(
    Text,
    {
      textStyle: "body-3",
      color: "gray.900",
      textAlign: "center",
      mb: "30px"
    },
    "The cloud console shows you the details of the jobs submitted to the IonQ Quantum Cloud to this particular project, but it looks like you haven\u2019t run any yet!"
  ), /* @__PURE__ */ React.createElement(
    LinkButtonLarge,
    {
      isExternal: true,
      href: "https://docs.ionq.com/guides/sdks/qiskit",
      text: "Run your first quantum job"
    }
  ));
};
