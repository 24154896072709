import React from "react";
import { Icon } from "@chakra-ui/icons";
import { Tooltip, Text } from "@chakra-ui/react";
import { MdHelpOutline } from "react-icons/md";
export const IonTooltip = ({ label, children }) => {
  return /* @__PURE__ */ React.createElement(
    Tooltip,
    {
      hasArrow: true,
      bg: "gray.900",
      label: /* @__PURE__ */ React.createElement(Text, { textStyle: "body-6" }, label),
      borderRadius: "5px",
      color: "white",
      ml: ["5px", "3px"],
      wordWrap: "break-word",
      maxW: ["280px"],
      p: "2px 8px"
    },
    children || /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement(
      Icon,
      {
        as: MdHelpOutline,
        boxSize: "14px",
        ml: "4px",
        mb: "-2px",
        cursor: "pointer",
        color: "gray.400"
      }
    ))
  );
};
