import React, { Component } from "react";
import "./NotFound.css";
class NotFound extends Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
  }
  goBack() {
    this.props.history.goBack();
  }
  render() {
    return /* @__PURE__ */ React.createElement("div", { className: "NotFound" }, /* @__PURE__ */ React.createElement("h1", { className: "headline" }, "Not found"), /* @__PURE__ */ React.createElement("p", { className: "subhead" }, "Sorry, but the page you were looking for could not be found."), /* @__PURE__ */ React.createElement("p", { className: "helpful-links" }, /* @__PURE__ */ React.createElement("span", { className: "go-back-link", onClick: this.goBack }, "Go back")));
  }
}
export default NotFound;
