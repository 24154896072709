import React from "react";
import { Form, Formik } from "../../FormikWrapper";
import "./TimeoutCheckbox.scss";
import { Field } from "formik";
import api from "../../../api";
import { useMe, useMySettings } from "../../shared/Me/useMe";
export const TimeoutCheckbox = () => {
  const { refetch } = useMe();
  const minutesUntilSessionTimeout = useMySettings()?.minutesUntilSessionTimeout;
  const isChecked = Boolean(minutesUntilSessionTimeout);
  return /* @__PURE__ */ React.createElement(
    Formik,
    {
      submitAction: (e) => {
        api.updateUser({
          isTimeout: true,
          ...e.values
        }).then(refetch);
      },
      initialValues: {
        minutesUntilSessionTimeout: isChecked
      },
      validateOnChange: false,
      enableReinitialize: true
    },
    ({ dirty, touched, setErrors, status, errors }) => {
      return /* @__PURE__ */ React.createElement("div", { className: "TimeoutCheckbox" }, status && status.success && /* @__PURE__ */ React.createElement("div", { className: "success-box" }, status.success), errors.global && /* @__PURE__ */ React.createElement("div", { className: "error-box" }, errors.global), /* @__PURE__ */ React.createElement(Form, { className: "settings-panel" }, /* @__PURE__ */ React.createElement("h5", { className: "settings-panel-internal-headline" }, "Session timeout"), /* @__PURE__ */ React.createElement("p", { className: "settings-panel-helper-text" }, "Increase the security of your account by automatically logging out after 30 minutes of inactivity."), /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "settings-panel-input-group" }, /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement(
        Field,
        {
          onFocus: () => {
            setErrors({
              ...errors,
              minutesUntilSessionTimeout: ""
            });
          },
          className: `timeout-input ${dirty && touched.minutesUntilSessionTimeout && errors.minutesUntilSessionTimeout ? "-errored" : ""}`,
          type: "checkbox",
          name: "minutesUntilSessionTimeout"
        }
      )), /* @__PURE__ */ React.createElement("span", { className: "label-wrapper" }, /* @__PURE__ */ React.createElement("label", { className: "label" }, "Log me out after 30 minutes of inactivity")), dirty && touched.minutesUntilSessionTimeout && errors.minutesUntilSessionTimeout && /* @__PURE__ */ React.createElement("p", { className: "error-message" }, errors.minutesUntilSessionTimeout)), /* @__PURE__ */ React.createElement(
        "button",
        {
          type: "submit",
          disabled: !dirty,
          className: "settings-panel-submit-button"
        },
        "Update timeout"
      ))));
    }
  );
};
