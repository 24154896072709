import React from "react";
import Profile from "./AccountSettings/Profile";
import Email from "./AccountSettings/Email";
import ProfileImage from "./AccountSettings/ProfileImage";
import Password from "./AccountSettings/Password";
import Multifactor from "./AccountSettings/Multifactor";
import Allowlist from "./AccountSettings/Allowlist";
import { TimeoutCheckbox } from "./AccountSettings/TimeoutCheckbox";
import { useMe } from "../shared/Me/useMe.js";
import { Center, Spinner, Text } from "@chakra-ui/react";
const AccountSettings = (props) => {
  const { isLoading, data } = useMe();
  const { firstName, lastName, email, timeZone, mfa, mfaView } = data || {};
  const isSSO = email && !email.split("@").shift().includes("+") && /.ionq.co(m)?$/.test(email);
  return isLoading ? /* @__PURE__ */ React.createElement(Center, { p: "40px" }, /* @__PURE__ */ React.createElement(Spinner, null)) : /* @__PURE__ */ React.createElement("div", { className: "AccountSettings" }, /* @__PURE__ */ React.createElement(Text, { lineHeight: "38px", textStyle: "heading-3", mb: "12px" }, "Account Settings"), /* @__PURE__ */ React.createElement(
    Profile,
    {
      onSubmit: props.updateUser,
      user: { firstName, lastName, timeZone }
    }
  ), /* @__PURE__ */ React.createElement(Email, { email, onSubmit: props.updateUser }), /* @__PURE__ */ React.createElement(ProfileImage, { email }), /* @__PURE__ */ React.createElement(Password, { isSSO, onSubmit: props.updatePassword }), /* @__PURE__ */ React.createElement(TimeoutCheckbox, null), /* @__PURE__ */ React.createElement(
    Multifactor,
    {
      isSSO,
      enabled: mfa?.enabled,
      enrolling: mfaView?.enrolling,
      qrcode: mfaView?.qrcode,
      onEnroll: props.enrollAuthFactor,
      onActivate: props.activateAuthFactor,
      onCancel: props.cancelEnrollAuthFactor
    }
  ), /* @__PURE__ */ React.createElement("h3", { className: "settings-page-headline" }, "IP allow list"), /* @__PURE__ */ React.createElement(
    Allowlist,
    {
      list: props.ipAllowlist,
      onUpdateUserIpWhitelist: props.updateUser
    }
  ));
};
export default AccountSettings;
