import React, { Component } from "react";
class HistogramTooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false
    };
    this._handleTooltipMouseEnter = this._handleTooltipMouseEnter.bind(this);
    this._handleTooltipMouseLeave = this._handleTooltipMouseLeave.bind(this);
  }
  _handleTooltipMouseEnter() {
    this.setState({
      active: true
    });
  }
  _handleTooltipMouseLeave() {
    this.setState({
      active: false
    });
  }
  render() {
    const {
      histogramKey,
      lineXPosition,
      measurements,
      tooltipRectangleXPosition,
      tooltipWidth,
      tooltipTextXPosition,
      tooltipMessage,
      tooltipHoverArea
    } = this.props;
    return /* @__PURE__ */ React.createElement(
      "g",
      {
        className: `tooltip ${this.state.active === true ? "-visible" : ""}`,
        "data-key": histogramKey
      },
      /* @__PURE__ */ React.createElement("g", { className: "tooltip-background" }, /* @__PURE__ */ React.createElement(
        "polygon",
        {
          points: `${lineXPosition}, ${measurements.baselineYValue}
                              ${lineXPosition - 6}, ${measurements.baselineYValue - measurements.tooltipVerticalOffset + measurements.tooltipHeight / 2 - 1}
                              ${lineXPosition + 6}, ${measurements.baselineYValue - measurements.tooltipVerticalOffset + measurements.tooltipHeight / 2 - 1}
                            `
        }
      ), /* @__PURE__ */ React.createElement(
        "rect",
        {
          x: tooltipRectangleXPosition,
          y: measurements.baselineYValue - measurements.tooltipHeight / 2 - measurements.tooltipVerticalOffset,
          width: tooltipWidth,
          height: measurements.tooltipHeight,
          rx: "5",
          ry: "5"
        }
      )),
      /* @__PURE__ */ React.createElement(
        "text",
        {
          className: "tooltip-text",
          x: tooltipTextXPosition,
          y: measurements.baselineYValue - measurements.tooltipVerticalOffset + (measurements.tooltipHeight - measurements.tooltipTextHeight) / 2,
          textAnchor: "middle"
        },
        tooltipMessage
      ),
      /* @__PURE__ */ React.createElement(
        "line",
        {
          className: "tooltip-hover-line",
          x1: lineXPosition,
          x2: lineXPosition,
          y1: measurements.baselineYValue,
          y2: measurements.baselineYValue - measurements.graphHeight,
          stroke: "transparent",
          strokeWidth: tooltipHoverArea,
          onMouseEnter: this._handleTooltipMouseEnter.bind(
            this,
            histogramKey
          ),
          onMouseLeave: this._handleTooltipMouseLeave
        }
      )
    );
  }
}
export default HistogramTooltip;
