import { getProjectFromId } from "../APIKeys/APIKeysForProjectsMembers.js";
const COMPLETED_JOB_STATUSES = ["completed", "canceled", "failed"];
export const cancelableItemsArray = ({ jobs, rowSelection }) => Object.keys(rowSelection).filter(
  (id) => jobs.find(
    (job) => job.id === id && !COMPLETED_JOB_STATUSES.includes(job.status)
  )
);
export const selectedProjectsSet = ({
  selectedCancelableItems,
  jobs,
  projects
}) => new Set(
  selectedCancelableItems.map(
    (job) => getProjectFromId({
      projects,
      projectId: jobs.find((j) => j.id === job).project_id
    })?.name
  )
);
