const stubbedApiClient = {
  request: () => {
  },
  get: () => {
  },
  post: () => {
  },
  patch: () => {
  },
  put: () => {
  },
  delete: () => {
  }
};
export class Api {
  constructor(apiClient) {
    this.client = apiClient || stubbedApiClient;
    this.get = async (url) => {
      try {
        const resp = await this.client.get(url);
        if (!resp) {
          return null;
        }
        return resp;
      } catch (e) {
        return null;
      }
    };
  }
  async getMyJobs({ params, onSuccess, onError }) {
    const resp = await this.get(
      `/users/me/jobs?${new URLSearchParams(params).toString()}`
    );
    if (!resp?.jobs) {
      onError("Invalid response from server");
      return;
    }
    return onSuccess(resp);
  }
  async getJobs({ params, onSuccess, onError }) {
    const resp = await this.get(
      `/jobs?${new URLSearchParams(params).toString()}`
    );
    if (!resp?.jobs) {
      onError("Invalid response from server");
      return;
    }
    return onSuccess(resp);
  }
  async getJobProgram({ jobId, onSuccess, onError }) {
    const resp = await this.get(`/jobs/${jobId}/program`);
    if (!resp) {
      onError();
      return;
    }
    return onSuccess(resp);
  }
  async getCharacterization(characterizationUrl) {
    return this.get(characterizationUrl);
  }
  async getStatus({ onSuccess, onError }) {
    const backends = await this.get("/backends");
    if (!backends) return;
    if (backends.error) {
      onError(backends.error);
      return;
    }
    let characterizations = [];
    const urls = backends.filter((v) => v.characterization_url);
    if (urls.length > 0) {
      characterizations = await Promise.all(
        urls.map(
          (v) => this.getCharacterization(v.characterization_url)
        )
      );
    }
    const isQpu = (target) => /^qpu\./.test(target.backend);
    const getSortOrder = (a, b) => {
      if (isQpu(a) && isQpu(b)) return a.qubits - b.qubits;
      return isQpu(a) ? 1 : -1;
    };
    const filtered = backends.filter((v) => v.backend !== "qpu.s11").sort(getSortOrder);
    onSuccess({
      backends: filtered,
      characterizations,
      // NOTE(nakardo): free access accounts are inferred from not having
      // access to any qpu.
      isFreeAccess: backends.filter(isQpu).every((v) => !v.has_access)
    });
  }
  async getProject({ projectId, onSuccess, onError }) {
    const resp = await this.get(`/projects/${projectId}`);
    if (!resp?.id) {
      onError();
      return;
    }
    return onSuccess(resp);
  }
  async getJobResults({ jobId, onSuccess, onError }) {
    const resp = await this.get(`/jobs/${jobId}/results`);
    if (!resp) {
      onError();
      return;
    }
    return onSuccess(resp);
  }
}
