import { CLOSE_MODAL, MODAL_STATES, MODALS, OPEN_MODAL } from "./constants";
export const initialState = Object.values(MODALS).reduce(
  (modals, name) => ({
    ...modals,
    [name]: {
      isOpen: MODAL_STATES.closed
    }
  }),
  {}
);
export function reducer(state, action) {
  const {
    type,
    payload: { modalName, props }
  } = action;
  switch (type) {
    case OPEN_MODAL:
      return {
        ...state,
        [modalName]: {
          props,
          isOpen: MODAL_STATES.open
        }
      };
    case CLOSE_MODAL:
      return {
        ...state,
        [modalName]: {
          isOpen: MODAL_STATES.closed
        }
      };
    default:
      throw new Error(`Invalid modal action ${JSON.stringify(action)}`);
  }
}
