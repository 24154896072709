import React from "react";
import { useBaseModal } from "../../shared/baseModals/useBaseModal";
import { MODALS } from "../../shared/baseModals/constants";
import {
  Box,
  Button,
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
export const MemberProjectsModal = () => {
  const {
    close: closeMemberProjectsModal,
    isOpen,
    props = {}
  } = useBaseModal(MODALS.memberProjects);
  return /* @__PURE__ */ React.createElement(
    Modal,
    {
      isOpen,
      size: "xl",
      isCentered: true,
      onClose: closeMemberProjectsModal
    },
    /* @__PURE__ */ React.createElement(ModalOverlay, null),
    /* @__PURE__ */ React.createElement(ModalContent, { p: "24px 32px 32px" }, /* @__PURE__ */ React.createElement(ModalHeader, { p: 0 }, /* @__PURE__ */ React.createElement(Text, { textStyle: "heading-1" }, "All projects")), /* @__PURE__ */ React.createElement(ModalBody, { p: "24px 0" }, /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(
      Text,
      {
        textStyle: "body-3",
        lineHeight: "21px",
        color: "ionDark"
      },
      props?.userName,
      " belongs to the following projects:"
    ), props?.projects?.map((project) => {
      return /* @__PURE__ */ React.createElement(
        Link,
        {
          key: project.id,
          to: `/projects/${project.id}`
        },
        /* @__PURE__ */ React.createElement(
          Text,
          {
            textStyle: "body-3",
            color: "uiBlue",
            lineHeight: "21px",
            _hover: { textDecoration: "underline" }
          },
          project.name
        )
      );
    }))), /* @__PURE__ */ React.createElement(ModalFooter, { p: 0 }, /* @__PURE__ */ React.createElement(
      Button,
      {
        variant: "grey",
        size: "lg",
        onClick: closeMemberProjectsModal
      },
      "Cancel"
    )))
  );
};
