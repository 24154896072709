const stylingClassFromState = (state) => {
  if (state === void 0 || !state) {
    return "";
  } else if (state && state instanceof String) {
    return `-${state.replace(/([a-z])([A-Z])/g, "$1-$2")}`;
  } else if (state && state instanceof Array) {
    let str = "";
    state.forEach((e) => {
      str += `-${e.toString().replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase()} `;
    });
    return str;
  } else {
    return `-${state.toString().replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase()}`;
  }
};
export default stylingClassFromState;
