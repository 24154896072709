import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast
} from "@chakra-ui/react";
import { useBaseModal } from "../../shared/baseModals/useBaseModal";
import { MODALS } from "../../shared/baseModals/constants";
import { StatusIcon } from "../../shared/StatusIcon";
import { IonToast } from "../../shared/IonToast";
import { updateUserById } from "../hooks/utils";
import { useOrganization } from "../../shared/Organization/useOrganization";
export const ChangeUserRoleModal = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const toast = useToast();
  const toastIdRef = useRef();
  const { refetch: refetchOrg } = useOrganization();
  const { close, isOpen, props } = useBaseModal(MODALS.changeUserRole);
  if (!props?.name || !props?.role || !props?.id) {
    return /* @__PURE__ */ React.createElement(Modal, { isOpen, onClose: close, size: "xl", isCentered: true }, /* @__PURE__ */ React.createElement(ModalOverlay, null), /* @__PURE__ */ React.createElement(ModalContent, { p: "24px 32px 32px" }, "Something is wrong"));
  }
  const { name, role, id } = props;
  const handleModalClose = () => {
    setSubmitError(null);
    setIsSubmitting(false);
    close();
  };
  const handleSubmit = () => {
    setIsSubmitting(true);
    close();
    updateUserById({
      onSuccess: () => {
        toastIdRef.current = toast({
          duration: 5e3,
          position: "bottom-left",
          render: () => /* @__PURE__ */ React.createElement(
            IonToast,
            {
              message: `${name} successfully changed to ${role}`,
              status: "success",
              toast,
              toastIdRef
            }
          )
        });
        refetchOrg();
        setIsSubmitting(false);
      },
      onError: (e) => {
        toastIdRef.current = toast({
          duration: 5e3,
          position: "bottom-left",
          render: () => /* @__PURE__ */ React.createElement(
            IonToast,
            {
              message: e.message,
              toast,
              toastIdRef
            }
          )
        });
        setIsSubmitting(false);
      },
      id,
      role
    });
  };
  return /* @__PURE__ */ React.createElement(Modal, { isOpen, onClose: handleModalClose, size: "xl", isCentered: true }, /* @__PURE__ */ React.createElement(ModalOverlay, null), /* @__PURE__ */ React.createElement(ModalContent, { p: "24px 32px 32px" }, /* @__PURE__ */ React.createElement(ModalHeader, { p: 0 }, /* @__PURE__ */ React.createElement(Text, { textStyle: "heading-1" }, "Change member\u2019s role")), /* @__PURE__ */ React.createElement(ModalBody, { p: "24px 0" }, submitError && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Grid, { templateColumns: "1fr 20fr", bg: "#F9E3E1", p: 4 }, /* @__PURE__ */ React.createElement(StatusIcon, { variant: "WARNING" }), submitError), /* @__PURE__ */ React.createElement(Box, { pb: "5" })), /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3" }, `Are you sure you want to change ${name}\u2019s role to ${role}?`))), /* @__PURE__ */ React.createElement(ModalFooter, { p: 0 }, /* @__PURE__ */ React.createElement(Button, { variant: "grey", size: "lg", onClick: handleModalClose }, "Cancel"), /* @__PURE__ */ React.createElement(
    Button,
    {
      size: "lg",
      variant: "blue",
      ml: "12px",
      isLoading: isSubmitting,
      onClick: handleSubmit
    },
    "Yes, change role"
  ))));
};
