import { useCallback, useEffect, useState } from "react";
import { fetchOrgMembers, deleteOrgMember } from "./utils";
import { getStandardError } from "../../shared/utils/utils";
export const useOrgMembers = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [orgMembers, setOrgMembers] = useState([]);
  const [error, setError] = useState(null);
  useEffect(() => {
    setIsLoading(true);
    fetchOrgMembers({
      onSuccess: (res) => {
        setIsLoading(false);
        setOrgMembers(res);
      },
      onError: (e) => {
        setIsLoading(false);
        setError(getStandardError(e));
      }
    });
  }, []);
  const removeOrgMember = useCallback(
    ({ userId, onRemoveSuccess, onRemoveError }) => {
      deleteOrgMember({
        userId,
        onSuccess: () => {
          const updatedOrgMembers = orgMembers.filter(
            (orgMember) => orgMember.id !== userId
          );
          setOrgMembers(updatedOrgMembers);
          onRemoveSuccess();
        },
        onError: (e) => onRemoveError(getStandardError(e))
      });
    },
    [orgMembers]
  );
  return {
    orgMembers,
    error,
    isLoading,
    removeOrgMember
  };
};
