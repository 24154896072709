import React, { useEffect } from "react";
import { Grid, GridItem, Box, Text } from "@chakra-ui/react";
import { MODALS } from "../shared/baseModals/constants";
import { BaseModal, BaseModalBody, BaseModalFooter } from "../shared/Modal";
import { useBaseModal } from "../shared/baseModals/useBaseModal";
import { useJob } from "./useJob";
import { useSendCancelJob } from "./useSendCancelJob.js";
import { useActionToast } from "./useActionToast";
export const CancelJobConfirmationModal = () => {
  const [cancelJobError, setCancelJobError] = React.useState(null);
  const { toastSuccess } = useActionToast();
  const { close, isOpen, props } = useBaseModal(MODALS.cancelJobConfirmation);
  const { loading, job, error } = useJob(props?.jobId);
  const isJobAvailable = job && !error;
  const onCancelSuccess = () => {
    if (!isJobAvailable) return;
    toastSuccess(`Job ${job.id.slice(0, 8)} was successfully canceled`);
    close();
  };
  const onCancelError = (errorMessage) => {
    if (!isJobAvailable) return;
    setCancelJobError(errorMessage);
  };
  const { sendCancelJob, isCancelJobLoading } = useSendCancelJob({
    jobId: props?.jobId,
    onCancelSuccess,
    onCancelError
  });
  useEffect(() => {
    if (!job) return;
    setCancelJobError(null);
  }, [job]);
  return /* @__PURE__ */ React.createElement(BaseModal, { title: "Cancel job", isOpen, close }, /* @__PURE__ */ React.createElement(
    BaseModalBody,
    {
      isLoading: loading,
      loadingError: error ? /* @__PURE__ */ React.createElement("p", null, "error retrieving job") : null,
      errorBannerContent: cancelJobError ? /* @__PURE__ */ React.createElement("p", null, cancelJobError) : null
    },
    job && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3" }, "Are you sure you want to cancel job \u201C", job.name, "\u201D? Canceling this job will prevent its execution and not incur any usage. This action cannot be undone."), /* @__PURE__ */ React.createElement(Box, { pb: "5" }), /* @__PURE__ */ React.createElement(Grid, { templateColumns: "1fr 3fr", gap: "2 0" }, /* @__PURE__ */ React.createElement(GridItem, { textStyle: "body-2" }, "job name"), /* @__PURE__ */ React.createElement(
      GridItem,
      {
        textStyle: "body-3",
        maxW: "400px",
        isTruncated: true
      },
      job.name
    ), /* @__PURE__ */ React.createElement(GridItem, { textStyle: "body-2" }, "job id"), /* @__PURE__ */ React.createElement(GridItem, { textStyle: "body-3" }, job.id), /* @__PURE__ */ React.createElement(GridItem, { textStyle: "body-2" }, "target"), /* @__PURE__ */ React.createElement(GridItem, { textStyle: "body-3" }, job.target), job.circuits && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(GridItem, { textStyle: "body-2" }, "circuits"), /* @__PURE__ */ React.createElement(GridItem, { textStyle: "body-3", gap: 2 }, job.circuits)))),
    /* @__PURE__ */ React.createElement(
      BaseModalFooter,
      {
        isLoading: isCancelJobLoading,
        isDanger: true,
        onClose: close,
        onCtaClick: () => sendCancelJob(job.id),
        ctaLabel: "I understand, cancel this job",
        isDisabled: !!cancelJobError
      }
    )
  ));
};
