import {
  Box,
  Button,
  Center,
  Flex,
  SimpleGrid,
  Spacer,
  Tag,
  Text,
  Tooltip,
  useClipboard,
  useDisclosure
} from "@chakra-ui/react";
import SystemStatus from "../Jobs/JobsDashboardSystemStatus";
import { Link } from "react-router-dom";
import {
  formatGateFidelity,
  formatInvertedFidelity,
  formatTiming,
  getAvgTimeInQueue,
  nameMapping
} from "./utils";
import { SYSTEM_TOOLTIP_LABELS } from "./labels";
import downloadCode from "../../lib/downloadCode";
import upsellImage from "../../assets/images/upsell-bg.svg";
import React from "react";
import { ColumnGridBox, KVTableContainer } from "./SystemTableHelpers";
import { Icon } from "@chakra-ui/icons";
import {
  MdContentCopy,
  MdOpenInNew,
  MdOutlineFileDownload
} from "react-icons/md";
import { IonTooltip } from "../shared/IonTooltip";
import { getIonDateFormat, getTooltipFormattedDate } from "../../util/format";
const { REACT_APP_IONQ_WEBSITE_BASE_URL } = window._dynamic_env_;
const BackEndHeader = ({ backend, isOpen, onToggle, isSimulator }) => {
  return /* @__PURE__ */ React.createElement(
    Flex,
    {
      borderRadius: "5px 5px 0 0",
      p: ["10px 24px", "10px 24px", "10px 24px 6px"],
      bg: "gray.50",
      borderBottom: "1px solid #E0E0E3",
      borderColor: "gray.200",
      color: "gray.900",
      minH: "44px"
    },
    /* @__PURE__ */ React.createElement(Flex, { flexDirection: ["column", "column", "row"], w: "59%" }, /* @__PURE__ */ React.createElement(
      Text,
      {
        as: isSimulator ? null : "span",
        textStyle: "body-2",
        noOfLines: [2, 2, 1]
      },
      backend.backend === "simulator" && "Ideal ",
      nameMapping[backend.backend.split(".").pop()],
      backend.backend !== "simulator" && isSimulator && " Noisy Quantum Simulator"
    ), /* @__PURE__ */ React.createElement(
      Text,
      {
        as: isSimulator ? null : "span",
        ml: [0, 0, 2],
        textStyle: "body-3",
        color: "gray.600"
      },
      isSimulator ? "simulator" : backend.backend
    ), /* @__PURE__ */ React.createElement(Box, { ml: [0, 0, "8px"] }, /* @__PURE__ */ React.createElement(
      SystemStatus,
      {
        status: isSimulator ? "available" : backend.status
      }
    )), /* @__PURE__ */ React.createElement(Box, null, !isSimulator && !backend.has_access && /* @__PURE__ */ React.createElement(
      Tag,
      {
        colorScheme: "blackAlpha",
        ml: [0, 0, "12px"],
        p: "5px 7px",
        h: "20px",
        variant: "solid",
        color: "gray.700",
        textStyle: "body-9",
        bg: "gray.100"
      },
      "Out Of Plan"
    ))),
    /* @__PURE__ */ React.createElement(Spacer, null),
    /* @__PURE__ */ React.createElement(Center, { axis: "both" }, /* @__PURE__ */ React.createElement(Link, { onClick: onToggle, to: "#" }, /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3", color: "uiBlue" }, isOpen ? "Hide" : "Show", " details")))
  );
};
const BackEndTopColumn = ({
  backend,
  waitTime,
  isSimulator,
  simulatorWaitTime
}) => {
  return /* @__PURE__ */ React.createElement(Box, { borderRadius: "0 0 5px 5px", bg: "white", borderTop: "none" }, /* @__PURE__ */ React.createElement(SimpleGrid, { columns: [1, 3], spacingX: "0" }, /* @__PURE__ */ React.createElement(
    ColumnGridBox,
    {
      title: isSimulator ? "Simulated Hardware Noise Model" : "#AQ",
      tooltipMessage: isSimulator ? SYSTEM_TOOLTIP_LABELS.SIMULATED_HARDWARE_NOISE_MODEL : SYSTEM_TOOLTIP_LABELS.AQ_COUNT,
      value: isSimulator ? (backend.backend === "simulator" ? "Ideal" : nameMapping[backend.backend.split(".").pop()]).split("IonQ ").pop() : backend.aq
    }
  ), /* @__PURE__ */ React.createElement(
    ColumnGridBox,
    {
      title: "Qubits",
      tooltipMessage: isSimulator ? SYSTEM_TOOLTIP_LABELS.QUBITS_SIMULATOR : SYSTEM_TOOLTIP_LABELS.QUBITS,
      value: backend.qubits
    }
  ), /* @__PURE__ */ React.createElement(
    ColumnGridBox,
    {
      title: "Avg. Time in Queue",
      tooltipMessage: null,
      value: isSimulator ? simulatorWaitTime : waitTime,
      isLastItem: true
    }
  )));
};
const getLinkString = (systemName) => {
  return `mailto:support@ionq.com?subject=${`I would like to make a reservation on ${systemName}`.split(" ").join("%20")}&body=${"Please describe your workload".split(" ").join("%20")}`;
};
const BackEndExpanded = ({
  backend,
  characterization,
  timing,
  fidelity,
  isSimulator
}) => {
  const gateCalibrationDate = characterization?.date && getIonDateFormat(characterization?.date) || "Unknown";
  const { onCopy } = useClipboard(JSON.stringify(characterization));
  const systemName = nameMapping[backend.backend.split(".").pop()];
  const hasAccess = backend.has_access;
  const linkString = hasAccess ? getLinkString(systemName) : "https://ionq.com/get-access";
  return /* @__PURE__ */ React.createElement(Box, null, !isSimulator && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Flex, { borderTop: "1px solid #E0E0E3" }, /* @__PURE__ */ React.createElement(Box, { w: "100%" }, /* @__PURE__ */ React.createElement(
    Flex,
    {
      flexDirection: ["column", "row"],
      justifyContent: "space-between"
    },
    /* @__PURE__ */ React.createElement(
      Box,
      {
        w: ["100%", "unset"],
        p: "24px 24px 6px",
        flexGrow: 1
      },
      /* @__PURE__ */ React.createElement(
        Text,
        {
          textStyle: "body-2",
          color: "grey.900",
          whiteSpace: "nowrap"
        },
        "Characterization data"
      )
    ),
    /* @__PURE__ */ React.createElement(
      Box,
      {
        w: ["100%", "unset"],
        p: ["0 24px 6px", "24px 24px 6px"]
      },
      /* @__PURE__ */ React.createElement(
        Text,
        {
          textStyle: "body-3",
          color: "gray.900",
          align: "right"
        },
        "Characterization data taken at",
        " ",
        /* @__PURE__ */ React.createElement(
          Tooltip,
          {
            hasArrow: true,
            bg: "gray.900",
            label: /* @__PURE__ */ React.createElement(Text, { textStyle: "body-6" }, `${getTooltipFormattedDate(
              characterization?.date
            )} ago`),
            borderRadius: "5px",
            color: "white",
            ml: ["5px", "3px"],
            wordWrap: "break-word",
            maxW: ["280px"],
            p: "2px 8px"
          },
          gateCalibrationDate
        )
      )
    )
  ), /* @__PURE__ */ React.createElement(SimpleGrid, { columns: [1, 1, 3], spacingX: "0" }, /* @__PURE__ */ React.createElement(
    KVTableContainer,
    {
      items: [
        {
          title: "Topology",
          label: SYSTEM_TOOLTIP_LABELS.TOPOLOGY,
          value: "all-to-all"
        },
        {
          title: "1Q Gate Error",
          label: SYSTEM_TOOLTIP_LABELS.ONE_Q_GATE_ERROR,
          value: formatGateFidelity(
            fidelity,
            "1q"
          )
        },
        {
          title: "2Q Gate Error",
          label: SYSTEM_TOOLTIP_LABELS.TWO_Q_GATE_ERROR,
          value: formatGateFidelity(
            fidelity,
            "2q"
          )
        }
      ]
    }
  ), /* @__PURE__ */ React.createElement(
    KVTableContainer,
    {
      items: [
        {
          title: "SPAM Error",
          label: SYSTEM_TOOLTIP_LABELS.SPAM_ERROR,
          value: formatInvertedFidelity(
            fidelity,
            "spam"
          )
        },
        {
          title: "T1",
          label: SYSTEM_TOOLTIP_LABELS.T1,
          value: timing && timing.t1 ? `${timing.t1} s` : "Unknown"
        },
        {
          title: "T2",
          label: SYSTEM_TOOLTIP_LABELS.T2,
          value: timing && timing.t2 ? `${timing.t2} s` : "Unknown"
        }
      ]
    }
  ), /* @__PURE__ */ React.createElement(
    KVTableContainer,
    {
      items: [
        {
          title: "1Q Gate",
          label: SYSTEM_TOOLTIP_LABELS.ONE_Q,
          value: formatTiming(timing, "1q")
        },
        {
          title: "2Q Gate",
          label: SYSTEM_TOOLTIP_LABELS.TWO_Q,
          value: formatTiming(timing, "2q")
        },
        {
          title: "Readout",
          label: SYSTEM_TOOLTIP_LABELS.READOUT,
          value: formatTiming(
            timing,
            "readout"
          )
        },
        {
          title: "Reset",
          label: SYSTEM_TOOLTIP_LABELS.RESET,
          value: formatTiming(
            timing,
            "reset"
          )
        }
      ]
    }
  )))), /* @__PURE__ */ React.createElement(
    Flex,
    {
      mt: 2,
      p: "2px 24px",
      justifyContent: "flex-end",
      flexDirection: ["column", "row"]
    },
    /* @__PURE__ */ React.createElement(
      Button,
      {
        leftIcon: /* @__PURE__ */ React.createElement(
          Icon,
          {
            as: MdContentCopy,
            boxSize: "14px",
            color: "gray.600"
          }
        ),
        borderColor: "gray.200",
        borderWidth: 1,
        color: "gray.900",
        bg: "white",
        p: "12px 8px",
        mr: 2,
        w: ["100%", "unset"],
        size: "sm",
        mb: 2,
        maxH: "28px",
        _hover: { bg: "gray.50" },
        onClick: characterization ? onCopy : null
      },
      "Copy JSON"
    ),
    /* @__PURE__ */ React.createElement(
      Button,
      {
        leftIcon: /* @__PURE__ */ React.createElement(
          Icon,
          {
            as: MdOutlineFileDownload,
            boxSize: "14px",
            color: "gray.600"
          }
        ),
        borderColor: "gray.200",
        borderWidth: 1,
        bg: "white",
        _hover: { bg: "gray.50" },
        size: "sm",
        p: "12px 8px",
        maxH: "28px",
        w: ["100%", "unset"],
        color: "gray.900",
        onClick: () => {
          downloadCode(
            JSON.stringify(characterization),
            `${backend.backend}-characterization-data`,
            "json"
          );
        }
      },
      "Download JSON"
    )
  )), /* @__PURE__ */ React.createElement(Flex, { pb: "24px" }, /* @__PURE__ */ React.createElement(
    Box,
    {
      w: "100%",
      borderTop: isSimulator ? "1px solid #E0E0E3" : ""
    },
    /* @__PURE__ */ React.createElement(Flex, null, /* @__PURE__ */ React.createElement(
      Box,
      {
        p: [4, `${isSimulator ? "24px" : "16px"} 24px 8px`],
        w: ["50%", "100%"]
      },
      /* @__PURE__ */ React.createElement(Text, { textStyle: "body-2", color: "gray.900" }, "Capabilities")
    ), /* @__PURE__ */ React.createElement(Spacer, null)),
    /* @__PURE__ */ React.createElement(SimpleGrid, { columns: [1, 1, 3], spacingX: "0" }, !isSimulator && /* @__PURE__ */ React.createElement(
      KVTableContainer,
      {
        items: [
          {
            isBig: true,
            title: /* @__PURE__ */ React.createElement(Box, { as: "span" }, /* @__PURE__ */ React.createElement(
              Text,
              {
                textStyle: "body-3",
                color: "gray.500",
                lineHeight: "0.9125"
              },
              "Supports"
            ), /* @__PURE__ */ React.createElement(
              Text,
              {
                textStyle: "body-3",
                as: "span",
                color: "gray.500"
              },
              "Reservations",
              /* @__PURE__ */ React.createElement(
                IonTooltip,
                {
                  label: SYSTEM_TOOLTIP_LABELS.SUPPORTS_RESERVATIONS
                }
              )
            )),
            label: SYSTEM_TOOLTIP_LABELS.SUPPORTS_RESERVATIONS,
            value: /* @__PURE__ */ React.createElement(Box, { lineHeight: "1rem" }, !hasAccess && /* @__PURE__ */ React.createElement(
              Box,
              {
                as: "span",
                textStyle: "body-8",
                color: "ionDark",
                display: "inline-block",
                paddingRight: 1,
                mb: "4px"
              },
              "Requires QPU Access"
            ), /* @__PURE__ */ React.createElement(
              Text,
              {
                as: "span",
                whiteSpace: "nowrap",
                display: "inline-block",
                paddingRight: 1,
                color: "uiBlue",
                textStyle: "body-3",
                lineHeight: "1rem"
              },
              /* @__PURE__ */ React.createElement(
                "a",
                {
                  href: linkString,
                  target: "_blank",
                  rel: "noopener noreferrer"
                },
                hasAccess ? "Request" : "Get"
              )
            ), /* @__PURE__ */ React.createElement(
              Text,
              {
                as: "span",
                display: "inline-block",
                whiteSpace: "nowrap",
                color: "uiBlue",
                textStyle: "body-3",
                lineHeight: "1rem"
              },
              /* @__PURE__ */ React.createElement(
                "a",
                {
                  href: linkString,
                  target: "_blank",
                  rel: "noopener noreferrer"
                },
                " ",
                hasAccess ? "reservation" : "access"
              ),
              /* @__PURE__ */ React.createElement(
                Icon,
                {
                  as: MdOpenInNew,
                  mb: "-2px"
                }
              )
            ))
          },
          {
            isBig: true,
            title: /* @__PURE__ */ React.createElement(Box, { as: "span" }, /* @__PURE__ */ React.createElement(
              Text,
              {
                textStyle: "body-3",
                color: "gray.500",
                lineHeight: "0.9125"
              },
              "Supports Error"
            ), /* @__PURE__ */ React.createElement(
              Text,
              {
                textStyle: "body-3",
                as: "span",
                color: "gray.500"
              },
              "Mitigation",
              /* @__PURE__ */ React.createElement(
                IonTooltip,
                {
                  label: SYSTEM_TOOLTIP_LABELS.SUPPORTS_ERROR_MITIGATION
                }
              )
            )),
            label: SYSTEM_TOOLTIP_LABELS.SUPPORTS_ERROR_MITIGATION,
            value: "Debias, Sharpen"
          }
        ]
      }
    ), /* @__PURE__ */ React.createElement(
      KVTableContainer,
      {
        items: [
          {
            isBig: true,
            title: /* @__PURE__ */ React.createElement(Box, { as: "span" }, /* @__PURE__ */ React.createElement(
              Text,
              {
                textStyle: "body-3",
                color: "gray.500",
                lineHeight: "0.9125"
              },
              "Supported"
            ), /* @__PURE__ */ React.createElement(
              Text,
              {
                textStyle: "body-3",
                as: "span",
                color: "gray.500"
              },
              "Gates",
              /* @__PURE__ */ React.createElement(
                IonTooltip,
                {
                  label: SYSTEM_TOOLTIP_LABELS.SUPPORTED_GATES
                }
              )
            )),
            label: SYSTEM_TOOLTIP_LABELS.SUPPORTED_GATES,
            value: "x, y, z, rx, ry, rz, h, not, cnot, s, si, t, ti, v, vi, swap"
          },
          {
            isBig: true,
            title: /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(
              Text,
              {
                textStyle: "body-3",
                color: "gray.500",
                lineHeight: "0.9125"
              },
              "Supported"
            ), /* @__PURE__ */ React.createElement(
              Text,
              {
                textStyle: "body-3",
                as: "span",
                color: "gray.500"
              },
              "Native Gates",
              /* @__PURE__ */ React.createElement(
                IonTooltip,
                {
                  label: SYSTEM_TOOLTIP_LABELS.SUPPORTED_NATIVE_GATES
                }
              )
            )),
            label: SYSTEM_TOOLTIP_LABELS.SUPPORTED_NATIVE_GATES,
            value: `ms, gpi, gpi2${backend.backend.includes("forte") ? ", zz" : ""}`
          }
        ]
      }
    ))
  )));
};
const BackEndUpsell = ({ name }) => {
  return /* @__PURE__ */ React.createElement(
    Flex,
    {
      p: ["24px", "24px"],
      bg: "#F0F4FD",
      bgImg: upsellImage,
      borderBottomRadius: "5px",
      bgRepeat: "no-repeat",
      bgPos: ["50%", "66.6%", "right"],
      bgSize: ["cover", "cover", "contain"],
      verticalAlign: "middle"
    },
    /* @__PURE__ */ React.createElement(Box, { maxW: ["66%", "66%", "66%", "100%"] }, /* @__PURE__ */ React.createElement(Text, { as: "span", color: "gray.900", textStyle: "body-2" }, "Interested in running jobs on ", name, "?", " "), /* @__PURE__ */ React.createElement(Text, { as: "span", color: "gray.900", textStyle: "body-3" }, "Request direct access to start running jobs on ", name)),
    /* @__PURE__ */ React.createElement(Spacer, { minW: "96px" }),
    /* @__PURE__ */ React.createElement(Center, { align: "vertical", w: ["40%", "unset"] }, /* @__PURE__ */ React.createElement(
      Button,
      {
        size: "sm",
        bg: "uiBlue",
        color: "white",
        align: "right",
        maxH: "28px",
        _hover: { bg: "uiDarkBlue" },
        _active: {
          bg: "uiDarkBlue"
        },
        textStyle: "body-4"
      },
      /* @__PURE__ */ React.createElement(
        "a",
        {
          target: "_blank",
          rel: "noreferrer",
          href: `${REACT_APP_IONQ_WEBSITE_BASE_URL}/get-access?intent=direct-access`
        },
        "Get access"
      )
    ))
  );
};
export const SystemsBackEnd = ({
  backend,
  characterizations,
  noiseModels = [],
  isSimulator,
  simulatorWaitTime
}) => {
  const { isOpen, onToggle } = useDisclosure();
  const characterization = characterizations.filter((v) => v.backend === backend.backend).pop();
  const waitTime = getAvgTimeInQueue(backend.average_queue_time);
  const { fidelity, timing } = characterization || {};
  if (isSimulator && backend.backend !== "simulator" && !noiseModels.includes(backend.backend.split(".").pop())) {
    return null;
  }
  return /* @__PURE__ */ React.createElement(
    Box,
    {
      key: backend.backend,
      mt: [0, 0, "24px"],
      mb: ["16px", "16px", "24px"],
      boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.05)",
      border: "1px solid #E0E0E3",
      borderRadius: "5px"
    },
    /* @__PURE__ */ React.createElement(
      BackEndHeader,
      {
        backend,
        onToggle,
        isOpen,
        isSimulator
      }
    ),
    /* @__PURE__ */ React.createElement(
      BackEndTopColumn,
      {
        backend,
        waitTime,
        isSimulator,
        simulatorWaitTime
      }
    ),
    isOpen && /* @__PURE__ */ React.createElement(
      BackEndExpanded,
      {
        backend,
        fidelity,
        timing,
        characterization,
        isSimulator
      }
    ),
    !isSimulator && !backend.has_access && /* @__PURE__ */ React.createElement(
      BackEndUpsell,
      {
        name: nameMapping[backend.backend.split(".").pop()]
      }
    )
  );
};
