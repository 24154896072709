import React from "react";
import StdoutViewer from "./StdoutViewer";
function formatJsonOutput(output) {
  const keySize = Object.keys(output).map((v) => v.length).reduce((a, b) => a > b ? a : b);
  return Object.entries(output).map(
    ([k, v]) => "> " + k.padEnd(keySize + 1) + `= "${v}"`
  );
}
const JobUnknownCircuitOutput = ({ status, failure }) => {
  return /* @__PURE__ */ React.createElement("div", null, status === "failed" && !failure && /* @__PURE__ */ React.createElement("div", null, " ", /* @__PURE__ */ React.createElement(StdoutViewer, { output: [{ stderr: "job failed" }] }), " "), status === "failed" && failure && /* @__PURE__ */ React.createElement("div", null, " ", /* @__PURE__ */ React.createElement(
    StdoutViewer,
    {
      output: [
        "job failed",
        ...formatJsonOutput(failure)
      ].map((output) => ({ stderr: output }))
    }
  ), " "), status === "canceled" && /* @__PURE__ */ React.createElement("div", null, " ", /* @__PURE__ */ React.createElement(StdoutViewer, { output: [{ stdout: "job canceled" }] }), " "), status === "submitted" && /* @__PURE__ */ React.createElement("div", null, " ", /* @__PURE__ */ React.createElement(StdoutViewer, { output: [{ stdout: "job submitted" }] }), " "), status === "ready" && /* @__PURE__ */ React.createElement("div", null, " ", /* @__PURE__ */ React.createElement(StdoutViewer, { output: [{ stdout: "ready..." }] }), " "), status === "running" && /* @__PURE__ */ React.createElement("div", null, " ", /* @__PURE__ */ React.createElement(StdoutViewer, { output: [{ stdout: "running..." }] }), " "));
};
export default JobUnknownCircuitOutput;
