import { Global } from "@emotion/react";
import React from "react";
export const Fonts = () => /* @__PURE__ */ React.createElement(
  Global,
  {
    styles: `
        @font-face {
            font-family: 'Colfaxx';
            src: url("/fonts/Colfax-Light.woff2") format('woff2'), url("/fonts/Colfax-Light.woff") format('woff');
            font-weight: 300;
            font-style: normal;
        }
        @font-face {
            font-family: 'Colfaxx';
            src: url("/fonts/Colfax-Regular.woff2") format('woff2'), url("/fonts/Colfax-Regular.woff") format('woff');
            font-weight: 400;
            font-style: normal;
        }
        @font-face {
            font-family: 'Colfaxx';
            src: url("/fonts/Colfax-Medium.woff2") format('woff2'), url("/fonts/Colfax-Medium.woff") format('woff');
            font-weight: 500;
            font-style: normal;
        }
        @font-face {
            font-family: 'Colfaxx';
            src: url("/fonts/Colfax-Bold.woff2") format('woff2'), url("/fonts/Colfax-Bold.woff") format('woff');
            font-weight: 600;
            font-style: normal;
        }
        @font-face {
            font-family: 'Ubuntu-Mono';
            src: url("/fonts/UbuntuMono-Italic.woff2") format('woff2'), url("/fonts/UbuntuMono-Italic.woff") format('woff');
            font-weight: 400;
            font-style: italic;
        }
        @font-face {
            font-family: 'Ubuntu-Mono';
            src: url("/fonts/UbuntuMono-BoldItalic.woff2") format('woff2'), url("/fonts/UbuntuMono-BoldItalic.woff") format('woff');
            font-weight: 700;
            font-style: italic;
        }
        @font-face {
            font-family: 'Ubuntu-Mono';
            src: url("/fonts/UbuntuMono-Regular.woff2") format('woff2'), url("/fonts/UbuntuMono-Regular.woff") format('woff');
            font-weight: 400;
            font-style: normal;
        }
        @font-face {
            font-family: Ubuntu-Mono;
            src: url("/fonts/UbuntuMono-Bold.woff2") format('woff2'), url("/fonts/UbuntuMono-Bold.woff") format('woff');
            font-weight: 700;
            font-style: normal;
        }
      `
  }
);
