import React from "react";
import { createContext, useState } from "react";
import { useMyJobs } from "../components/Projects/hooks/useMyJobs.js";
export const MyJobsContext = createContext({
  jobs: [],
  setJobs: () => {
  },
  filters: [],
  setFilters: () => {
  },
  hasMore: false,
  next: null,
  isJobsLoading: false,
  jobsError: null,
  loadMore: () => {
  },
  setIsLoading: () => {
  }
});
export const MyJobsProvider = ({ children }) => {
  const [filters, setFilters] = useState([]);
  const {
    hasMore,
    next,
    jobs,
    isLoading: isJobsLoading,
    loadMore,
    error: jobsError,
    setIsLoading
  } = useMyJobs({
    filters
  });
  return /* @__PURE__ */ React.createElement(
    MyJobsContext.Provider,
    {
      value: {
        jobs,
        filters,
        setFilters,
        hasMore,
        next,
        isJobsLoading,
        jobsError,
        loadMore,
        setIsLoading
      }
    },
    children
  );
};
