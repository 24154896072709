import React from "react";
import PropTypes from "prop-types";
import { formatDateString } from "../../shared/utils/utils";
import JSONObjectNode from "./JSONObjectNode";
import JSONArrayNode from "./JSONArrayNode";
import JSONIterableNode from "./JSONIterableNode";
import JSONValueNode from "./JSONValueNode";
import objType from "./utils/objType";
const JSONNode = ({
  getItemString,
  keyPath,
  labelRenderer,
  value,
  valueRenderer,
  isCustomNode,
  ...rest
}) => {
  const nodeType = isCustomNode(value) ? "Custom" : objType(keyPath[0], value);
  const simpleNodeProps = {
    getItemString,
    key: keyPath[0],
    keyPath,
    labelRenderer,
    nodeType,
    value,
    valueRenderer
  };
  const nestedNodeProps = {
    ...rest,
    ...simpleNodeProps,
    data: value,
    isCustomNode
  };
  switch (nodeType) {
    case "Object":
    case "Error":
      return /* @__PURE__ */ React.createElement(JSONObjectNode, { ...nestedNodeProps });
    case "Array":
      return /* @__PURE__ */ React.createElement(JSONArrayNode, { ...nestedNodeProps });
    case "Iterable":
    case "IterableLike":
      return /* @__PURE__ */ React.createElement(JSONIterableNode, { ...nestedNodeProps });
    case "String":
      return /* @__PURE__ */ React.createElement(
        JSONValueNode,
        {
          ...simpleNodeProps,
          valueGetter: (raw) => raw.toString(),
          valueRenderer: (raw) => /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement("span", { className: "punctuation" }, '"'), raw.split("\n").map((string, i) => {
            if (i === 0) {
              return /* @__PURE__ */ React.createElement("span", { key: `${string}-${i}` }, string);
            } else {
              return /* @__PURE__ */ React.createElement("span", { key: `${string}-${i}` }, /* @__PURE__ */ React.createElement("br", null), string);
            }
          }), /* @__PURE__ */ React.createElement("span", { className: "punctuation" }, '"'))
        }
      );
    case "Number":
      return /* @__PURE__ */ React.createElement(JSONValueNode, { ...simpleNodeProps });
    case "Boolean":
      return /* @__PURE__ */ React.createElement(
        JSONValueNode,
        {
          ...simpleNodeProps,
          valueGetter: (raw) => {
            if (Object.prototype.toString.call(raw).slice(8, -1) === "String") {
              return raw;
            } else if (raw) {
              return "true";
            } else {
              return "false";
            }
          }
        }
      );
    case "Date":
      return /* @__PURE__ */ React.createElement(
        JSONValueNode,
        {
          ...simpleNodeProps,
          valueGetter: (raw) => raw.toISOString()
        }
      );
    case "Null":
      return /* @__PURE__ */ React.createElement(
        JSONValueNode,
        {
          ...simpleNodeProps,
          valueGetter: () => "null"
        }
      );
    case "Undefined":
      return /* @__PURE__ */ React.createElement(
        JSONValueNode,
        {
          ...simpleNodeProps,
          valueGetter: () => "undefined"
        }
      );
    case "Function":
    case "Symbol":
      return /* @__PURE__ */ React.createElement(
        JSONValueNode,
        {
          ...simpleNodeProps,
          valueGetter: (raw) => raw.toString()
        }
      );
    case "Link":
      return /* @__PURE__ */ React.createElement(
        JSONValueNode,
        {
          ...simpleNodeProps,
          valueRenderer: (raw) => /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement("span", { className: "punctuation" }, '"'), /* @__PURE__ */ React.createElement(
            "a",
            {
              href: raw,
              target: "_blank",
              rel: "noopener noreferrer"
            },
            raw
          ), /* @__PURE__ */ React.createElement("span", { className: "punctuation" }, '"'))
        }
      );
    case "Timestamp":
      return /* @__PURE__ */ React.createElement(
        JSONValueNode,
        {
          ...simpleNodeProps,
          valueRenderer: (raw) => formatDateString(raw)
        }
      );
    case "Duration":
      return /* @__PURE__ */ React.createElement(
        JSONValueNode,
        {
          ...simpleNodeProps,
          valueRenderer: (raw) => /* @__PURE__ */ React.createElement("span", null, raw, /* @__PURE__ */ React.createElement("span", { className: "punctuation" }, " ms"))
        }
      );
    case "StatusCompleted":
    case "StatusCanceled":
    case "StatusErrored":
    case "StatusPending":
    case "StatusRunning":
      return /* @__PURE__ */ React.createElement(JSONValueNode, { ...simpleNodeProps });
    default:
      return null;
  }
};
JSONNode.propTypes = {
  getItemString: PropTypes.func.isRequired,
  keyPath: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  labelRenderer: PropTypes.func.isRequired,
  value: PropTypes.any,
  valueRenderer: PropTypes.func.isRequired,
  isCustomNode: PropTypes.func.isRequired
};
export default JSONNode;
