import { useCallback, useEffect, useState } from "react";
import { deleteApiKey, fetchApiKeys, postAddApiKey } from "./utils";
export const useApiKeys = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [apiKeys, setApiKeys] = useState([]);
  const [error, setError] = useState(null);
  useEffect(() => {
    fetchApiKeys({
      onSuccess: (members) => {
        setError(null);
        setApiKeys(members);
        setIsLoading(false);
      },
      onError: (e) => {
        setError(e);
        setIsLoading(false);
      }
    });
  }, []);
  const addApiKey = useCallback(
    ({ apiKeyName, apiProjectId, onAddSuccess, onAddError }) => {
      postAddApiKey({
        apiKeyName,
        apiProjectId,
        onSuccess: (newApiKey) => {
          const updatedKeys = [...apiKeys, newApiKey];
          setApiKeys(updatedKeys);
          onAddSuccess(newApiKey.secret);
        },
        onError: onAddError
      });
    },
    [apiKeys]
  );
  const removeApiKey = useCallback(
    ({ apiKey, onRemoveSuccess, onRemoveError }) => {
      deleteApiKey({
        apiKey,
        onSuccess: () => {
          const updatedApiKeys = apiKeys.filter(
            (key) => key.id !== apiKey
          );
          setApiKeys(updatedApiKeys);
          onRemoveSuccess();
        },
        onError: onRemoveError
      });
    },
    [apiKeys]
  );
  return {
    addApiKey,
    removeApiKey,
    apiKeys,
    error,
    isLoading
  };
};
