import React, { useState } from "react";
import sampleProgram from "../../../lib/sampleProgram";
import "./IonVisualizerSVG.css";
import { IonVisualizerCircuitGroup } from "./IonVisualizerCircuitGroup";
import { IonVisualizerWires } from "./IonVisualizerWires";
import IonVisualizerEmbeddedStyles from "./IonVisualizerEmbeddedStyles";
const measurements = {
  wireWidth: 42,
  gateRowHeight: 40,
  sequenceHeaderSize: 30,
  sequenceOuterPadding: 30,
  sequenceInnerPadding: 10,
  labelYPadding: 8,
  labelXPadding: 10,
  labelLineHeight: 20,
  labelCharacterWidth: 7.5,
  collapsedSequenceSize: 30,
  SVGheaderSize: 60,
  SVGfooterSize: 40
};
export const IonVisualizerSVG = (props) => {
  const [collapsedSequences, setCollapsedSequences] = useState([]);
  const handleSequenceHeaderClick = (id) => {
    let newCollapsedSequences;
    if (collapsedSequences.includes(id)) {
      newCollapsedSequences = collapsedSequences.filter((e) => e !== id);
    } else {
      newCollapsedSequences = [...collapsedSequences, id];
    }
    setCollapsedSequences(newCollapsedSequences);
  };
  const measureSequence = (slice, qubitsUsed) => {
    let measurementOffset = measurements.sequenceHeaderSize + measurements.sequenceInnerPadding;
    return slice[0].sequence.ops.map((op) => {
      const sequenceHasSequenceChildren = op[0].sequence;
      const childOffset = sequenceHasSequenceChildren ? measurements.sequenceOuterPadding : 0;
      return measureTimeSlice(op, qubitsUsed) + childOffset;
    }).reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, measurementOffset);
  };
  const measureTimeSlice = (slice, qubitsUsed) => {
    if (slice[0].sequence) {
      if (collapsedSequences.includes(slice[0].sequence.id))
        return measurements.collapsedSequenceSize;
      else return measureSequence(slice, qubitsUsed);
    } else if (slice[0].label) {
      return measureLabel(slice[0].label, qubitsUsed);
    } else {
      return measurements.gateRowHeight;
    }
  };
  const splitLabelIntoLines = (text, qubitsUsed) => {
    if (Array.isArray(text)) {
      return text;
    } else {
      const max = Math.max(...qubitsUsed);
      const min = Math.min(...qubitsUsed);
      const labelWidth = measurements.wireWidth * (max - min) + measurements.wireWidth;
      const textWidth = labelWidth - measurements.labelXPadding * 2;
      const maxCharacters = Math.floor(
        textWidth / measurements.labelCharacterWidth
      );
      const linesCount = Math.ceil(text.length / maxCharacters);
      const lines = new Array(linesCount);
      for (let i = 0, o = 0; i < linesCount; ++i, o += maxCharacters) {
        lines[i] = text.substr(o, maxCharacters);
      }
      return lines;
    }
  };
  const measureLabel = (label, qubitsUsed) => {
    const lines = splitLabelIntoLines(label.text, qubitsUsed);
    return lines.length * measurements.labelLineHeight + measurements.labelYPadding * 2;
  };
  const getOpType = (op) => {
    if (op[0].sequence) {
      return "sequence";
    } else if (op[0].gate) {
      return "gateRow";
    } else if (op[0].line) {
      return "line";
    } else if (op[0].label) {
      return "label";
    }
  };
  const getDecoratedTimeSlices = (ops, qubitsUsed, isNested) => {
    let decoratedOps = [];
    let offsetAccumulator = isNested ? measurements.sequenceHeaderSize : 0;
    let idCounter = 0;
    ops.forEach((op) => {
      const type = getOpType(op);
      const height = measureTimeSlice(op, qubitsUsed);
      let opObject = {
        id: idCounter,
        verticalOffset: offsetAccumulator,
        ops: op,
        height,
        type
      };
      if (type === "sequence") {
        const sequence = op[0].sequence;
        opObject.ops = getDecoratedTimeSlices(
          sequence.ops,
          sequence.qubitsUsed,
          true
        );
        opObject.verticalOffset = opObject.verticalOffset += measurements.sequenceOuterPadding / 2;
        opObject.id = sequence.id;
        opObject.name = sequence.name;
        opObject.color = sequence.color;
        opObject.qubitsUsed = sequence.qubitsUsed;
        offsetAccumulator += measurements.sequenceOuterPadding;
      }
      if (type === "label") {
        opObject.ops[0].label.text = splitLabelIntoLines(
          opObject.ops[0].label.text,
          qubitsUsed
        );
      }
      decoratedOps.push(opObject);
      idCounter++;
      offsetAccumulator += height;
    });
    if (isNested) {
      return decoratedOps;
    } else {
      return { ops: decoratedOps, totalHeight: offsetAccumulator };
    }
  };
  const loadSampleProgram = () => {
    props.updateEditorTitle("My First Quantum Program");
    props.setEditorLanguage("sqore");
    props.updateEditorCode({
      language: "sqore",
      newCode: sampleProgram
    });
  };
  const submitProgram = () => {
    props.toggleAutoRun(false);
    props.showSubmitModal();
  };
  const { circuit, code, language, isHorizontal, zoom, error, setZoom } = props;
  const drawCircuit = (circuitOverride = circuit) => {
    const qubitsArray = Array.from(
      { length: circuitOverride.pool.maxQubits },
      (v, k) => k++
    );
    const timeSlices = getDecoratedTimeSlices(
      circuitOverride.ops,
      qubitsArray,
      false
    );
    const svgHeight = timeSlices.totalHeight + measurements.SVGheaderSize + measurements.SVGfooterSize;
    const svgWidth = circuitOverride.pool.maxQubits * measurements.wireWidth;
    const horizontalContainerStyle = {
      height: `${svgWidth * zoom + 10}px`,
      width: `${svgHeight * zoom + 40}px`,
      overflow: "hidden"
    };
    return /* @__PURE__ */ React.createElement("div", { style: isHorizontal ? horizontalContainerStyle : {} }, /* @__PURE__ */ React.createElement(
      "svg",
      {
        className: `generated-svg`,
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: isHorizontal ? `0 0 ${svgHeight} ${svgWidth}` : `0 0 ${svgWidth} ${svgHeight}`,
        width: isHorizontal ? `${svgHeight * zoom}px` : `${svgWidth * zoom}px`,
        height: isHorizontal ? `${svgWidth * zoom}px` : `${svgHeight * zoom}px`,
        ref: props.svgRef
      },
      /* @__PURE__ */ React.createElement(
        "style",
        {
          dangerouslySetInnerHTML: {
            __html: IonVisualizerEmbeddedStyles
          }
        }
      ),
      /* @__PURE__ */ React.createElement(
        "g",
        {
          transform: isHorizontal ? `rotate(-90) translate(-${svgWidth})` : ``
        },
        /* @__PURE__ */ React.createElement(
          IonVisualizerWires,
          {
            qubitCount: circuitOverride.pool.maxQubits,
            isHorizontal,
            wireWidth: measurements.wireWidth,
            svgHeight
          }
        ),
        /* @__PURE__ */ React.createElement(
          "g",
          {
            transform: `translate(0,${measurements.SVGheaderSize})`
          },
          /* @__PURE__ */ React.createElement(
            IonVisualizerCircuitGroup,
            {
              timeSlices: timeSlices.ops,
              measurements,
              svgWidth,
              handleSequenceHeaderClick,
              collapsedSequences,
              parentQubitsUsed: qubitsArray,
              isHorizontal,
              setZoom
            }
          )
        )
      )
    ));
  };
  if (!code || code.circuit && code.circuit.length === 0 || !code.circuit && code.trim().length === 0) {
    return /* @__PURE__ */ React.createElement("div", { className: "no-circuit-container" }, /* @__PURE__ */ React.createElement("p", { className: "no-circuit-message" }, "As you write your quantum program, this panel will visualize the circuit you create."), /* @__PURE__ */ React.createElement("p", { className: "no-circuit-action", onClick: loadSampleProgram }, "load a sample program"));
  } else if (circuit && (language === "sqore" || language === "json") && !error) {
    return drawCircuit();
  } else if (code && code.circuit && language === "json" && !error) {
    return drawCircuit({
      pool: {
        maxQubits: code.qubits
      },
      ops: code.circuit.map((e) => [
        {
          gate: {
            type: e.gate,
            targets: e.targets || [e.target],
            controls: e.controls || (e.control ? [e.control] : []),
            ...e
          }
        }
      ])
    });
  } else if (error && error.indexOf("no import") > -1) {
    return /* @__PURE__ */ React.createElement("div", { className: "no-circuit-container" }, /* @__PURE__ */ React.createElement("p", { className: "no-circuit-message" }, "We can only visualize quantum circuits today."), /* @__PURE__ */ React.createElement("p", { className: "no-circuit-action", onClick: submitProgram }, "Please turn off automatic in-browser simulation and submit your program to see the results!"));
  } else if (error) {
    return /* @__PURE__ */ React.createElement("div", { className: "no-circuit-container" }, /* @__PURE__ */ React.createElement("p", { className: "no-circuit-message" }, "Circuit could not be visualized due to the following error:"), /* @__PURE__ */ React.createElement("p", { className: "no-circuit-error-message" }, error));
  } else if (language !== "sqore" && language !== "json") {
    return /* @__PURE__ */ React.createElement("div", { className: "no-circuit-container" }, /* @__PURE__ */ React.createElement("p", { className: "no-circuit-message" }, "The circuit visualizer can only draw simple circuits written in JSON."));
  } else if (!circuit) {
    return /* @__PURE__ */ React.createElement("div", { className: "no-circuit-container" }, /* @__PURE__ */ React.createElement("p", { className: "no-circuit-message" }, "Sorry, something happened. Try reloading the page."));
  }
};
