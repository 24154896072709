import React from "react";
import { Text } from "@chakra-ui/react";
import {
  getIonDateFormat,
  getTooltipFormattedDate,
  USD
} from "../../util/format.js";
import { IonTooltip } from "./IonTooltip/index.js";
import { Icon } from "@chakra-ui/icons";
import { MdGrain } from "react-icons/md";
import { JobStatusTag } from "./JobStatusTag.js";
import { NameCell } from "./IonTableCells/NameCell.js";
export const sharedColumns = ({
  row,
  hasAccessToPaidBackend,
  noiseModelName
}) => {
  return {
    request: /* @__PURE__ */ React.createElement(IonTooltip, { label: `${getTooltipFormattedDate(row.request)} ago` }, /* @__PURE__ */ React.createElement(
      Text,
      {
        textStyle: "body-8",
        color: "ionDark",
        minW: "180px",
        maxW: "200px"
      },
      getIonDateFormat(row.request)
    )),
    name: /* @__PURE__ */ React.createElement(NameCell, { name: row.name }),
    circuits: /* @__PURE__ */ React.createElement(
      Text,
      {
        textStyle: "body-8",
        color: row.circuits ? "ionDark" : "gray.500"
      },
      row.circuits || "1"
    ),
    usage: row?.cost_usd === null ? null : /* @__PURE__ */ React.createElement(Text, { textStyle: "body-8", color: "ionDark", maxW: "120px" }, hasAccessToPaidBackend ? USD.format(row?.cost_usd ?? 0) : "$0.00"),
    target: /* @__PURE__ */ React.createElement(Text, { textStyle: "body-8", color: "ionDark", maxW: "120px" }, row.target, row.noise && row.noise.model && row.noise.model !== "ideal" ? /* @__PURE__ */ React.createElement(IonTooltip, { label: noiseModelName }, /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement(
      Icon,
      {
        boxSize: "18px",
        as: MdGrain,
        ml: "4px",
        color: "gray.600",
        verticalAlign: "sub"
      }
    ))) : null),
    id: row.id,
    status: /* @__PURE__ */ React.createElement(JobStatusTag, { status: row.status }),
    statusRaw: row.status
  };
};
