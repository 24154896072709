import ace from "ace-builds";
ace.config.set("basePath", "/node_modules/ace-builds/src-min-noconflict");
ace["define"](
  "ace/theme/ionq",
  ["require", "exports", "module", "ace/lib/dom"],
  function(acequire, exports) {
    const colorVars = {
      //Grays -- nearly a base16 set but a little different in implementation
      grayColor00: "#222222",
      grayColor01: "#333333",
      grayColor02: "#666666",
      grayColor03: "#999999",
      grayColor04: "#BBBBBB",
      grayColor05: "#DDDDDD",
      grayColor06: "#E8E8E8",
      grayColor07: "#F9F9F9",
      white: "#FFFFFF",
      //Core colors -- nearly a base16 set but a little different in implementation
      //The "alternate" purples are different for people with color vision but
      //identical to most colorblind people
      coolColor00: "#4e75b4",
      //navy
      coolColor01: "#3b73ff",
      //blue
      coolColor02: "#2989d9",
      //blue
      coolColor02Alt: "#9359b2",
      //purple
      coolColor03: "#1398b7",
      //teal
      coolColor03Alt: "#9b86bc",
      //purple
      warmColor00: "#993d1d",
      //rust
      warmColor01: "#d67b3e",
      //orange
      warmColor02: "#deaa37",
      //mustardy yellow
      //Ancillary colors -- for rare use
      //a couple of very light warm colors for highlights and stuff
      warmColor04: "#ffbb99",
      //salmon pink
      warmColor05: "#fffdbb",
      //pale yellow
      // special "alert" colors
      // no good for colorblinds, always need to pair with a symbol or text.
      "alert-color-success": "#008454",
      //green
      "alert-color-error": "#d34233",
      //red
      "alert-color-warn": "#ffdc48",
      //yellow
      //brand -- for rare use
      "ionq-color-yellow-orange": "#F89B33",
      "ionq-color-orange": "#EC7B37",
      "ionq-color-red-orange": "#DE5125"
    };
    exports.isDark = false;
    exports.cssClass = "ace-ionq";
    exports.cssText = `
.ace-ionq  {
  background: ${colorVars.white};
  color: ${colorVars.grayColor03};
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}
.ace-ionq .ace_gutter {
  background: ${colorVars.white};
  color: ${colorVars.grayColor04};
}
.ace-ionq .ace_keyword {

}
.ace-ionq .ace_keyword.ace_quantum {
  font-weight: bold;
  color: ${colorVars.coolColor02Alt};
}
.ace-ionq .ace_keyword.ace_operator {
  font-weight: bold;
  color: ${colorVars.grayColor02};
}
.ace-ionq .ace_string {
  color: ${colorVars.warmColor00};
}
.ace-ionq .ace_variable {
  color: ${colorVars.grayColor02};
}
.ace-ionq .ace_variable.ace_class {
  color: ${colorVars.coolColor03};
}
.ace-ionq .ace_constant.ace_numeric {
  color: ${colorVars.warmColor02};
}
.ace-ionq .ace_constant.ace_buildin {
  color: ${colorVars.warmColor01};
}
.ace-ionq .ace_support.ace_function {
  color: ${colorVars.coolColor00};
}
.ace-ionq .ace_comment {
  color: ${colorVars.grayColor04};
  font-style: italic;
}
.ace-ionq .ace_variable.ace_language  {
  color: #0086B3;
}
.ace-ionq .ace_storage.ace_type  {
  color: ${colorVars.coolColor02};
}
.ace-ionq .ace_identifier  {
  color: ${colorVars.grayColor02};
}
.ace-ionq .ace_paren {
  font-weight: 400;
  color: ${colorVars.grayColor03};
}
.ace-ionq .ace_boolean {
  color: ${colorVars.warmColor02};
}
.ace-ionq .ace_string.ace_regexp {
  color: ${colorVars.warmColor02};
  font-weight: normal;
}
.ace-ionq .ace_variable.ace_instance {
  color: ${colorVars.coolColor00};
}
.ace-ionq .ace_constant.ace_language {
  font-weight: bold;
}
.ace-ionq .ace_constant.ace_language.ace_boolean,
.ace-ionq .ace_constant.ace_language.ace_escape
 {
  font-weight: 400;
}
.ace-ionq .ace_cursor {
  color: ${colorVars.grayColor00};
}
.ace-ionq.ace_focus .ace_marker-layer .ace_active-line {
  background: rgba(0,0,0,0.04);
}
.ace-ionq .ace_marker-layer .ace_active-line {
  background : rgba(0,0,0,0.04);
}
.ace-ionq .ace_marker-layer .ace_selection {
  background-color : rgba(0,0,0,0.06);
}
.ace-ionq.ace_multiselect .ace_selection.ace_start {
  box-shadow: 0 0 3px 0px white;
}
.ace-ionq.ace_nobold .ace_line > span {
  font-weight: normal !important;
}
.ace-ionq .ace_marker-layer .ace_step {
  background: rgb(252, 255, 0);
}
.ace-ionq .ace_marker-layer .ace_stack {
  background: rgb(164, 229, 101);
}
.ace-ionq .ace_marker-layer .ace_bracket {
  margin: -1px 0 0 -1px;
  border: 1px solid rgb(192, 192, 192);
}
.ace-ionq .ace_gutter-active-line {
  background-color : rgba(0,0,0,0.04);
}
.ace-ionq .ace_marker-layer .ace_selected-word {
  background-color : rgba(0,0,0,0.06);
  border: 1px solid rgb(200, 200, 250);
}
.ace-ionq .ace_invisible {
  color: #BFBFBF
}
.ace-ionq .ace_print-margin {
  width: 1px;
  background: #e8e8e8;
}
.ace-ionq .ace_indent-guide {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAACCAYAAACZgbYnAAAAE0lEQVQImWP4////f4bLly//BwAmVgd1/w11/gAAAABJRU5ErkJggg==") right repeat-y;
}
`;
    const dom = acequire("ace/lib/dom");
    dom.importCssString(exports.cssText, exports.cssClass);
  }
);
