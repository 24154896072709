import React from "react";
import {
  Box,
  Button,
  Flex,
  IconButton,
  Spacer,
  Tag,
  Text
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import imgUrl from "../../assets/images/Projects_Dark_Background.png";
export const HIDE_BANNER = "__hide_projects_banner__";
export const HIDE_BANNER_YES_VALUE = "yes";
export const HeaderBanner = ({ setBannerHidden }) => {
  return /* @__PURE__ */ React.createElement(
    Box,
    {
      borderRadius: "5px",
      bg: "#11191F",
      w: "100%",
      p: "24px",
      overflow: "hidden",
      backgroundImage: [null, null, `url(${imgUrl})`],
      backgroundPosition: [
        null,
        null,
        "right 40px center",
        "right 40px center",
        "right 72px top -83px"
      ],
      backgroundRepeat: [null, null, "no-repeat"],
      backgroundSize: [null, null, "350px", "420px", "483px"]
    },
    /* @__PURE__ */ React.createElement(Flex, null, /* @__PURE__ */ React.createElement(
      Tag,
      {
        borderRadius: "3px",
        size: "sm",
        color: "uiBlue",
        bg: "uiLightBlue",
        maxHeight: "12px",
        p: "1px 6px"
      },
      /* @__PURE__ */ React.createElement(Text, { textStyle: "body-9" }, "New")
    ), /* @__PURE__ */ React.createElement(Spacer, null), /* @__PURE__ */ React.createElement(
      IconButton,
      {
        "aria-label": "Close Banner",
        bg: "transparent",
        colorScheme: "blackAlpha",
        onClick: () => {
          localStorage.setItem(
            HIDE_BANNER,
            HIDE_BANNER_YES_VALUE
          );
          setBannerHidden(true);
        },
        icon: /* @__PURE__ */ React.createElement(CloseIcon, null)
      }
    )),
    /* @__PURE__ */ React.createElement(
      Text,
      {
        color: "white",
        fontSize: "lg",
        fontWeight: 500,
        lineHeight: 1.2,
        maxWidth: ["100%", null, "35%", "50%"],
        mt: "12px",
        mb: "8px"
      },
      "Welcome to Projects: a new way to manage your work"
    ),
    /* @__PURE__ */ React.createElement(
      Text,
      {
        color: "white",
        fontSize: "sm",
        maxWidth: ["90%", null, "35%", "50%"]
      },
      "Projects are collaborative workspaces that make sharing data and controlling budgets easier than ever \u2014 create projects, invite members, track usage, and more from the new Projects view."
    ),
    /* @__PURE__ */ React.createElement(
      "a",
      {
        href: "https://docs.ionq.com/user-manual/projects",
        target: "_blank",
        rel: "noreferrer"
      },
      /* @__PURE__ */ React.createElement(Button, { variant: "blue", mt: "16px", size: "lg" }, "Learn more about projects")
    )
  );
};
