import React from "react";
import PropTypes from "prop-types";
import JSONNestedNode from "./JSONNestedNode";
import ObjectPreview from "./ObjectPreview";
function createItemString(data) {
  return /* @__PURE__ */ React.createElement("span", { className: "previewValue -object" }, /* @__PURE__ */ React.createElement("span", { className: "punctuation -left-bracket" }, "{"), /* @__PURE__ */ React.createElement(ObjectPreview, { object: data }), /* @__PURE__ */ React.createElement("span", { className: "punctuation -right-bracket" }, "}"));
}
const JSONObjectNode = ({ data, ...props }) => /* @__PURE__ */ React.createElement(
  JSONNestedNode,
  {
    ...props,
    data,
    nodeType: "Object",
    nodeTypeIndicator: props.nodeType === "Error" ? "Error()" : "{}",
    createItemString,
    expandable: Object.getOwnPropertyNames(data).length > 0
  }
);
JSONObjectNode.propTypes = {
  data: PropTypes.object,
  nodeType: PropTypes.string
};
export default JSONObjectNode;
