import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { IonTooltip } from "../shared/IonTooltip";
export const ColumnGridBox = ({ title, tooltipMessage, value, isLastItem }) => {
  return /* @__PURE__ */ React.createElement(
    Box,
    {
      p: "24px 40px",
      borderRight: [0, isLastItem ? "" : "1px solid #E0E0E3"],
      borderBottom: [isLastItem ? "" : "1px solid #E0E0E3", 0],
      borderColor: "gray.200"
    },
    /* @__PURE__ */ React.createElement(Box, { w: "100%" }, /* @__PURE__ */ React.createElement(Text, { as: "span", textStyle: "body-3", color: "gray.600" }, title), tooltipMessage && /* @__PURE__ */ React.createElement(IonTooltip, { label: tooltipMessage })),
    /* @__PURE__ */ React.createElement(Text, { mt: 0, textStyle: "body-7", color: "gray.900" }, value)
  );
};
export const KVTable = ({ title, label, value, isBig = false }) => {
  return /* @__PURE__ */ React.createElement(Flex, { pb: isBig ? "6px" : 0 }, /* @__PURE__ */ React.createElement(Box, { whiteSpace: "nowrap", w: ["50%", "100%"] }, typeof title === "string" ? /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(Text, { as: "span", textStyle: "body-3", color: "gray.500" }, title, label && /* @__PURE__ */ React.createElement(IonTooltip, { label }))) : title), /* @__PURE__ */ React.createElement(Box, { w: ["50%", "100%"], ml: "10px" }, typeof value === "string" ? /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(Text, { textStyle: "body-8", color: "gray.900" }, value)) : value));
};
export const KVTableContainer = ({ items }) => {
  return /* @__PURE__ */ React.createElement(Box, { p: ["0 0 0 24px", "0 4px 0 24px"] }, items.map(({ title, label, value, isBig }, index) => /* @__PURE__ */ React.createElement(
    KVTable,
    {
      key: label + index,
      title,
      label,
      value,
      isBig
    }
  )));
};
