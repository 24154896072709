import React from "react";
import getPreviewValueView from "./utils/getPreviewValueView";
const ObjectPreview = ({ object }) => {
  const preview = Object.entries(object).map(([key, value], i) => {
    const outputKey = key.toString();
    const outputValue = getPreviewValueView(key, value);
    if (i === 0) {
      return /* @__PURE__ */ React.createElement("span", { key: outputKey }, outputKey, ":", outputValue);
    } else {
      return /* @__PURE__ */ React.createElement("span", { key: outputKey }, /* @__PURE__ */ React.createElement("span", { className: "punctuation -comma" }, ","), " ", outputKey, ":", outputValue);
    }
  });
  return preview;
};
export default ObjectPreview;
