import { Center, CloseButton, Flex, Text } from "@chakra-ui/react";
import { Icon } from "@chakra-ui/icons";
import React from "react";
import { MdCheckCircle, MdWarningAmber } from "react-icons/md";
export const IonToast = ({ message, status, toast, toastIdRef }) => {
  const isSuccess = status === "success";
  function close() {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current);
    }
  }
  return /* @__PURE__ */ React.createElement(
    Flex,
    {
      justifyContent: "space-between",
      padding: 4,
      backgroundColor: isSuccess ? "#CEE7DE" : "#F9E3E1",
      maxHeight: "48px",
      lineHeight: "1.235rem",
      borderRadius: "5px",
      marginBottom: "24px",
      marginLeft: "24px"
    },
    /* @__PURE__ */ React.createElement(Center, null, isSuccess ? /* @__PURE__ */ React.createElement(Icon, { as: MdCheckCircle, color: "#00794A" }) : /* @__PURE__ */ React.createElement(Icon, { as: MdWarningAmber, color: "#D34233" })),
    /* @__PURE__ */ React.createElement(
      Text,
      {
        fontSize: "14px",
        fontWeight: 400,
        color: "gray.700",
        marginLeft: "8px",
        isTruncated: true
      },
      message
    ),
    /* @__PURE__ */ React.createElement(Center, null, /* @__PURE__ */ React.createElement(
      CloseButton,
      {
        size: "sm",
        onClick: close,
        color: "gray.700",
        marginLeft: "8px"
      }
    ))
  );
};
export const errorToast = ({ toastIdRef, toast }) => (e) => {
  toastIdRef.current = toast({
    duration: 5e3,
    position: "bottom-left",
    render: () => /* @__PURE__ */ React.createElement(
      IonToast,
      {
        message: e,
        status: "warning",
        toast,
        toastIdRef
      }
    )
  });
};
export const successToast = ({ message, status, toastIdRef, toast }) => () => {
  toastIdRef.current = toast({
    duration: 5e3,
    position: "bottom-left",
    render: () => /* @__PURE__ */ React.createElement(
      IonToast,
      {
        message,
        status,
        toast,
        toastIdRef
      }
    )
  });
};
