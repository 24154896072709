import React from "react";
import objType from "./objType";
import NodeIcon from "../NodeIcon";
const getPreviewValueView = (keyOrIndex, value) => {
  const type = objType(keyOrIndex, value);
  switch (type) {
    case "Object":
    case "Error":
    case "Array":
    case "Iterable":
    case "IterableLike":
    case "Function":
    case "Symbol":
      return /* @__PURE__ */ React.createElement(NodeIcon, { nodeType: type });
    case "String":
    case "Link": {
      const string = value.length > 12 ? value.toString().substring(0, 10) + "..." : value.toString();
      return /* @__PURE__ */ React.createElement("span", { className: `valueText -preview -${type.toLowerCase()}` }, /* @__PURE__ */ React.createElement("span", { className: "punctuation" }, '"'), string, /* @__PURE__ */ React.createElement("span", { className: "punctuation" }, '"'));
    }
    case "Number":
      return /* @__PURE__ */ React.createElement("span", { className: "valueText -number" }, value.toString());
    case "Boolean":
      return /* @__PURE__ */ React.createElement("span", { className: "valueText -boolean" }, value.toString());
    case "Date":
      return /* @__PURE__ */ React.createElement("span", { className: "valueText -date" }, value.toUTCString());
    case "Null":
      return /* @__PURE__ */ React.createElement("span", { className: "valueText -null" }, "null");
    case "Undefined":
      return /* @__PURE__ */ React.createElement("span", { className: "valueText -undefined" }, "undefined");
    default:
      return /* @__PURE__ */ React.createElement("span", null, value.toString());
  }
};
export default getPreviewValueView;
