import { saveAs } from "file-saver";
import Cookies from "js-cookie";
const { REACT_APP_API_BASE_URL, REACT_APP_SSO_BASE_URL } = window._dynamic_env_;
export const DEFAULT_MINUTES_UNTIL_SESSION_TIMEOUT = 30;
export const SESSION_COOKIE_NAME = "__Secure-Session";
export class Client {
  constructor(baseURL2, onSessionExpire) {
    this.baseURL = baseURL2;
    this.onSessionExpire = onSessionExpire ? onSessionExpire : () => {
    };
  }
  async request({ method, path, body }) {
    const accessToken = Cookies.get(SESSION_COOKIE_NAME);
    try {
      const response = await fetch(`${this.baseURL}${path}`, {
        method,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "content-type": "application/json"
        },
        body: body && JSON.stringify(body)
      });
      const contentType = response.headers.get("content-type");
      if (!contentType || !contentType.startsWith("application/json")) {
        return response.text();
      }
      if (response.status === 204) {
        return "success";
      }
      const res = await response.json();
      if (response.status >= 400) {
        if (response.status === 401) {
          await this.onSessionExpire();
          if (path === "/users/me") {
            window.location.href = `${REACT_APP_SSO_BASE_URL}/sign-out`;
          }
        } else {
          let error = new Error("Failed request");
          if (res.message) {
            error.message = res.message;
          }
          error.response = res;
          throw error;
        }
      }
      return res;
    } catch (message) {
      console.error(message);
      throw message;
    }
  }
  async get(path) {
    return this.request({ method: "GET", path });
  }
  async post({ path, content }) {
    return this.request({ method: "POST", path, body: content });
  }
  async patch({ path, content }) {
    return this.request({ method: "PATCH", path, body: content });
  }
  async put({ path, content }) {
    return this.request({ method: "PUT", path, body: content });
  }
  async delete({ path }) {
    return this.request({ method: "DELETE", path });
  }
}
const baseURL = REACT_APP_API_BASE_URL;
export const client = new Client(baseURL);
const api = {
  buildJobQuery: (filters = {}) => {
    let query = [];
    const { parent, status, date, backend, id, name, type, metadata } = filters;
    if (parent) {
      query.push(`parent=${parent}`);
    }
    if (status && status.active) {
      query.push(`status=${status.status}`);
    }
    if (date && date.active) {
      query.push(
        `date=${date.control}%3D${date.date}${date.betweenEndDate ? "|" + date.betweenEndDate : ""}`
      );
    }
    if (backend && backend.active) {
      query.push(`backend=${backend.backend}`);
    }
    if (id && id.active) {
      query.push(`id=${id.contains}`);
    }
    if (name && name.active) {
      query.push(`name=${name.contains}`);
    }
    if (type && type.active) {
      query.push(`type=${type.type}`);
    }
    if (metadata && metadata.active) {
      query.push(
        `metadata=${metadata.keyString}%3D${metadata.valueString}`
      );
    }
    query = query.join("&");
    return query;
  },
  exportProjectJobs: ({ params, selectedIds }) => {
    const accessToken = Cookies.get(SESSION_COOKIE_NAME);
    const query = new URLSearchParams(params).toString();
    const url = `${baseURL}/jobs/download.csv?${query || ""}${query ? "&" : ""}${(selectedIds || []).map((id) => `id=${id}`).join("&")}`;
    return fetch(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "text/csv"
      },
      responseType: "blob"
    }).then((response) => {
      if (response.status !== 200)
        throw new Error("unauthorized");
      return response.blob();
    }).then((blob) => saveAs(blob, "download.csv")).catch(console.error);
  },
  exportJobs: ({ params, selectedIds }) => {
    const accessToken = Cookies.get(SESSION_COOKIE_NAME);
    const query = new URLSearchParams(params).toString();
    const url = `${baseURL}/users/me/jobs/download.csv?${query || ""}${query ? "&" : ""}${(selectedIds || []).map((id) => `id=${id}`).join("&")}`;
    return fetch(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "text/csv"
      }
    }).then((response) => {
      if (response.status !== 200)
        throw new Error("unauthorized");
      return response.blob();
    }).then((blob) => saveAs(blob, "download.csv")).catch(console.error);
  },
  updatePassword: (content) => {
    return client.post({
      path: "/users/password",
      content
    });
  },
  enrollAuthFactor: () => {
    return client.post({
      path: `/users/factors/google`
    });
  },
  activateAuthFactor: (passCode) => {
    return client.post({
      path: `/users/factors/google/activate`,
      content: {
        passCode
      }
    });
  },
  listJobs: (query) => client.get(`/users/me/jobs?${query || ""}`),
  listProjectMembers: (projectId) => {
    return client.get(`/projects/${projectId}/members`);
  },
  addProjectMembers: (projectId, content) => {
    return client.post({
      path: `/projects/${projectId}/members`,
      content
    });
  },
  deleteProjectMember: ({ projectId, userId }) => {
    return client.delete({
      path: `/projects/${projectId}/members/${userId}`
    });
  },
  listProjects: () => {
    return client.get("/projects");
  },
  createProject: (content) => {
    return client.post({
      path: "/projects",
      content
    });
  },
  updateProject: (id, content) => {
    return client.patch({
      path: `/projects/${id}`,
      content
    });
  },
  getProject: (id) => {
    return client.get(`/projects/${id}`);
  },
  getUser: () => {
    return client.get("/users/me");
  },
  getOrganizationUsers: () => {
    return client.get("/users").then((payload) => {
      return "users" in payload ? payload.users : payload;
    });
  },
  getOrganizationQuota: () => {
    return client.get("/billing/organizations/current/quotas");
  },
  updateOrganizationMemberQuota: (id, content) => {
    return client.post({
      path: `/billing/users/${id}/quotas`,
      content: {
        limit: content.limit || null
      }
    });
  },
  updateUser: (content) => {
    if (content.isTimeout) {
      return client.patch({
        path: "/users",
        content: content.minutesUntilSessionTimeout ? {
          minutesUntilSessionTimeout: DEFAULT_MINUTES_UNTIL_SESSION_TIMEOUT
        } : { minutesUntilSessionTimeout: null }
      });
    }
    return client.patch({
      path: "/users",
      content
    });
  },
  updateUserById: (id, content) => {
    return client.patch({
      path: `/users/${id}`,
      content
    });
  },
  deleteUserById: (id) => {
    return client.delete({ path: `/users/${id}` });
  },
  getKeys: () => {
    return client.get("/keys");
  },
  makeKey: (name, projectId) => {
    return client.post({
      path: "/keys",
      content: {
        name,
        projectId
      }
    });
  },
  renameKey: (from, to) => {
    return client.put({
      path: `/keys/${encodeURIComponent(from)}`,
      content: {
        name: to
      }
    });
  },
  revokeKey: (id) => {
    return client.delete({ path: `/keys/${id}` });
  },
  getLog: (id) => {
    return client.get(`/logs/${id}`);
  },
  listLogs: (query) => {
    return client.get(`/logs${query ? "?" + query : ""}`);
  },
  getCharacterization: (characterization_url) => {
    return client.get(characterization_url);
  },
  getBackends: () => {
    return client.get("/backends");
  },
  getJobProgram: (id) => {
    return client.get(`/jobs/${id}/program`);
  },
  getJobResults: (id) => {
    return client.get(`/jobs/${id}/results`);
  },
  getJob: (id) => client.get(`/jobs/${id}`),
  getInvites: () => client.get("/invites"),
  revokeInvite: (id) => client.delete({ path: `/invites/${id}` }),
  sendInvites: (content) => {
    return client.post({ path: "/invites", content });
  },
  resendInvite: (id) => client.post({ path: `/invites/${id}/resend` }),
  cancelJob: (id) => client.put({ path: `/jobs/${id}/status/cancel` }),
  checkOrganizationExists: (id) => client.get(`/organizations/${id}/exists`),
  getOrganization: (id) => client.get(`/organizations/${id}`),
  updateOrganization: (id, content) => {
    return client.patch({
      path: `/organizations/${id}`,
      content
    });
  }
};
export default api;
