import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
  VisuallyHidden
} from "@chakra-ui/react";
import { NumericFormat } from "react-number-format";
import { useBaseModal } from "../../shared/baseModals/useBaseModal";
import { MODALS } from "../../shared/baseModals/constants";
import { Form, Formik } from "formik";
import { WarningIcon } from "@chakra-ui/icons";
import { useProject } from "../hooks/useProject";
import { DECIMAL } from "../../../util/format";
import { StatusIcon } from "../../shared/StatusIcon";
import { useIonToast } from "../../shared/IonToast";
const CHANGE_BUDGET_MODAL_ERROR = "Error updating project budget. Please try again later.";
export const getNumberFromUsdFormattedString = (budget) => {
  if (!budget) return 0;
  const stringifiedNumber = budget.replace(/[$,]/g, "");
  if (isNaN(stringifiedNumber)) return 0;
  return Number(stringifiedNumber);
};
export const ChangeProjectBudgetModal = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const { toastSuccess } = useIonToast();
  const { close, isOpen } = useBaseModal(MODALS.changeProjectBudget);
  const { project, updateProject } = useProject();
  const { id, quotaLimitUsd, quotaUsageUsd } = project || {};
  const handleModalClose = () => {
    setSubmitError(null);
    setIsSubmitting(false);
    close();
  };
  const handleSubmit = (values) => {
    const { budget, radio } = values;
    setIsSubmitting(true);
    const quotaLimitUsd2 = radio === "1" ? null : getNumberFromUsdFormattedString(budget);
    updateProject({
      projectId: id,
      updatedFields: { quotaLimitUsd: quotaLimitUsd2 },
      onUpdateSuccess: () => {
        setIsSubmitting(false);
        toastSuccess(`Project budget has been successfully changed`);
        handleModalClose();
      },
      onUpdateError: (e) => {
        setIsSubmitting(false);
        setSubmitError(e ?? CHANGE_BUDGET_MODAL_ERROR);
      }
    });
  };
  return /* @__PURE__ */ React.createElement(
    Formik,
    {
      enableReinitialize: true,
      initialValues: {
        projectId: id,
        budget: DECIMAL.format(quotaLimitUsd || 0),
        radio: quotaLimitUsd === null ? "1" : "2"
      },
      onSubmit: handleSubmit
    },
    ({ values, handleChange, handleBlur, handleSubmit: handleSubmit2, dirty }) => {
      const budgetExceedsUsage = dirty.budget && values.radio === "2" && quotaUsageUsd > values.budget;
      return /* @__PURE__ */ React.createElement(Form, null, /* @__PURE__ */ React.createElement(
        Modal,
        {
          isOpen,
          onClose: close,
          size: "xl",
          isCentered: true
        },
        /* @__PURE__ */ React.createElement(ModalOverlay, null),
        /* @__PURE__ */ React.createElement(ModalContent, { p: "24px 32px 32px" }, /* @__PURE__ */ React.createElement(ModalHeader, { p: 0 }, /* @__PURE__ */ React.createElement(Text, { textStyle: "heading-1" }, "Change project budget")), /* @__PURE__ */ React.createElement(ModalBody, { p: "24px 0" }, submitError && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
          Grid,
          {
            templateColumns: "1fr 20fr",
            bg: "#F9786E3E1",
            p: 4
          },
          /* @__PURE__ */ React.createElement(StatusIcon, { variant: "WARNING" }),
          submitError
        ), /* @__PURE__ */ React.createElement(Box, { pb: "5" })), /* @__PURE__ */ React.createElement(Text, { textStyle: "body-2", mb: "8px" }, "Project budget"), /* @__PURE__ */ React.createElement(VisuallyHidden, null, /* @__PURE__ */ React.createElement(
          Input,
          {
            name: "projectId",
            onChange: handleChange,
            onBlur: handleBlur
          }
        )), /* @__PURE__ */ React.createElement(
          RadioGroup,
          {
            name: "radio",
            defaultValue: quotaLimitUsd === null ? "1" : "2"
          },
          /* @__PURE__ */ React.createElement(Stack, null, /* @__PURE__ */ React.createElement(
            Radio,
            {
              size: "md",
              value: "1",
              onChange: handleChange,
              onBlur: handleBlur
            },
            /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3" }, "Unlimited")
          ), /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3" }, "Do not set a budget limit for this project. Users can submit QPU jobs as long as the organization has remaining budget."), /* @__PURE__ */ React.createElement(
            Radio,
            {
              size: "md",
              value: "2",
              onChange: handleChange,
              onBlur: handleBlur
            },
            /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3" }, "Limited")
          ), /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3", mb: "8px" }, "Users can run jobs until the limit is hit for the project.", " ", /* @__PURE__ */ React.createElement(
            Text,
            {
              fontStyle: "italic",
              as: "span"
            },
            "Note: a budget of $0.00 translates to no available budget, meaning QPU jobs cannot be run."
          )))
        ), /* @__PURE__ */ React.createElement(InputGroup, null, /* @__PURE__ */ React.createElement(
          InputLeftAddon,
          {
            bg: values.radio === "1" ? "white" : "gray.50",
            borderColor: values.radio === "1" ? "gray.100" : "gray.200",
            color: values.radio === "1" ? "gray.400" : "gray.600"
          },
          "$"
        ), /* @__PURE__ */ React.createElement(
          NumericFormat,
          {
            bg: values.radio === "1" ? "gray.50" : "white",
            customInput: Input,
            fixedDecimalScale: true,
            thousandSeparator: true,
            allowLeadingZeros: true,
            decimalScale: 2,
            textStyle: "body-3",
            isInvalid: budgetExceedsUsage,
            isDisabled: values.radio === "1",
            name: "budget",
            onChange: handleChange,
            onBlur: handleBlur,
            value: values.budget,
            errorBorderColor: "uiRed",
            min: 0,
            color: values.radio === "1" ? "gray.500" : "gray.600",
            maxWidth: "356px",
            type: "string",
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderColor: "gray.200"
          }
        )), budgetExceedsUsage && /* @__PURE__ */ React.createElement(
          Text,
          {
            color: "uiRed",
            textStyle: "body-6",
            mt: "4px"
          },
          /* @__PURE__ */ React.createElement(
            WarningIcon,
            {
              boxSize: "16px",
              color: "uiRed",
              mr: "0.5em"
            }
          ),
          "Project budget can\u2019t be lower than the current project usage"
        )), /* @__PURE__ */ React.createElement(ModalFooter, { p: 0 }, /* @__PURE__ */ React.createElement(
          Button,
          {
            variant: "grey",
            size: "lg",
            onClick: close
          },
          "Cancel"
        ), /* @__PURE__ */ React.createElement(
          Button,
          {
            isDisabled: budgetExceedsUsage,
            size: "lg",
            variant: "blue",
            ml: "12px",
            type: "submit",
            onClick: handleSubmit2,
            isLoading: isSubmitting
          },
          "Set project budget"
        )))
      ));
    }
  );
};
