import React from "react";
export const IonVisualizerWires = ({
  qubitCount,
  isHorizontal,
  wireWidth,
  svgHeight
}) => {
  const measurementIconSize = 16;
  const textFontSize = 14;
  const initializationLabelFontSize = 11;
  let wires = [];
  for (let i = 0; i < qubitCount; i++) {
    wires.push(
      /* @__PURE__ */ React.createElement(
        "g",
        {
          className: "svg-wire-group",
          key: `wire-${i}`,
          transform: `translate(${(qubitCount - i - 1) * wireWidth}, 30)`
        },
        /* @__PURE__ */ React.createElement(
          "line",
          {
            className: "svg-wire",
            x1: wireWidth / 2,
            x2: wireWidth / 2,
            y1: "22",
            y2: svgHeight - 54
          }
        ),
        /* @__PURE__ */ React.createElement(
          "text",
          {
            className: isHorizontal ? "svg-wire-label -horizontal" : "svg-wire-label",
            x: wireWidth / 2,
            y: "0",
            transform: isHorizontal ? `rotate(90, ${wireWidth / 2 - textFontSize / 4}, 0) translate(${-textFontSize / 2}, 1)` : ""
          },
          i
        ),
        /* @__PURE__ */ React.createElement(
          "g",
          {
            className: "initialization",
            transform: isHorizontal ? `rotate(90, ${wireWidth / 2 - initializationLabelFontSize / 4}, 0) translate(11, 1)` : `translate(0, 18)`
          },
          /* @__PURE__ */ React.createElement(
            "text",
            {
              className: "svg-wire-initialization-label",
              x: wireWidth / 2,
              y: "0"
            },
            "0"
          ),
          /* @__PURE__ */ React.createElement(
            "polyline",
            {
              className: "initialization-line",
              points: `${wireWidth / 2 + 3.5}, 1    ${wireWidth / 2 + 6}, -3.5    ${wireWidth / 2 + 3.5}, -8`
            }
          ),
          /* @__PURE__ */ React.createElement(
            "line",
            {
              className: "initialization-line",
              x1: wireWidth / 2 - 4.75,
              y1: "1",
              x2: wireWidth / 2 - 4.75,
              y2: "-8"
            }
          )
        ),
        /* @__PURE__ */ React.createElement("g", { className: "double-wire" }, /* @__PURE__ */ React.createElement(
          "line",
          {
            className: "svg-wire",
            x1: wireWidth / 2 + 2,
            x2: wireWidth / 2 + 2,
            y1: svgHeight - 33,
            y2: svgHeight - 44
          }
        ), /* @__PURE__ */ React.createElement(
          "line",
          {
            className: "svg-wire",
            x1: wireWidth / 2 - 2,
            x2: wireWidth / 2 - 2,
            y1: svgHeight - 33,
            y2: svgHeight - 44
          }
        )),
        /* @__PURE__ */ React.createElement(
          "g",
          {
            className: "svg-wire-measurement-icon",
            transform: isHorizontal ? `rotate(90, ${wireWidth / 2 - measurementIconSize / 2}, 0) translate(${svgHeight - 44}, ${-wireWidth / 2 + measurementIconSize / 2 - 3})` : `translate(${wireWidth / 2 - measurementIconSize / 2}, ${svgHeight - 56})`
          },
          /* @__PURE__ */ React.createElement("rect", { width: "16", height: "16", fill: "#fff" }),
          /* @__PURE__ */ React.createElement("path", { d: "M3.80632,10.875a4.08333,4.08333,0,0,1,8.16666,0" }),
          /* @__PURE__ */ React.createElement("line", { x1: "7.30632", y1: "10.875", x2: "11.38965", y2: "4.625" })
        )
      )
    );
  }
  return wires;
};
