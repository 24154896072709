import React, { useEffect, useState } from "react";
import { MyContext } from "./context";
import api from "../../../api";
const getUser = async ({ onSuccess, onError }) => {
  const res = await api.getUser();
  if (res.error) {
    onError(res.error);
    return;
  }
  onSuccess(res);
};
export const MyContextProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [startRefetch, setStartRefetch] = useState(true);
  useEffect(() => {
    if (!startRefetch) return;
    setIsLoading(true);
    getUser({
      onSuccess: (res) => {
        setData(res);
        setIsLoading(false);
        setError(null);
        setStartRefetch(false);
      },
      onError: () => {
        setError("error retrieving user");
        setIsLoading(false);
        setStartRefetch(false);
      }
    });
  }, [startRefetch, setStartRefetch]);
  const refetch = () => {
    setStartRefetch(true);
  };
  return /* @__PURE__ */ React.createElement(MyContext.Provider, { value: { data, isLoading, error, refetch } }, children);
};
