import React from "react";
import "./SettingsButton.css";
const iconPaths = {
  zoom: "M11,14m2-7M7.27,10v3.93H11.2m-3.93,0,3.4-3.4m6.06,1.7V8.26H12.8m3.93,0-3.4,3.4",
  download: "M12,6.87V12m4.93,2.09H7.12m2.7-4.24L12,12l2.14-2.13",
  view: "M17.28,11A6.35,6.35,0,0,1,6.72,11a6.34,6.34,0,0,1,10.56,0ZM12,9.58A1.46,1.46,0,1,0,13.48,11,1.46,1.46,0,0,0,12,9.58Z",
  fontSize: "M11.58,15.12,14.4,7.88l2.83,7.24m-4.55-2.76h3.43m-5.79,2.69L8.55,10.51,6.77,15.05m.56-1.39H9.74",
  code: "M9,14.59,6.75,11.5,9,8.41M15,14.59l2.21-3.09L15,8.41M13,8l-2,7"
};
const SettingsButton = ({
  name,
  icon,
  onClick,
  onMouseLeave,
  open,
  children,
  disabled
}) => {
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      onClick: disabled ? () => {
      } : onClick,
      onMouseLeave,
      name,
      className: `SettingsButton ${open ? "-active" : ""} ${disabled ? "-disabled" : ""}`
    },
    /* @__PURE__ */ React.createElement("svg", { className: "settings-button-icon", viewBox: "0 0 24 21" }, /* @__PURE__ */ React.createElement("path", { strokeWidth: "1.5", d: iconPaths[icon] })),
    children
  );
};
export default SettingsButton;
