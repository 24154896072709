import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
const findTabIndex = ({ tabName, tabs }) => {
  const ret = tabs.map((t) => t.name.toLowerCase()).findIndex((tab) => tab === tabName);
  return ret === -1 ? 0 : ret;
};
export const IonTabs = ({ baseUrl, tabs, props }) => {
  const { pathname } = useLocation();
  const tabName = pathname.split("/").filter(Boolean).pop();
  return /* @__PURE__ */ React.createElement(
    Tabs,
    {
      isLazy: true,
      variant: "unstyled",
      position: "relative",
      index: findTabIndex({ tabName, tabs })
    },
    /* @__PURE__ */ React.createElement(TabList, null, tabs.map(({ name }) => /* @__PURE__ */ React.createElement(NavLink, { key: name, to: `${baseUrl}/${name.toLowerCase()}` }, /* @__PURE__ */ React.createElement(Tab, null, name)))),
    /* @__PURE__ */ React.createElement(TabPanels, null, tabs.map(({ name, Component }) => /* @__PURE__ */ React.createElement(TabPanel, { pl: 0, pr: 0, key: name }, /* @__PURE__ */ React.createElement(Component, { ...props }))))
  );
};
