import React, { Fragment } from "react";
import { Button, Input, Text } from "@chakra-ui/react";
import { Icon } from "@chakra-ui/icons";
import { MdOutlineFileUpload } from "react-icons/md";
export const IonFileUploader = (props) => {
  const hiddenFileInput = React.useRef(null);
  const handleClick = () => hiddenFileInput.current.click();
  const handleChange = (event) => {
    const files = event.target.files;
    if (!files.length) return;
    const reader = new FileReader();
    reader.onloadend = () => props.onFileUploaded(reader.result);
    reader.onerror = console.error;
    reader.readAsText(files[0], "UTF-8");
  };
  return /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement(
    Button,
    {
      onClick: handleClick,
      mb: "24px",
      height: "32px",
      p: "0 12px 0 12px!important",
      variant: "white",
      size: "lg",
      leftIcon: /* @__PURE__ */ React.createElement(
        Icon,
        {
          as: MdOutlineFileUpload,
          boxSize: "14px",
          color: "gray.600",
          marginInlineEnd: "0"
        }
      )
    },
    /* @__PURE__ */ React.createElement(
      Text,
      {
        textStyle: "body-4",
        lineHeight: "32px",
        borderRadius: "5px",
        maxHeight: "32px",
        color: "ionDark"
      },
      "Upload CSV"
    )
  ), /* @__PURE__ */ React.createElement(
    Input,
    {
      type: "file",
      accept: ".csv",
      ref: hiddenFileInput,
      onChange: handleChange,
      display: "none"
    }
  ));
};
