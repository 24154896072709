import React, { useState } from "react";
import {
  Alert,
  AlertIcon,
  Box,
  HStack,
  Text,
  CloseButton,
  Spacer
} from "@chakra-ui/react";
import { useOrganization } from "../shared/Organization/useOrganization.js";
const HIDE_BANNER = "__hide_harmony_retirement_banner__";
const HIDE_BANNER_YES_VALUE = "yes";
export const HarmonyRetirementBanner = () => {
  const [bannerHidden, setBannerHidden] = useState(
    localStorage.getItem(HIDE_BANNER) === HIDE_BANNER_YES_VALUE
  );
  const { organization, isLoading: isOrganizationLoading } = useOrganization();
  if (isOrganizationLoading) return null;
  const hasAccessToPaidBackend = organization?.hasAccessToPaidBackend;
  const shouldShowBanner = hasAccessToPaidBackend && !bannerHidden;
  if (!shouldShowBanner) return null;
  return /* @__PURE__ */ React.createElement(
    Alert,
    {
      status: "warning",
      mb: "24px",
      borderRadius: "5px",
      bg: "uiLightRed",
      p: "12px 16px"
    },
    /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(HStack, null, /* @__PURE__ */ React.createElement(AlertIcon, { color: "uiRed", boxSize: 3.5, mr: 0 }), /* @__PURE__ */ React.createElement(Text, { textStyle: "body-2", mt: "2px" }, "Harmony is retiring"), /* @__PURE__ */ React.createElement(Spacer, null), /* @__PURE__ */ React.createElement(
      CloseButton,
      {
        size: "sm",
        onClick: () => {
          localStorage.setItem(
            HIDE_BANNER,
            HIDE_BANNER_YES_VALUE
          );
          setBannerHidden(true);
        }
      }
    ))), /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3", ml: "22px" }, "Harmony is being retired on September 1st, 2024, and will no longer accept jobs after August 15th to ensure that any remaining jobs in the queue have a chance to run before retirement."))
  );
};
