import { Heading } from "@chakra-ui/react";
import React from "react";
export const IonPanelHeading = ({ children }) => {
  return /* @__PURE__ */ React.createElement(
    Heading,
    {
      fontFamily: "Colfaxx",
      fontWeight: 500,
      fontSize: "1.0625rem",
      color: "#333"
    },
    children
  );
};
