import React, { useEffect, useState } from "react";
import { StatusContext } from "./context";
import { useApi } from "../../../Api/ApiProvider";
export const StatusContextProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [startRefetch, setStartRefetch] = useState(true);
  const { Api, isLoading: isApiLoading } = useApi();
  useEffect(() => {
    if (!startRefetch || isApiLoading) return;
    setLoading(true);
    Api.getStatus({
      onSuccess: (res) => {
        setData(res);
        setLoading(false);
        setError(null);
        setStartRefetch(false);
      },
      onError: () => {
        setError("error retrieving status");
        setLoading(false);
        setStartRefetch(false);
      }
    });
  }, [startRefetch, setStartRefetch, Api, isApiLoading]);
  const refetch = () => {
    setStartRefetch(true);
  };
  return /* @__PURE__ */ React.createElement(StatusContext.Provider, { value: { data, loading, error, refetch } }, children);
};
