import React from "react";
const HistogramBaseline = ({ qubitCount, measurements }) => {
  const states = Math.pow(2, qubitCount);
  const dotCount = states > 256 ? 256 : states;
  const dotSize = dotCount >= 256 ? 2 : 3;
  const baselineWidth = measurements.graphWidth;
  const dotPeriod = (baselineWidth - 1) / (dotCount - 1);
  return /* @__PURE__ */ React.createElement(
    "line",
    {
      className: "baseline",
      x1: measurements.horizontalPadding,
      x2: measurements.svgWidth - measurements.horizontalPadding,
      y1: measurements.svgHeight - (measurements.verticalPadding + measurements.labelTextHeight),
      y2: measurements.svgHeight - (measurements.verticalPadding + measurements.labelTextHeight),
      strokeLinecap: "round",
      strokeWidth: dotSize,
      strokeDasharray: `0,${dotPeriod}`
    }
  );
};
export default HistogramBaseline;
