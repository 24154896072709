import React from "react";
import { Link } from "react-router-dom";
import { HStack, Text } from "@chakra-ui/react";
export const ProjectsHeading = ({ projectName }) => {
  return /* @__PURE__ */ React.createElement(HStack, null, /* @__PURE__ */ React.createElement(Link, { textDecoration: "none", to: "/projects" }, /* @__PURE__ */ React.createElement(
    Text,
    {
      textStyle: "heading-3",
      color: "uiBlue",
      _hover: {
        color: "uiDarkBlue"
      }
    },
    "Projects"
  )), /* @__PURE__ */ React.createElement(Text, null, "/"), /* @__PURE__ */ React.createElement(
    Text,
    {
      textStyle: "heading-3",
      color: "ionDark",
      isTruncated: true,
      maxW: "1000px"
    },
    projectName
  ));
};
