import React from "react";
const HistogramLine = ({
  histogramKey,
  lineXPosition,
  measurements,
  lineYPosition,
  barWidth
}) => {
  return /* @__PURE__ */ React.createElement("g", { key: histogramKey }, /* @__PURE__ */ React.createElement(
    "line",
    {
      className: "count-line",
      x1: lineXPosition,
      x2: lineXPosition,
      y1: measurements.baselineYValue,
      y2: lineYPosition,
      strokeWidth: barWidth,
      strokeLinecap: "butt",
      strokeDasharray: `${measurements.graphHeight / 50 - 0.6},.6`
    }
  ));
};
export default HistogramLine;
