import { useState, useEffect } from "react";
import { useApi } from "../../../Api/ApiProvider";
export const useJobResults = ({ jobId, shouldFetchResults }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState(null);
  const [error, setError] = useState(null);
  const { Api, isLoading: isApiLoading } = useApi();
  useEffect(() => {
    if (!jobId || !shouldFetchResults || isApiLoading) return;
    setIsLoading(true);
    Api.getJobResults({
      jobId,
      onSuccess: (res) => {
        setResults(res);
        setIsLoading(false);
      },
      onError: (e) => {
        setError(e);
        setIsLoading(false);
      }
    });
  }, [jobId, shouldFetchResults, Api, isApiLoading]);
  return {
    results,
    error,
    isLoading
  };
};
