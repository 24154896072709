import api from "../../../api";
import { getStandardError } from "../../shared/utils/utils";
export const fetchProjects = async ({ onSuccess, onError }) => {
  try {
    const resp = await api.listProjects();
    if (!resp?.projects) {
      onError();
      return;
    }
    return onSuccess(resp.projects);
  } catch {
    onError();
  }
};
export const fetchProject = async ({ id, onSuccess, onError }) => {
  try {
    const resp = await api.getProject(id);
    if (!resp?.id || !resp) {
      onError();
      return;
    }
    return onSuccess(resp);
  } catch {
    onError();
  }
};
export const postCreateProject = async ({ content, onSuccess, onError }) => {
  try {
    const resp = await api.createProject(content);
    if (!resp?.id) {
      onError();
      return;
    }
    onSuccess(resp);
  } catch (e) {
    onError(e);
  }
};
export const postUpdateProject = async ({
  id,
  updatedFields,
  onSuccess,
  onError
}) => {
  try {
    const resp = await api.updateProject(id, updatedFields);
    if (!resp?.id) {
      onError();
      return;
    }
    return onSuccess();
  } catch (e) {
    onError();
  }
};
export const fetchProjectMembers = async ({ projectId, onSuccess, onError }) => {
  const resp = await api.listProjectMembers(projectId);
  if (!resp?.projectMembers) {
    onError();
    return;
  }
  return onSuccess(resp.projectMembers);
};
export const postAddProjectMembers = async ({
  projectId,
  userIds,
  onSuccess,
  onError
}) => {
  try {
    const resp = await api.addProjectMembers(projectId, { userIds });
    if (!resp?.projectMembers) {
      onError();
      return;
    }
    return onSuccess(resp.projectMembers);
  } catch (e) {
    onError(e);
  }
};
export const fetchOrgInvites = async ({ onSuccess, onError }) => {
  const resp = await api.getInvites();
  if (!resp) {
    onError();
    return;
  }
  return onSuccess(resp);
};
export const fetchOrgQuota = async ({ onSuccess, onError }) => {
  const resp = await api.getOrganizationQuota();
  if (!resp) {
    onError();
    return;
  }
  return onSuccess(resp);
};
export const fetchOrg = async ({ onSuccess, onError }) => {
  const user = await api.getUser();
  if (user.error) {
    onError(user.error);
    return;
  }
  const resp = await api.getOrganization(user.organization);
  if (!resp?.id || !resp) {
    onError();
    return;
  }
  return onSuccess(resp);
};
export const fetchOrgMembers = async ({ onSuccess, onError }) => {
  const resp = await api.getOrganizationUsers();
  if (!resp) {
    onError("Invalid response from server");
    return;
  }
  return onSuccess(resp);
};
export const postSendInvites = async ({ userIds, onSuccess, onError }) => {
  try {
    const resp = await api.sendInvites(userIds);
    if (!resp || !resp.length) {
      onError("Invalid response from server");
      return;
    }
    return onSuccess(resp);
  } catch (e) {
    onError();
  }
};
export const deleteOrgMember = async ({ userId, onSuccess, onError }) => {
  try {
    await api.deleteUserById(userId);
    onSuccess();
  } catch (e) {
    onError(e);
  }
};
export const revokeInvite = async ({ userId, onSuccess, onError }) => {
  try {
    await api.revokeInvite(userId);
    onSuccess();
  } catch (e) {
    onError(e);
  }
};
export const resendOrgInvite = async ({ userId, onSuccess, onError }) => {
  try {
    await api.resendInvite(userId);
    onSuccess();
  } catch (e) {
    onError(e);
  }
};
export const deleteProjectMember = async ({
  projectId,
  userId,
  onSuccess,
  onError
}) => {
  try {
    await api.deleteProjectMember({ projectId, userId });
    onSuccess();
  } catch (e) {
    onError();
  }
};
export const fetchApiKeys = async ({ onSuccess, onError }) => {
  const resp = await api.getKeys();
  if (!resp.keys) {
    onError();
    return;
  }
  return onSuccess(resp.keys);
};
export const updateUserById = async ({ id, role, onSuccess, onError }) => {
  try {
    await api.updateUserById(id, { role });
    onSuccess();
  } catch (e) {
    onError(e);
  }
};
export const deleteApiKey = async ({ apiKey, onSuccess, onError }) => {
  try {
    await api.revokeKey(apiKey);
    onSuccess();
  } catch (e) {
    onError(e);
  }
};
export const postAddApiKey = async ({
  apiKeyName,
  apiProjectId,
  onSuccess,
  onError
}) => {
  try {
    const resp = await api.makeKey(apiKeyName, apiProjectId);
    if (!resp) {
      onError();
      return;
    }
    return onSuccess(resp);
  } catch (e) {
    onError(getStandardError(e));
  }
};
