import React, { useRef } from "react";
import {
  chakra,
  Table,
  TableContainer,
  Tbody,
  Thead,
  Tr,
  Th,
  Td,
  Text,
  useToast,
  Box,
  Button,
  Flex
} from "@chakra-ui/react";
import copy from "copy-to-clipboard";
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  createColumnHelper,
  noop
} from "@tanstack/react-table";
import { Icon } from "@chakra-ui/icons";
import { IonTooltip } from "../IonTooltip";
import { MdArrowDownward, MdArrowUpward, MdContentCopy } from "react-icons/md";
import { IonToast } from "../IonToast";
import { IonTableCheckbox } from "../IonTableCheckbox";
import { LoadingView } from "./LoadingView.js";
import { DefaultEmptyView } from "./DefaultEmptyView.js";
const columnHelper = createColumnHelper();
const returnSelf = (d) => d;
const getColumns = (keyLabels) => keyLabels.map(
  ({ key, label, isCopyable, isSortable, info, getDisplay }) => columnHelper.accessor(key, {
    cell: (d) => d.getValue(),
    header: label,
    meta: {
      getDisplay: getDisplay ?? returnSelf,
      isCopyable: isCopyable ?? false,
      isSortable: isSortable ?? false,
      info,
      shouldRightAlign: label === ""
    }
  })
);
const SortIcon = ({ isSorted, onClick }) => {
  if (!isSorted)
    return /* @__PURE__ */ React.createElement("button", { onClick }, /* @__PURE__ */ React.createElement(Icon, { as: MdArrowDownward, "aria-label": "sorted descending" }));
  return /* @__PURE__ */ React.createElement("button", { onClick }, isSorted === "desc" ? /* @__PURE__ */ React.createElement(Icon, { as: MdArrowDownward, "aria-label": "sorted descending" }) : /* @__PURE__ */ React.createElement(Icon, { as: MdArrowUpward, "aria-label": "sorted ascending" }));
};
export const IonTable = ({
  rows,
  keyLabels,
  onRowClick,
  loadMore,
  hasMoreItems,
  emptyView,
  isRowSelectEnabled,
  rowSelection,
  setRowSelection,
  isLoadingItems
}) => {
  const [sorting, setSorting] = React.useState([]);
  const toast = useToast();
  const toastIdRef = useRef();
  const columns = getColumns(keyLabels);
  const table = useReactTable({
    columns,
    data: rows,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: setRowSelection,
    enableRowSelection: isRowSelectEnabled,
    getRowId: (row) => row.id,
    state: {
      rowSelection,
      sorting
    }
  });
  const numberOfSelectedRows = rowSelection && Object.keys(rowSelection).length || 0;
  return /* @__PURE__ */ React.createElement(Box, null, rowSelection && /* @__PURE__ */ React.createElement(Flex, { mt: "12px", mb: "4px" }, /* @__PURE__ */ React.createElement(Text, { textStyle: "body-5", color: "gray.600" }, numberOfSelectedRows > 0 ? `${numberOfSelectedRows} of ${rows.length} ` : `${rows.length} `, rows.length === 1 ? "job " : "jobs ", numberOfSelectedRows > 0 ? "selected" : "")), /* @__PURE__ */ React.createElement(
    TableContainer,
    {
      minW: "min-content",
      display: "block",
      overflowX: "hidden",
      borderRadius: "5px",
      bg: "white",
      boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.05)",
      border: "1px solid #e8e8e8",
      borderBottom: "2px solid #e8e8e8",
      borderColor: "gray.200",
      mb: "64px"
    },
    /* @__PURE__ */ React.createElement(Table, { variant: "ionq" }, /* @__PURE__ */ React.createElement(Thead, null, table.getHeaderGroups().map((headerGroup) => /* @__PURE__ */ React.createElement(Tr, { key: headerGroup.id }, isRowSelectEnabled && /* @__PURE__ */ React.createElement(Th, { padding: "0 12px" }), headerGroup.headers.map((header) => {
      const meta = header.column.columnDef.meta;
      const { info, isSortable } = meta || {};
      return /* @__PURE__ */ React.createElement(Th, { key: header.id, padding: "0 12px" }, flexRender(
        header.column.columnDef.header,
        header.getContext()
      ), info && /* @__PURE__ */ React.createElement(IonTooltip, { label: info }), isSortable && /* @__PURE__ */ React.createElement(chakra.span, { pl: "1px" }, /* @__PURE__ */ React.createElement(
        SortIcon,
        {
          isSorted: header.column.getIsSorted(),
          onClick: header.column.getToggleSortingHandler()
        }
      )));
    })))), /* @__PURE__ */ React.createElement(Tbody, null, table.getRowModel().rows.map((row) => {
      return /* @__PURE__ */ React.createElement(
        Tr,
        {
          key: row.id,
          onClick: onRowClick ? () => onRowClick(row.original) : noop
        },
        isRowSelectEnabled && /* @__PURE__ */ React.createElement(
          IonTableCheckbox,
          {
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            onChange: row.getToggleSelectedHandler()
          }
        ),
        row.getVisibleCells().map((cell) => {
          const meta = cell.column.columnDef.meta;
          const {
            getDisplay,
            isCopyable,
            shouldRightAlign
          } = meta || {};
          return /* @__PURE__ */ React.createElement(
            Td,
            {
              whiteSpace: "break-spaces",
              key: cell.id,
              padding: "0 12px",
              isNumeric: shouldRightAlign
            },
            flexRender(
              getDisplay(
                cell.column.columnDef.cell(
                  cell.getContext()
                )
              ),
              cell.getContext()
            ),
            isCopyable && /* @__PURE__ */ React.createElement(
              Icon,
              {
                as: MdContentCopy,
                ml: 1,
                color: "gray.600",
                onClick: (e) => {
                  e.stopPropagation();
                  copy(
                    cell.column.columnDef.cell(
                      cell.getContext()
                    )
                  );
                  toastIdRef.current = toast({
                    duration: 5e3,
                    position: "bottom-left",
                    render: () => /* @__PURE__ */ React.createElement(
                      IonToast,
                      {
                        message: "Copied to clipboard",
                        status: "success",
                        toast,
                        toastIdRef
                      }
                    )
                  });
                }
              }
            )
          );
        })
      );
    }))),
    isLoadingItems && /* @__PURE__ */ React.createElement(LoadingView, null),
    !isLoadingItems && rows.length === 0 && (emptyView || /* @__PURE__ */ React.createElement(DefaultEmptyView, null)),
    !isLoadingItems && rows.length > 0 && hasMoreItems && /* @__PURE__ */ React.createElement(
      Button,
      {
        variant: "white",
        border: "none",
        borderRadius: 0,
        minHeight: "38px",
        w: "100%",
        onClick: loadMore
      },
      "Load More"
    )
  ));
};
