import {
  Box,
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  useDisclosure,
  useOutsideClick
} from "@chakra-ui/react";
import { MdKeyboardArrowDown, MdOutlineFileDownload } from "react-icons/md";
import React from "react";
export const JobDetailResultsPopover = ({ menuItems }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const ref = React.useRef();
  useOutsideClick({
    ref,
    handler: onClose
  });
  return /* @__PURE__ */ React.createElement(Popover, { placement: "bottom-end", isOpen }, /* @__PURE__ */ React.createElement(PopoverTrigger, null, /* @__PURE__ */ React.createElement(
    Button,
    {
      size: "lg",
      variant: "blue",
      leftIcon: /* @__PURE__ */ React.createElement(MdOutlineFileDownload, { fontSize: "16px" }),
      rightIcon: /* @__PURE__ */ React.createElement(MdKeyboardArrowDown, { fontSize: "16px" }),
      onClick: onOpen
    },
    "Download results"
  )), /* @__PURE__ */ React.createElement(Portal, null, /* @__PURE__ */ React.createElement(PopoverContent, { maxWidth: "200px" }, /* @__PURE__ */ React.createElement(PopoverBody, { padding: 0 }, menuItems.map((item, i) => {
    const { key, node, onClick, variant } = item;
    const isFirst = i === 0;
    const isLast = i === menuItems.length - 1;
    const isDanger = variant === "DANGER";
    return /* @__PURE__ */ React.createElement(
      Button,
      {
        key,
        variant: "popover",
        size: "lg",
        color: isDanger ? "uiRed" : "uiBlue",
        borderTopRadius: isFirst ? 5 : void 0,
        borderBottomRadius: isLast ? 5 : void 0,
        onClick: (e) => {
          onClick(e);
          onClose();
        }
      },
      /* @__PURE__ */ React.createElement(
        Box,
        {
          width: "100%",
          textAlign: "left",
          p: "8px 0",
          overflow: "hidden",
          isTruncated: false
        },
        /* @__PURE__ */ React.createElement(Text, { textStyle: "body-2" }, node)
      )
    );
  })))));
};
