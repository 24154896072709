import React, { useEffect } from "react";
import { createContext, useState } from "react";
import { useProjectJobs } from "../components/Projects/hooks/useProjectJobs.js";
import { useLocation } from "react-router-dom";
export const ProjectJobsContext = createContext({
  jobs: [],
  setJobs: () => {
  },
  filters: [],
  setFilters: () => {
  },
  hasMore: false,
  next: null,
  isJobsLoading: false,
  jobsError: null,
  loadMore: () => {
  },
  setIsLoading: () => {
  }
});
export const ProjectJobsProvider = ({ children }) => {
  const [filters, setFilters] = useState([]);
  const location = useLocation();
  const {
    hasMore,
    next,
    jobs,
    isLoading: isJobsLoading,
    loadMore,
    error: jobsError,
    setIsLoading,
    setProjectId,
    projectId
  } = useProjectJobs({
    filters,
    id: null
  });
  useEffect(() => {
    const splitURL = location.pathname.split("/").filter(Boolean);
    const [, id] = splitURL;
    if (splitURL.length !== 2 || id === projectId) return;
    setIsLoading(true);
    setProjectId(id);
  }, [location.pathname, projectId, setIsLoading, setProjectId]);
  return /* @__PURE__ */ React.createElement(
    ProjectJobsContext.Provider,
    {
      value: {
        jobs,
        filters,
        setFilters,
        hasMore,
        next,
        isJobsLoading,
        jobsError,
        loadMore,
        setIsLoading
      }
    },
    children
  );
};
