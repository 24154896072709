import React, { useCallback, useEffect, useState } from "react";
import "./TimeoutModal.scss";
import { DEFAULT_MINUTES_UNTIL_SESSION_TIMEOUT } from "../../api";
import { useMySettings } from "../shared/Me/useMe";
import { useApi } from "../../Api/ApiProvider.js";
const thirtyMinutesInSeconds = 6e4 * DEFAULT_MINUTES_UNTIL_SESSION_TIMEOUT;
const twentyFiveMinutesInSeconds = 6e4 * (DEFAULT_MINUTES_UNTIL_SESSION_TIMEOUT - 5);
export const TimeoutModal = ({ location }) => {
  const [isVisible, setIsVisible] = useState(null);
  const [showModalTimeoutID, setShowModalTimeoutID] = useState(null);
  const [logoutTimeoutID, setLogoutTimeoutID] = useState(null);
  const { onSessionExpire } = useApi();
  const minutesUntilSessionTimeout = useMySettings()?.minutesUntilSessionTimeout;
  const resetTimeout = useCallback(() => {
    if (logoutTimeoutID) {
      setIsVisible(false);
      clearTimeout(logoutTimeoutID);
      clearTimeout(showModalTimeoutID);
    }
    if (minutesUntilSessionTimeout) {
      setLogoutTimeoutID(
        setTimeout(() => {
          if (minutesUntilSessionTimeout) {
            onSessionExpire();
          }
          resetTimeout();
        }, thirtyMinutesInSeconds)
      );
      setShowModalTimeoutID(
        setTimeout(() => {
          setIsVisible(true);
        }, twentyFiveMinutesInSeconds)
      );
    }
  }, [
    onSessionExpire,
    logoutTimeoutID,
    minutesUntilSessionTimeout,
    showModalTimeoutID
  ]);
  useEffect(resetTimeout, [location]);
  return isVisible && /* @__PURE__ */ React.createElement("div", { className: "TimeoutModal", onClick: null }, /* @__PURE__ */ React.createElement("div", { className: "modal", onClick: (e) => e?.stopPropagation() }, /* @__PURE__ */ React.createElement("h3", { className: "timeout-headline" }, "Session timeout"), /* @__PURE__ */ React.createElement("p", null, "You\u2019ve been inactive for a while. You\u2019ll be signed out automatically if you don\u2019t dismiss this message in the next five minutes."), /* @__PURE__ */ React.createElement("p", null, "You can also disable this option under your account settings."), /* @__PURE__ */ React.createElement("button", { onClick: resetTimeout }, "Stay signed in"), /* @__PURE__ */ React.createElement(
    "button",
    {
      onClick: () => {
        onSessionExpire();
      },
      className: "timeout-modal-sign-out-button"
    },
    "Sign out"
  )));
};
