import React from "react";
import { Box, Button, Input, Text, useToast } from "@chakra-ui/react";
import { IonPanelHeading } from "../shared/IonPanelHeading";
import { IonDivider } from "../shared/IonDivider";
import { IonPanel } from "../shared/IonPanel";
import { useOrganization } from "../shared/Organization/useOrganization";
import * as Yup from "yup";
import { Form, Formik } from "../FormikWrapper";
import { IonToast } from "../shared/IonToast";
import api from "../../api.js";
export const OrganizationSettings = () => {
  const toast = useToast();
  const toastIdRef = React.useRef();
  const { organization, refetch: refetchOrg } = useOrganization();
  return /* @__PURE__ */ React.createElement(Box, { mb: 10 }, /* @__PURE__ */ React.createElement(Text, { lineHeight: "38px", textStyle: "heading-3", mb: "12px" }, "Organization Settings"), /* @__PURE__ */ React.createElement(IonPanel, null, /* @__PURE__ */ React.createElement(IonPanelHeading, null, "Basic information"), /* @__PURE__ */ React.createElement(IonDivider, null), /* @__PURE__ */ React.createElement(
    Formik,
    {
      onSubmit: async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        if (organization?.id && values.name) {
          await api.updateOrganization(organization?.id, {
            name: values.name
          });
          toastIdRef.current = toast({
            duration: 5e3,
            position: "bottom-left",
            render: () => /* @__PURE__ */ React.createElement(
              IonToast,
              {
                message: "Your organization information has been successfully updated.",
                status: "success",
                toast,
                toastIdRef
              }
            )
          });
        }
        await refetchOrg();
        setSubmitting(false);
        resetForm({ values });
      },
      initialValues: {
        orgId: organization?.id,
        name: organization?.name
      },
      validationSchema: Yup.object().shape({
        orgId: Yup.string().required("orgId is required"),
        name: Yup.string().required("name is required")
      })
    },
    ({
      touched,
      dirty,
      setErrors,
      errors,
      values,
      isSubmitting,
      handleBlur,
      handleChange
    }) => /* @__PURE__ */ React.createElement(Form, null, /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(Text, { textStyle: "body-2", mb: "4px" }, "Organization name"), /* @__PURE__ */ React.createElement(
      Input,
      {
        id: "name",
        onFocus: () => {
          setErrors({ ...errors, name: "" });
        },
        type: "text",
        name: "name",
        value: values.name,
        onBlur: handleBlur,
        onChange: handleChange
      }
    ), touched.name && errors.name && /* @__PURE__ */ React.createElement(Text, { color: "uiRed" }, errors.name)), /* @__PURE__ */ React.createElement(Box, { mt: "8px" }, /* @__PURE__ */ React.createElement(Text, { textStyle: "body-2", mb: "4px" }, "Organization ID"), /* @__PURE__ */ React.createElement(
      Input,
      {
        readOnly: true,
        isDisabled: true,
        id: "orgId",
        type: "text",
        name: "orgId",
        value: values.orgId
      }
    ), touched.orgId && errors.orgId && /* @__PURE__ */ React.createElement(Text, { color: "uiRed" }, errors.orgId)), /* @__PURE__ */ React.createElement(
      Button,
      {
        variant: "blue",
        maxW: "320px",
        size: "lg",
        mt: "24px",
        type: "submit",
        isDisabled: !dirty || isSubmitting
      },
      `${isSubmitting ? "Updating" : "Update"} organization information`
    ))
  )));
};
