import React from "react";
export const IonVisualizerLineRow = ({
  height,
  verticalOffset,
  measurements,
  ops,
  svgWidth,
  color,
  parentQubitsUsed
}) => {
  const max = Math.max(...parentQubitsUsed);
  const min = Math.min(...parentQubitsUsed);
  const lineWidth = measurements.wireWidth * (max - min) + measurements.wireWidth;
  const lineXOffset = svgWidth - measurements.wireWidth * max - measurements.wireWidth;
  const nextLineDistance = 5;
  const lineThickness = 3;
  const firstLineLocation = verticalOffset + height / 2 - (ops.length - 1) * (nextLineDistance + lineThickness) / 2;
  return /* @__PURE__ */ React.createElement("g", { transform: `translate(0,${firstLineLocation})` }, ops.map((op, i) => {
    return /* @__PURE__ */ React.createElement(
      "line",
      {
        key: i,
        className: "rule-line",
        style: { stroke: op.line.color ? op.line.color : color },
        x1: lineXOffset,
        x2: lineXOffset + lineWidth,
        y1: (nextLineDistance + lineThickness) * i,
        y2: (nextLineDistance + lineThickness) * i
      }
    );
  }));
};
