import {
  Box,
  Button,
  Center,
  Grid,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Text,
  useToast
} from "@chakra-ui/react";
import React, { useRef, useState, useEffect } from "react";
import { useBaseModal } from "../../shared/baseModals/useBaseModal";
import { MODALS } from "../../shared/baseModals/constants";
import { Form, Formik } from "formik";
import { useMe, useIsOwner } from "../../shared/Me/useMe";
import { useProjects } from "../hooks/useProjects";
import { useOrgMembers } from "../hooks/useOrgMembers";
import { StatusIcon } from "../../shared/StatusIcon";
import { IonToast } from "../../shared/IonToast";
import { isNameInvalid } from "../../../util/project";
export const GenerateAPIKeyModal = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [activeProjects, setActiveProjects] = useState([]);
  const toast = useToast();
  const toastIdRef = useRef();
  const {
    close: closeGenerateApiKeyModal,
    isOpen,
    props = {}
  } = useBaseModal(MODALS.generateApiKey);
  const { open: openApiKeyCreatedModal } = useBaseModal(MODALS.apiKeyCreated);
  const {
    projects,
    isLoading: isProjectsLoading,
    error: projectError
  } = useProjects();
  const isOwner = useIsOwner();
  const { data: userData, isLoading: isUserLoading } = useMe();
  const { orgMembers, isLoading: isOrgMembersLoading } = useOrgMembers();
  const isLoading = isProjectsLoading || isOrgMembersLoading || isUserLoading;
  useEffect(() => {
    if (isLoading) return;
    let activeProjects2 = (projects || []).filter((project) => !project.isArchived).sort((a, b) => a.name.localeCompare(b.name));
    if (isOwner) {
      const member = orgMembers.filter((m) => m.id === userData.id).pop();
      activeProjects2 = activeProjects2.filter(
        // make best effort to filter out projects the user doesn't
        // have access to, it's still restricted on the backend.
        (project) => member?.projectIds?.includes(project.id) ?? true
      );
    }
    setActiveProjects(activeProjects2);
  }, [isLoading, isOwner, userData, orgMembers, projects]);
  return /* @__PURE__ */ React.createElement(
    Formik,
    {
      onSubmit: (values, { resetForm }) => {
        setIsSubmitting(true);
        props.addApiKey({
          apiKeyName: values.keyName,
          apiProjectId: values.projectId,
          onAddSuccess: (apiKey) => {
            setSubmitError(null);
            setIsSubmitting(false);
            toastIdRef.current = toast({
              duration: 5e3,
              position: "bottom-left",
              render: () => /* @__PURE__ */ React.createElement(
                IonToast,
                {
                  message: `New API key successfully generated`,
                  status: "success",
                  toast,
                  toastIdRef
                }
              )
            });
            closeGenerateApiKeyModal();
            resetForm();
            openApiKeyCreatedModal({
              apiKey,
              projectName: (activeProjects?.find(
                (x) => x.id === values.projectId
              ) || {}).name ?? ""
            });
          },
          onAddError: (e) => {
            setIsSubmitting(false);
            setSubmitError(e);
          }
        });
      },
      initialValues: {
        keyName: "",
        projectId: ""
      }
    },
    ({
      touched,
      setErrors,
      errors,
      handleBlur,
      handleChange,
      handleSubmit,
      values
    }) => /* @__PURE__ */ React.createElement(Form, null, /* @__PURE__ */ React.createElement(
      Modal,
      {
        isOpen,
        onClose: () => {
          setSubmitError(null);
          closeGenerateApiKeyModal();
        },
        size: "xl",
        isCentered: true
      },
      /* @__PURE__ */ React.createElement(ModalOverlay, null),
      /* @__PURE__ */ React.createElement(ModalContent, { p: "24px 32px 32px" }, /* @__PURE__ */ React.createElement(ModalHeader, { p: 0 }, /* @__PURE__ */ React.createElement(Text, { textStyle: "heading-1" }, "Generate API key")), isLoading ? /* @__PURE__ */ React.createElement(ModalBody, { p: "24px 0" }, /* @__PURE__ */ React.createElement(Center, null, /* @__PURE__ */ React.createElement(Spinner, null))) : projectError ? /* @__PURE__ */ React.createElement(ModalBody, { p: "24px 0" }, /* @__PURE__ */ React.createElement(Center, null, /* @__PURE__ */ React.createElement(Text, { color: "uiRed", textStyle: "body-6" }, projectError))) : /* @__PURE__ */ React.createElement(ModalBody, { p: "24px 0" }, submitError && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
        Grid,
        {
          templateColumns: "1fr 20fr",
          bg: "#F9E3E1",
          p: 4
        },
        /* @__PURE__ */ React.createElement(StatusIcon, { variant: "WARNING" }),
        submitError
      ), /* @__PURE__ */ React.createElement(Box, { pb: "5" })), /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(
        Text,
        {
          textStyle: "body-2",
          mb: "4px",
          color: "ionDark"
        },
        "Give your key a descriptive name"
      ), /* @__PURE__ */ React.createElement(
        Input,
        {
          size: "md",
          maxW: 400,
          mb: "4px",
          textStyle: "body-3",
          onFocus: () => {
            setErrors({
              ...errors,
              keyName: ""
            });
          },
          spellCheck: false,
          onBlur: handleBlur,
          onChange: handleChange,
          isInvalid: touched.keyName && errors.keyName,
          type: "text",
          name: "keyName"
        }
      ), touched.keyName && errors.keyName && /* @__PURE__ */ React.createElement(
        Text,
        {
          textStyle: "body-6",
          color: "uiRed"
        },
        errors.keyName
      ), /* @__PURE__ */ React.createElement(
        Text,
        {
          textStyle: "body-6",
          mb: "16px",
          color: "gray.600"
        },
        "What is this key for?"
      ), /* @__PURE__ */ React.createElement(Box, { mb: "16px" }, /* @__PURE__ */ React.createElement(
        Text,
        {
          textStyle: "body-2",
          mb: "4px",
          color: "ionDark"
        },
        "Choose a project this key will connect to"
      ), /* @__PURE__ */ React.createElement(
        Text,
        {
          textStyle: "body-3",
          mb: "4px",
          color: "ionDark"
        },
        "You need to be added to the project to generate an API key for it."
      ), /* @__PURE__ */ React.createElement(
        Select,
        {
          maxW: 400,
          size: "md",
          id: "projectId",
          name: "projectId",
          onChange: handleChange,
          onBlur: handleBlur
        },
        [""].concat(activeProjects).map((project) => {
          return /* @__PURE__ */ React.createElement(
            "option",
            {
              id: "projectId",
              key: project.id || "",
              value: project.id ?? ""
            },
            project?.name ?? "Choose project"
          );
        })
      )))), /* @__PURE__ */ React.createElement(ModalFooter, { p: 0 }, /* @__PURE__ */ React.createElement(
        Button,
        {
          size: "lg",
          variant: "grey",
          onClick: () => {
            setSubmitError(null);
            closeGenerateApiKeyModal();
          },
          mr: "8px"
        },
        "Cancel"
      ), /* @__PURE__ */ React.createElement(
        Button,
        {
          variant: "blue",
          type: "submit",
          size: "lg",
          isDisabled: isNameInvalid(values.keyName) || !values.projectId,
          isLoading: isSubmitting,
          onClick: handleSubmit
        },
        isSubmitting ? "Generating key" : "Generate key"
      )))
    ))
  );
};
