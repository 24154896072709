export const CLOSE_MODAL = "modals.close";
export const OPEN_MODAL = "modals.open";
export const MODAL_STATES = {
  open: "open",
  closed: "closed"
};
export const MODALS = {
  cancelJobConfirmation: "cancelJobConfirmation",
  cancelMultipleJobsConfirmation: "cancelMultipleJobsConfirmation",
  archiveProjectConfirmation: "archiveProjectConfirmation",
  unarchiveProjectConfirmation: "unarchiveProjectConfirmation",
  createProject: "createProject",
  removeMemberFromProject: "removeMemberFromProject",
  inviteNewMembers: "inviteNewMembers",
  changeProjectBudget: "changeProjectBudget",
  generateApiKey: "generateApiKey",
  confirmApiKeyDeletion: "confirmApiKeyDeletion",
  apiKeyCreated: "apiKeyCreated",
  changeUserRole: "changeUserRole",
  addNewOrganizationMember: "addNewOrganizationMember",
  memberProjects: "memberProjects",
  removeUserFromOrganization: "removeUserFromOrganization"
};
