import React from "react";
import PropTypes from "prop-types";
import stylingClassFromState from "./utils/stylingClassFromState";
import NodeIcon from "./NodeIcon";
const JSONValueNode = ({
  nodeType,
  labelRenderer,
  keyPath,
  valueRenderer,
  value,
  valueGetter
}) => /* @__PURE__ */ React.createElement(
  "li",
  {
    className: `value ${stylingClassFromState(
      nodeType
    )} ${stylingClassFromState(keyPath)}`
  },
  /* @__PURE__ */ React.createElement("span", { className: "label-group" }, /* @__PURE__ */ React.createElement(NodeIcon, { nodeType }), /* @__PURE__ */ React.createElement(
    "label",
    {
      className: `label valueLabel ${stylingClassFromState(
        nodeType
      )} ${stylingClassFromState(keyPath)}`
    },
    labelRenderer(keyPath, nodeType, false, false)
  )),
  /* @__PURE__ */ React.createElement(
    "span",
    {
      className: `valueText ${stylingClassFromState(
        nodeType
      )} ${stylingClassFromState(keyPath)}`
    },
    valueRenderer(valueGetter(value), value, ...keyPath)
  )
);
JSONValueNode.propTypes = {
  nodeType: PropTypes.string.isRequired,
  labelRenderer: PropTypes.func.isRequired,
  keyPath: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  valueRenderer: PropTypes.func.isRequired,
  value: PropTypes.any,
  valueGetter: PropTypes.func
};
JSONValueNode.defaultProps = {
  valueGetter: (value) => value
};
export default JSONValueNode;
