export const isBudgetInvalid = (budget) => {
  return isBudgetValid(budget) === false;
};
export const isBudgetValid = (budget) => {
  if (budget === "") {
    return true;
  }
  if (typeof budget === "string" && !isNaN(getUnformattedNumber(budget))) {
    return getUnformattedNumber(budget) >= 0;
  }
  if (typeof budget !== "number" || isNaN(budget)) {
    return false;
  }
  return budget >= 0;
};
export const isNameInvalid = (name) => {
  return isNameValid(name) === false;
};
export const isNameValid = (name) => {
  const hasRestrictedCharactersOnly = /^[()@&+:._-`a-zA-Z0-9- ]*$/.test(name);
  return typeof name === "string" && name.trim().length > 0 && hasRestrictedCharactersOnly;
};
export const getUnformattedNumber = (formattedNumber) => {
  if (typeof formattedNumber === "number") {
    return formattedNumber;
  }
  return Number(formattedNumber.replace(/,/g, ""));
};
