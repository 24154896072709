const AQ_COUNT = `Application oriented number of useful qubits`;
const SUPPORTS_ERROR_MITIGATION = "Optional, post processing techniques to improve algorithm results";
const SUPPORTED_NATIVE_GATES = "Hardware-native basis gate set bypassing IonQ compilers";
const SUPPORTED_GATES = "Supported QIS gate set";
const SUPPORTS_RESERVATIONS = "Reservations are a scheduled period of exclusive access to a QPU";
const QUBITS = "Number of physical qubits used to perform quantum operations";
const QUBITS_SIMULATOR = "Number of simulated qubits used to perform simulated quantum operations";
const ALL_TO_ALL = "all to all";
const ONE_Q_GATE_ERROR = "Average 1Q randomized benchmarking error rates obtained via Direct Randomized Benchmarking";
const TWO_Q_GATE_ERROR = "Average 2Q randomized benchmarking error rates obtained via Direct Randomized Benchmarking";
const TOPOLOGY = "Topology articulates the ability of a system to perform any arbitrary entangling gate without the need for swap gates.";
const SIMULATED_HARDWARE_NOISE_MODEL = "Type of noise model";
const T1 = "T1 & T2 are two factors of the amount of time a qubit \u201Cstays a qubit,\u201D T1 measures how long you can tell what\u2019s a one vs a zero.";
const T2 = "T1 & T2 are two factors of the amount of time a qubit \u201Cstays a qubit,\u201D T2 measures phase coherence.";
const ONE_Q = "The average duration of the laser pulse required to accomplish a native single-qubit gate.";
const TWO_Q = "The average duration of the laser pulse required to accomplish one native two-qubit entangling gate.";
const READOUT = "The average duration of the readout operation on the system. Note that readout only occurs at the end of a circuit, and is performed on all qubits.";
const RESET = "The average duration of the reset operation on the system. Note that reset only occurs at the beginning of a circuit, and is performed on all qubits.";
const SPAM_ERROR = "Average error introduced during state preparation and measurement";
export const SYSTEM_TOOLTIP_LABELS = {
  ALL_TO_ALL,
  AQ_COUNT,
  QUBITS,
  QUBITS_SIMULATOR,
  SIMULATED_HARDWARE_NOISE_MODEL,
  SUPPORTED_GATES,
  SUPPORTED_NATIVE_GATES,
  SUPPORTS_ERROR_MITIGATION,
  SUPPORTS_RESERVATIONS,
  TOPOLOGY,
  ONE_Q_GATE_ERROR,
  TWO_Q_GATE_ERROR,
  T1,
  T2,
  ONE_Q,
  TWO_Q,
  READOUT,
  RESET,
  SPAM_ERROR
};
