import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { useBaseModal } from "../../shared/baseModals/useBaseModal";
import { MODALS } from "../../shared/baseModals/constants";
import { Link } from "react-router-dom";
import { IonToast } from "../../shared/IonToast";
import { useOrgMembers } from "../hooks/useOrgMembers";
import { StatusIcon } from "../../shared/StatusIcon";
export const getDisplayUserName = (user) => {
  const { firstName, lastName, email } = user;
  if (firstName && lastName) {
    return `${firstName} ${lastName} (${email})`;
  }
  const name = firstName || lastName;
  if (name) {
    return `${name} (${email})`;
  }
  return email;
};
export const getInviteOptions = ({ orgMembers, projectMembers }) => {
  return orgMembers.filter(
    (orgMember) => !projectMembers.some(
      (projectMember) => projectMember.email === orgMember.email
    )
  ).map((user) => ({
    value: user.id,
    label: getDisplayUserName(user)
  }));
};
export const AddNewProjectMemberModal = ({
  addProjectMembers,
  projectMembers,
  projectName
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const toast = useToast();
  const toastIdRef = useRef();
  const [options, setOptions] = useState([]);
  const { isOpen, close } = useBaseModal(MODALS.inviteNewMembers);
  const { orgMembers } = useOrgMembers();
  useEffect(() => {
    if (!orgMembers || !projectMembers) return;
    setOptions(getInviteOptions({ orgMembers, projectMembers }));
  }, [orgMembers, projectMembers]);
  const handleCloseModal = () => {
    setSubmitError(null);
    setIsSubmitting(false);
    close();
  };
  const handleSubmit = () => {
    setIsSubmitting(true);
    addProjectMembers({
      userIds: selectedUsers.map(({ value }) => value),
      onAddSuccess: () => {
        setIsSubmitting(false);
        handleCloseModal();
        setSelectedUsers([]);
        toastIdRef.current = toast({
          duration: 5e3,
          position: "bottom-left",
          render: () => /* @__PURE__ */ React.createElement(
            IonToast,
            {
              message: `${selectedUsers.length} new project ${selectedUsers.length === 1 ? "member" : "members"} successfully invited to project ${projectName}.`,
              status: "success",
              toast,
              toastIdRef
            }
          )
        });
      },
      onAddError: (error) => {
        setIsSubmitting(false);
        setSubmitError(error);
      }
    });
  };
  return /* @__PURE__ */ React.createElement(Modal, { isOpen, onClose: handleCloseModal, size: "xl", isCentered: true }, /* @__PURE__ */ React.createElement(ModalOverlay, null), /* @__PURE__ */ React.createElement(ModalContent, { p: "24px 32px 32px" }, /* @__PURE__ */ React.createElement(ModalHeader, { p: 0 }, /* @__PURE__ */ React.createElement(Text, { textStyle: "heading-1" }, "Add members to project")), /* @__PURE__ */ React.createElement(ModalBody, { p: "24px 0" }, submitError && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Grid, { templateColumns: "1fr 20fr", bg: "#F9E3E1", p: 4 }, /* @__PURE__ */ React.createElement(StatusIcon, { variant: "WARNING" }), submitError), /* @__PURE__ */ React.createElement(Box, { pb: "5" })), /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3", mb: "16px" }, "Add existing organization members and/or owners to the project ", projectName, " so they can run jobs against this project. If you wish to add members who are not part of the organization yet, head to the", /* @__PURE__ */ React.createElement(
    Link,
    {
      to: "/settings/organization/members",
      target: "_blank",
      rel: "noopener noreferrer"
    },
    /* @__PURE__ */ React.createElement(Text, { ml: "0.3em", as: "span", color: "uiBlue" }, "organization members page.")
  ), /* @__PURE__ */ React.createElement(Box, { mt: "16px" }, /* @__PURE__ */ React.createElement(Text, { textStyle: "body-2", as: "span" }, "Note:"), /* @__PURE__ */ React.createElement(Text, { as: "span", textStyle: "body-3", ml: "0.5em" }, "Organization owners must add themselves to a project to submit jobs to it."))), /* @__PURE__ */ React.createElement(FormControl, null, /* @__PURE__ */ React.createElement(FormLabel, null, "Members"), /* @__PURE__ */ React.createElement(
    Select,
    {
      isMulti: true,
      placeholder: "Search or select by name or email",
      noOptionsMessage: () => options.length && options.length === selectedUsers.length ? "No other members to invite" : "No results found",
      closeMenuOnSelect: false,
      options,
      value: selectedUsers,
      onChange: setSelectedUsers,
      isDisabled: isSubmitting
    }
  )))), /* @__PURE__ */ React.createElement(ModalFooter, { p: 0 }, /* @__PURE__ */ React.createElement(Button, { variant: "grey", size: "lg", onClick: handleCloseModal }, "Cancel"), /* @__PURE__ */ React.createElement(
    Button,
    {
      size: "lg",
      variant: "blue",
      ml: "12px",
      onClick: handleSubmit,
      isLoading: isSubmitting,
      isDisabled: selectedUsers.length === 0
    },
    "Add members"
  ))));
};
