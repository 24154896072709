import React from "react";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { createRoot } from "react-dom/client";
import * as serviceWorker from "./registerServiceWorker";
import { BrowserRouter } from "react-router-dom";
import "what-input";
import App from "./components/App/App";
import { loadAnalytics } from "./loadAnalytics";
import { ChakraProvider } from "@chakra-ui/react";
import chakraTheme from "./lib/chakra-theme";
import { Fonts } from "./fonts";
import { MyContextProvider } from "./components/shared/Me/provider";
const root = createRoot(document.getElementById("root"));
const { LD_CLIENT_SIDE_ID } = window._dynamic_env_;
(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: LD_CLIENT_SIDE_ID,
    reactOptions: {
      useCamelCaseFlagKeys: false
    }
  });
  root.render(
    /* @__PURE__ */ React.createElement(LDProvider, null, /* @__PURE__ */ React.createElement(MyContextProvider, null, /* @__PURE__ */ React.createElement(ChakraProvider, { theme: chakraTheme }, /* @__PURE__ */ React.createElement(Fonts, null), /* @__PURE__ */ React.createElement(BrowserRouter, null, /* @__PURE__ */ React.createElement(App, null)))))
  );
})();
serviceWorker.unregister();
loadAnalytics();
