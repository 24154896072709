import React, { Fragment, useRef, useState } from "react";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useToast
} from "@chakra-ui/react";
import { useBaseModal } from "../../shared/baseModals/useBaseModal";
import { MODALS } from "../../shared/baseModals/constants";
import { MdOutlineDelete } from "react-icons/md";
import { Field, FieldArray, Form, Formik } from "formik";
import csvGuide from "../../../static/csv-guide.pdf";
import { IonToast } from "../../shared/IonToast";
import { IonFileUploader } from "../../shared/IonFileUploader";
import { parseCsv, validateEmail } from "../../shared/utils/utils";
import { downloadFile } from "../../../lib/downloadCode";
const CSV_UPLOAD_MAX_RECORDS = 1e3;
const CSV_HEADERS = ["email", "role"];
const InvitesFieldArray = (props) => {
  const {
    onRemoveClick,
    form: {
      getFieldMeta,
      values: { invites }
    }
  } = props;
  const hasEmailErrors = (index, name) => {
    const { touched, value } = getFieldMeta(`invites.${index}.${name}`);
    return touched && !validateEmail(value);
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, invites.map((invite, index) => {
    const hasErr = hasEmailErrors(index, "email");
    return /* @__PURE__ */ React.createElement(
      Box,
      {
        key: `invites.${index}.email`,
        display: "flex",
        mb: "12px"
      },
      /* @__PURE__ */ React.createElement(Box, { flexGrow: 1, minW: "255px", mr: "12px" }, /* @__PURE__ */ React.createElement(
        Field,
        {
          as: Input,
          name: `invites.${index}.email`,
          type: "email",
          isInvalid: hasErr,
          placeholder: "user@ionq.com"
        }
      ), hasErr && /* @__PURE__ */ React.createElement(Text, { textStyle: "body-6", color: "uiRed" }, "Email address ", invites[index].email, " is not valid")),
      /* @__PURE__ */ React.createElement(
        Field,
        {
          name: `invites.${index}.role`,
          as: Select,
          mr: "12px"
        },
        /* @__PURE__ */ React.createElement("option", { value: "user" }, "User"),
        /* @__PURE__ */ React.createElement("option", { value: "owner" }, "Owner")
      ),
      invites.length > 1 && /* @__PURE__ */ React.createElement(
        IconButton,
        {
          bg: "white",
          _hover: {
            bg: "gray.100"
          },
          color: "gray.500",
          _active: {
            bg: "gray.200"
          },
          size: "lg",
          fontSize: "20px",
          alignSelf: "flex-start",
          "aria-label": "Delete row",
          onClick: () => onRemoveClick(index),
          icon: /* @__PURE__ */ React.createElement(MdOutlineDelete, null)
        }
      )
    );
  }));
};
export const AddNewOrganizationMemberModal = ({ inviteOrgMembers }) => {
  const { isOpen, close } = useBaseModal(MODALS.addNewOrganizationMember);
  const [submitError, setSubmitError] = useState();
  const [isShowingDeleteConfirm, setIsShowingDeleteConfirm] = useState(false);
  const toast = useToast();
  const toastIdRef = useRef();
  const validEmails = (x) => validateEmail(x.email);
  const handleCloseModal = () => {
    setSubmitError(null);
    setIsShowingDeleteConfirm(false);
    setUploadStatus(null);
    setInvites([createInvite()]);
    close();
  };
  const createInvite = () => ({ email: "", role: "user" });
  const [invites, setInvites] = useState([createInvite()]);
  const [uploadStatus, setUploadStatus] = useState(null);
  const arrayHelpersRef = useRef(null);
  const formRef = useRef(null);
  const handleSubmit = (values) => {
    const totalInvites = values.invites.filter(validEmails);
    inviteOrgMembers({
      userIds: totalInvites,
      onAddSuccess: () => {
        handleCloseModal();
        toastIdRef.current = toast({
          duration: 5e3,
          position: "bottom-left",
          render: () => /* @__PURE__ */ React.createElement(
            IonToast,
            {
              message: `${totalInvites.length} new organization ${totalInvites.length === 1 ? "member" : "members"} successfully invited.`,
              status: "success",
              toast,
              toastIdRef
            }
          )
        });
      },
      onAddError: (error) => {
        setSubmitError(error);
      }
    });
  };
  if (invites.length === 0) {
    setInvites([createInvite()]);
    setSubmitError(null);
  }
  const onRemoveClick = (index) => {
    if (invites.length > 1)
      setInvites(invites.filter((_, i) => i !== index));
  };
  const onAddClick = () => {
    const { invites: current } = arrayHelpersRef.current.form.values;
    let invites2 = [...current, createInvite()];
    if (invites2.length > CSV_UPLOAD_MAX_RECORDS) {
      setUploadStatus({
        message: `You reached the limit of ${CSV_UPLOAD_MAX_RECORDS} members in one CSV file. Create a new invite to add more.`,
        error: true
      });
      invites2 = current;
    }
    setInvites(invites2);
  };
  const onCsvFileUploaded = async (csvString) => {
    const {
      values: { invites: current }
    } = arrayHelpersRef.current.form;
    const uploaded = await parseCsv(csvString, CSV_HEADERS);
    let invites2 = [...current, ...uploaded].filter(validEmails);
    if (invites2.length > CSV_UPLOAD_MAX_RECORDS) {
      const count = invites2.length - CSV_UPLOAD_MAX_RECORDS;
      setUploadStatus({
        message: `You reached the limit of ${CSV_UPLOAD_MAX_RECORDS} members in one CSV file. ${count} contacts were not uploaded. Create a new invite to add more.`,
        error: true
      });
      invites2 = invites2.splice(0, CSV_UPLOAD_MAX_RECORDS);
    } else {
      setUploadStatus({
        message: `${uploaded.length} members successfully uploaded from CSV file`,
        error: false
      });
    }
    setInvites(invites2);
  };
  return /* @__PURE__ */ React.createElement(Modal, { isOpen, onClose: handleCloseModal, size: "xl", isCentered: true }, /* @__PURE__ */ React.createElement(ModalOverlay, null), /* @__PURE__ */ React.createElement(ModalContent, { p: "24px 32px 32px" }, /* @__PURE__ */ React.createElement(
    Formik,
    {
      enableReinitialize: true,
      initialValues: { invites },
      validateOnChange: true,
      onSubmit: handleSubmit
    },
    ({ isSubmitting, values }) => /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(ModalHeader, { p: 0 }, /* @__PURE__ */ React.createElement(Text, { textStyle: "heading-1" }, isShowingDeleteConfirm ? "Cancel invite?" : "Invite members to organization")), /* @__PURE__ */ React.createElement(ModalBody, { p: "24px 0" }, isShowingDeleteConfirm ? /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3" }, "Are you sure you want to cancel your invite? All the changes you made won\u2019t be saved.") : /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Box, null, submitError && /* @__PURE__ */ React.createElement(
      Alert,
      {
        status: "warning",
        mb: "8px",
        borderRadius: "5px",
        bg: "uiLightRed"
      },
      /* @__PURE__ */ React.createElement(
        AlertIcon,
        {
          color: "uiRed",
          boxSize: 4
        }
      ),
      /* @__PURE__ */ React.createElement(
        Text,
        {
          display: "flex",
          fontSize: "14px",
          fontWeight: 400,
          mr: 1
        },
        submitError
      )
    ), /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3", mb: "16px" }, "Invite new members to your organization by filling in the details below. (limit: 1000 members) Big team to invite? Upload CSV file (", /* @__PURE__ */ React.createElement(
      "a",
      {
        href: csvGuide,
        download: "csv-guide.pdf",
        target: "_blank",
        rel: "noreferrer"
      },
      /* @__PURE__ */ React.createElement(
        Text,
        {
          ml: "0.3em",
          as: "span",
          color: "uiBlue"
        },
        "learn more about CSV"
      )
    ), "). Optionally, you can", /* @__PURE__ */ React.createElement(
      Text,
      {
        ml: "0.3em",
        color: "uiBlue",
        as: "span",
        cursor: "pointer",
        onClick: () => {
          const headers = [
            "email,role"
          ];
          let fakeData = [
            "user@ionq.com,user"
          ];
          downloadFile({
            data: [
              ...headers,
              ...fakeData
            ].join("\n"),
            fileName: "template.csv",
            fileType: "text/csv"
          });
        }
      },
      "download our template."
    )), /* @__PURE__ */ React.createElement(
      Flex,
      {
        borderRadius: "5px",
        borderColor: "gray.500",
        m: 0
      },
      /* @__PURE__ */ React.createElement(
        IonFileUploader,
        {
          onFileUploaded: onCsvFileUploaded
        }
      ),
      /* @__PURE__ */ React.createElement(
        Text,
        {
          p: "8px 16px",
          textStyle: "body-5",
          color: uploadStatus?.error ? "uiRed" : "ionDark"
        },
        uploadStatus?.message
      )
    ), /* @__PURE__ */ React.createElement(Flex, null, /* @__PURE__ */ React.createElement(
      Text,
      {
        textStyle: "body-2",
        minW: "255px",
        mr: "12px",
        display: "inline-block"
      },
      "Email addresses"
    ), /* @__PURE__ */ React.createElement(
      Text,
      {
        textStyle: "body-2",
        display: "inline-block"
      },
      "Role"
    ))), /* @__PURE__ */ React.createElement(
      Form,
      {
        ref: formRef,
        className: "form content"
      },
      /* @__PURE__ */ React.createElement(
        FieldArray,
        {
          name: "invites",
          render: (props) => {
            arrayHelpersRef.current = props;
            return /* @__PURE__ */ React.createElement(
              InvitesFieldArray,
              {
                ...props,
                onRemoveClick
              }
            );
          }
        }
      )
    ), /* @__PURE__ */ React.createElement(
      Text,
      {
        textStyle: "body-3",
        onClick: onAddClick,
        color: "uiBlue",
        tabIndex: "0",
        cursor: "pointer"
      },
      "+ Add another member"
    ))), /* @__PURE__ */ React.createElement(ModalFooter, { p: 0 }, isShowingDeleteConfirm ? /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
      Button,
      {
        variant: "grey",
        size: "lg",
        onClick: () => setIsShowingDeleteConfirm(false)
      },
      "Return to invites"
    ), /* @__PURE__ */ React.createElement(
      Button,
      {
        size: "lg",
        variant: "blue",
        ml: "12px",
        onClick: handleCloseModal
      },
      "Cancel invite"
    )) : /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
      Button,
      {
        variant: "grey",
        size: "lg",
        onClick: (e) => {
          if (values.invites.some(
            (invite) => invite.email
          )) {
            setIsShowingDeleteConfirm(
              true
            );
          } else {
            handleCloseModal(e);
          }
        }
      },
      "Cancel"
    ), /* @__PURE__ */ React.createElement(
      Button,
      {
        size: "lg",
        variant: "blue",
        ml: "12px",
        type: "submit",
        onClick: () => {
          handleSubmit(values);
        },
        isLoading: isSubmitting,
        isDisabled: isSubmitting
      },
      values.invites.filter(validEmails).length > 1 ? `Invite ${values.invites.filter(
        validEmails
      ).length} members` : "Invite member"
    ))))
  )));
};
