import React from "react";
import { Button, ModalFooter } from "@chakra-ui/react";
export const BaseModalFooter = ({
  isDanger,
  onClose,
  ctaLabel,
  onCtaClick,
  isCtaDisabled,
  isLoading
}) => {
  return /* @__PURE__ */ React.createElement(ModalFooter, { pr: "0" }, /* @__PURE__ */ React.createElement(Button, { size: "lg", variant: "grey", onClick: onClose }, "Close"), onCtaClick && /* @__PURE__ */ React.createElement(
    Button,
    {
      size: "lg",
      isLoading,
      colorScheme: isDanger ? "red" : "blue",
      ml: 2,
      onClick: onCtaClick,
      isDisabled: isCtaDisabled
    },
    ctaLabel
  ));
};
