import React from "react";
import Gravatar from "react-gravatar";
const ProfileImage = ({ email }) => /* @__PURE__ */ React.createElement("div", { className: "settings-panel" }, /* @__PURE__ */ React.createElement("h5", { className: "settings-panel-internal-headline" }, "Profile image"), /* @__PURE__ */ React.createElement(
  "a",
  {
    href: "https://gravatar.com/",
    target: "_blank",
    rel: "noopener noreferrer"
  },
  /* @__PURE__ */ React.createElement(
    Gravatar,
    {
      email,
      size: 50,
      rating: "pg",
      default: "retro",
      className: "gravatar"
    }
  )
), /* @__PURE__ */ React.createElement("p", { className: "settings-panel-helper-text -gravatar" }, "We use Gravatar for profile images. To create a Gravatar, update your Gravatar, or learn more,", " ", /* @__PURE__ */ React.createElement(
  "a",
  {
    href: "https://gravatar.com/",
    target: "_blank",
    rel: "noopener noreferrer"
  },
  "please visit the Gravatar website"
), "."));
export default ProfileImage;
