import { useCallback, useContext, useMemo } from "react";
import { ModalContext } from "./ModalProvider";
import { MODALS, OPEN_MODAL, CLOSE_MODAL, MODAL_STATES } from "./constants";
export const useBaseModal = (modalName) => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error(
      `useModal used outside of ModalProvider by "${modalName}".`
    );
  }
  const { state, dispatch } = context;
  if (!state[modalName]) {
    throw new Error(
      `Invalid modal name: ${modalName}. Name must be one of ${Object.keys(
        MODALS
      ).join(", ")}`
    );
  }
  const open = useCallback(
    (props2) => {
      dispatch({
        type: OPEN_MODAL,
        payload: { modalName, props: props2 }
      });
    },
    [dispatch, modalName]
  );
  const close = useCallback(() => {
    dispatch({
      type: CLOSE_MODAL,
      payload: { modalName }
    });
  }, [dispatch, modalName]);
  const isOpen = state[modalName].isOpen === MODAL_STATES.open;
  const props = state[modalName].props;
  return useMemo(
    () => ({
      isOpen,
      open,
      close,
      props
    }),
    [isOpen, open, close, props]
  );
};
