import { useCallback, useState } from "react";
import api from "../../api";
const CANCEL_JOB_REQUEST_FAILED = "Could not cancel job. Please try again later.";
export const useSendCancelJob = ({ onCancelSuccess, onCancelError }) => {
  const [isCancelJobLoading, setIsCancelJobLoading] = useState(false);
  const onSuccess = useCallback(() => {
    onCancelSuccess();
  }, [onCancelSuccess]);
  const sendCancelJob = useCallback(
    async (jobId) => {
      setIsCancelJobLoading(true);
      try {
        await api.cancelJob(jobId);
        setIsCancelJobLoading(false);
        onSuccess();
      } catch (e) {
        setIsCancelJobLoading(false);
        onCancelError(e?.message || CANCEL_JOB_REQUEST_FAILED);
      }
    },
    [onSuccess, onCancelError]
  );
  return {
    isCancelJobLoading,
    sendCancelJob
  };
};
