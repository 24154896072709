import React from "react";
import "./SettingsButtonSelect.css";
const SettingsButtonSelect = ({ options, onSelect, active }) => {
  return /* @__PURE__ */ React.createElement("div", { className: "SettingsButtonSelect" }, Object.keys(options).map((option) => {
    return /* @__PURE__ */ React.createElement(
      "span",
      {
        key: option + options[option],
        className: `option ${options[option] === active ? "-active" : ""}`,
        onClick: () => {
          onSelect(options[option]);
        }
      },
      option
    );
  }));
};
export default SettingsButtonSelect;
