import React from "react";
import { Center, CloseButton, Flex, Text, useToast } from "@chakra-ui/react";
import { MdCheckCircle } from "react-icons/md";
import { Icon } from "@chakra-ui/icons";
const SuccessToast = ({ message, close }) => {
  return /* @__PURE__ */ React.createElement(
    Flex,
    {
      justifyContent: "space-between",
      padding: 4,
      backgroundColor: "#CEE7DE",
      maxHeight: "48px",
      lineHeight: "1.235rem",
      borderRadius: "5px",
      marginBottom: "24px",
      marginLeft: "24px"
    },
    /* @__PURE__ */ React.createElement(Center, null, /* @__PURE__ */ React.createElement(Icon, { as: MdCheckCircle, color: "#00794A" })),
    /* @__PURE__ */ React.createElement(
      Text,
      {
        fontSize: "14px",
        fontWeight: 400,
        color: "gray.700",
        marginLeft: "8px",
        isTruncated: true
      },
      message
    ),
    /* @__PURE__ */ React.createElement(Center, null, /* @__PURE__ */ React.createElement(
      CloseButton,
      {
        size: "sm",
        onClick: close,
        color: "gray.700",
        marginLeft: "8px"
      }
    ))
  );
};
export const useIonToast = () => {
  const toast = useToast();
  const toastIdRef = React.useRef();
  const close = () => {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current);
    }
  };
  const toastSuccess = (successMessage) => {
    toastIdRef.current = toast({
      position: "bottom-left",
      render: () => /* @__PURE__ */ React.createElement(
        SuccessToast,
        {
          message: successMessage,
          close,
          toastIdRef
        }
      )
    });
  };
  return {
    toastSuccess
  };
};
