import React from "react";
import { Box, Center, Flex, Progress, Spinner, Text } from "@chakra-ui/react";
import { IonPanel } from "../shared/IonPanel";
import { getFormattedBudget } from "../../util/format";
import { useOrganization } from "../shared/Organization/useOrganization";
import { useOrgQuotas } from "../Projects/hooks/useOrgQuotas";
export const UsageSettings = () => {
  const { organization, isLoading: orgIsLoading, error } = useOrganization();
  const { orgQuotas, isLoading: quotasIsLoading } = useOrgQuotas();
  if (orgIsLoading || quotasIsLoading) {
    return /* @__PURE__ */ React.createElement(Center, null, /* @__PURE__ */ React.createElement(Spinner, null));
  }
  const { limit, usage } = orgQuotas;
  if (error) {
    return /* @__PURE__ */ React.createElement(Text, null, error);
  }
  const { usedFormatted, remaining, totalFormatted, percentageUsed } = getFormattedBudget(limit, usage);
  const hasAccessToPaidBackend = organization?.hasAccessToPaidBackend;
  return /* @__PURE__ */ React.createElement(Box, { mb: "10px" }, /* @__PURE__ */ React.createElement(Text, { lineHeight: "38px", textStyle: "heading-3", mb: "12px" }, "Organization Spend"), /* @__PURE__ */ React.createElement(IonPanel, null, /* @__PURE__ */ React.createElement(Flex, { justifyContent: "space-between" }, /* @__PURE__ */ React.createElement(Text, { textStyle: "body-2", color: "ionDark" }, "Total organization spend")), /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(Box, { mt: "8px" }, /* @__PURE__ */ React.createElement(
    Text,
    {
      as: "span",
      textStyle: "heading-4",
      color: "ionDark",
      lineHeight: "21px"
    },
    usedFormatted || "Unlimited"
  ), hasAccessToPaidBackend && /* @__PURE__ */ React.createElement(
    Text,
    {
      as: "span",
      textStyle: "body-4",
      color: "gray.600",
      lineHeight: "21px"
    },
    "/ ",
    totalFormatted || "Unlimited"
  )), hasAccessToPaidBackend && /* @__PURE__ */ React.createElement(
    Progress,
    {
      hasStripe: !remaining,
      colorScheme: "green",
      mt: "8px",
      mb: "8px",
      value: percentageUsed
    }
  ), hasAccessToPaidBackend && remaining && /* @__PURE__ */ React.createElement(Flex, { justifyContent: "end" }, /* @__PURE__ */ React.createElement(Text, { textStyle: "body-5", color: "gray.600" }, remaining, " remaining")))), hasAccessToPaidBackend && /* @__PURE__ */ React.createElement(Text, { textStyle: "body-6", color: "gray.600", mt: "-16px" }, "To request additional quota, please contact your IonQ representative"));
};
