import React from "react";
import {
  Box,
  Button,
  Flex,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  useDisclosure,
  useOutsideClick
} from "@chakra-ui/react";
import { MdMoreHoriz } from "react-icons/md";
import { Icon } from "@chakra-ui/icons";
export const RowActionPopover = ({
  ariaLabel,
  menuItems,
  onClickEvent = () => {
  },
  icon = null,
  variant = "DEFAULT"
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const ref = React.useRef();
  const [onTopHover, setOnTopHover] = React.useState(false);
  const [onBottomHover, setOnBottomHover] = React.useState(false);
  const hasIcon = icon !== null;
  useOutsideClick({
    ref,
    handler: onClose
  });
  const isHovering = !isOpen && hasIcon && (onTopHover || onBottomHover);
  return /* @__PURE__ */ React.createElement(Popover, { placement: "bottom-end", isOpen }, /* @__PURE__ */ React.createElement(PopoverTrigger, null, /* @__PURE__ */ React.createElement(Flex, null, hasIcon && isHovering && /* @__PURE__ */ React.createElement(
    IconButton,
    {
      height: "26px",
      maxHeight: "26px",
      width: "26px",
      maxWidth: "26px",
      position: "absolute",
      size: "xs",
      fontSize: "16px",
      backgroundColor: "white",
      border: "1px solid #E0E0E3",
      ml: "-26px",
      color: variant === "CRITICAL" ? "uiRed" : "gray.500",
      borderRadius: "5px 0 0 5px",
      borderRight: "none",
      boxShadow: "none",
      "aria-label": ariaLabel,
      cursor: "pointer",
      icon: /* @__PURE__ */ React.createElement(Icon, { as: icon }),
      _hover: {
        backgroundColor: "gray.50",
        color: variant === "CRITICAL" ? "uiDarkRed" : "ionDark"
      },
      _active: {
        color: variant === "CRITICAL" ? "uiDarkRed" : "ionDark"
      },
      onClick: (e) => {
        onClickEvent(e);
        setOnBottomHover(false);
        setOnTopHover(false);
      },
      onMouseEnter: () => {
        setOnTopHover(true);
        onClose();
      },
      onMouseLeave: () => setOnTopHover(false)
    }
  ), /* @__PURE__ */ React.createElement(
    IconButton,
    {
      height: "26px",
      maxHeight: "26px",
      width: "26px",
      maxWidth: "26px",
      size: "xs",
      backgroundColor: "white",
      _hover: {
        border: "1px solid #E0E0E3",
        bg: "gray.50",
        color: "ionDark"
      },
      border: isHovering ? "1px solid #E0E0E3" : null,
      borderRadius: hasIcon && isHovering ? "0 5px 5px 0" : "5px",
      onMouseEnter: () => setOnBottomHover(true),
      onMouseLeave: () => setOnBottomHover(false),
      boxShadow: "none",
      color: "gray.500",
      _active: {
        color: "ionDark"
      },
      "aria-label": "Choose row action",
      cursor: "pointer",
      fontSize: "16px",
      icon: /* @__PURE__ */ React.createElement(MdMoreHoriz, null),
      onClick: (e) => {
        e.stopPropagation();
        setOnBottomHover(false);
        setOnTopHover(false);
        onOpen();
      }
    }
  ))), /* @__PURE__ */ React.createElement(Portal, null, /* @__PURE__ */ React.createElement(PopoverContent, { maxWidth: "250px" }, /* @__PURE__ */ React.createElement(PopoverBody, { padding: 0 }, menuItems.map((item, i) => {
    const { key, node, onClick, variant: variant2 } = item;
    const isFirst = i === 0;
    const isLast = i === menuItems.length - 1;
    const isDanger = variant2 === "DANGER";
    return /* @__PURE__ */ React.createElement(
      Button,
      {
        key,
        variant: "popover",
        size: "lg",
        color: isDanger ? "uiRed" : "uiBlue",
        borderTopRadius: isFirst ? 5 : void 0,
        borderBottomRadius: isLast ? 5 : void 0,
        onClick: (e) => {
          onClick(e);
          onClose();
        }
      },
      /* @__PURE__ */ React.createElement(
        Box,
        {
          width: "100%",
          textAlign: "left",
          p: "8px 0",
          overflow: "hidden",
          isTruncated: false
        },
        /* @__PURE__ */ React.createElement(Text, { textStyle: "body-2" }, node)
      )
    );
  })))));
};
