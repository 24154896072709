import React from "react";
const QLogo = () => {
  return /* @__PURE__ */ React.createElement(
    "svg",
    {
      version: "1.1",
      id: "ionq-logo",
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 26 30"
    },
    /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement(
      "polygon",
      {
        fill: "#D4D4D5",
        points: "13,3.6 22.8,9.3 26,7.5 13,0 0,7.5 3.2,9.3 "
      }
    ), /* @__PURE__ */ React.createElement(
      "polygon",
      {
        fill: "#EC7B37",
        points: "3.2,9.3 3.2,9.3 3.2,20.7 4.2,20.1 6.3,18.9 6.3,11.1 13,7.3 13,4.8 13,3.6 "
      }
    ), /* @__PURE__ */ React.createElement(
      "polygon",
      {
        fill: "#B7B7B8",
        points: "22.8,9.3 22.8,20.7 21.8,20.1 26,22.5 26,7.5 "
      }
    ), /* @__PURE__ */ React.createElement(
      "polygon",
      {
        fill: "#DE5126",
        points: "22.8,9.3 13,3.6 13,4.8 13,7.3 19.7,11.1 19.7,18.9 21.8,20.1 22.8,20.7 "
      }
    ), /* @__PURE__ */ React.createElement(
      "polygon",
      {
        fill: "#C2C2C3",
        points: "22.8,24.3 22.8,24.3 22.8,24.3 "
      }
    ), /* @__PURE__ */ React.createElement(
      "polygon",
      {
        fill: "#929293",
        points: "19.7,22.5 19.7,22.5 19.7,26.2 19.7,26.2 22.8,28 22.8,24.3 22.8,24.3 "
      }
    ), /* @__PURE__ */ React.createElement(
      "polygon",
      {
        fill: "#929293",
        points: "4.2,20.1 4.2,20.1 3.2,20.7 3.2,9.3 0,7.5 0,22.5 13,30 13,26.4 3.2,20.7 "
      }
    ), /* @__PURE__ */ React.createElement(
      "polygon",
      {
        fill: "#B7B7B8",
        points: "13,30 19.7,26.2 19.7,22.5 13,26.4 "
      }
    ), /* @__PURE__ */ React.createElement(
      "polygon",
      {
        fill: "#C2C2C3",
        points: "19.7,22.5 19.7,22.5 19.7,22.5 "
      }
    ), /* @__PURE__ */ React.createElement(
      "polygon",
      {
        fill: "#F89B33",
        points: "19.7,22.5 19.7,22.5 19.7,22.5 "
      }
    ), /* @__PURE__ */ React.createElement(
      "polygon",
      {
        fill: "#F89B33",
        points: "22.8,20.7 22,20.2 19.7,18.9 13,22.7 6.3,18.9 4.2,20.1 3.2,20.7 13,26.4 19.7,22.5 19.7,22.5 \n        19.7,22.5 22.8,24.3 22.8,24.3 26,22.5 "
      }
    ), /* @__PURE__ */ React.createElement(
      "polygon",
      {
        fill: "#B7B7B8",
        points: "26,22.5 22.8,24.3 22.8,28 26,26.2 "
      }
    ))
  );
};
export default QLogo;
