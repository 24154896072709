import { Center, Checkbox, Td } from "@chakra-ui/react";
import React from "react";
export const IonTableCheckbox = ({ checked, disabled, onChange }) => {
  return /* @__PURE__ */ React.createElement(
    Td,
    {
      padding: "0 12px",
      onClickCapture: (e) => {
        e.stopPropagation();
      }
    },
    /* @__PURE__ */ React.createElement(Center, null, /* @__PURE__ */ React.createElement(
      Checkbox,
      {
        isDisabled: disabled,
        size: "sm",
        isChecked: checked,
        onInput: onChange
      }
    ))
  );
};
