import React from "react";
import { Form, Field, Formik } from "../../FormikWrapper";
import * as Yup from "yup";
const Password = ({ onSubmit, isSSO }) => /* @__PURE__ */ React.createElement(
  Formik,
  {
    submitAction: onSubmit,
    initialValues: {
      currentPassword: "",
      password: "",
      confirmNewPassword: ""
    },
    validationSchema: Yup.object().shape({
      currentPassword: Yup.string().required(
        "current password is required"
      ),
      password: Yup.string().required("a new password is required"),
      confirmNewPassword: Yup.string().oneOf([Yup.ref("password"), null], "passwords must match").required("please confirm your password")
    }),
    validateOnChange: false
  },
  ({ dirty, touched, setErrors, status, errors }) => /* @__PURE__ */ React.createElement("div", null, status && status.success && /* @__PURE__ */ React.createElement("div", { className: "success-box" }, status.success), errors.global && /* @__PURE__ */ React.createElement("div", { className: "error-box" }, errors.global), /* @__PURE__ */ React.createElement(Form, { className: "settings-panel" }, /* @__PURE__ */ React.createElement("h5", { className: "settings-panel-internal-headline" }, "Password"), isSSO && /* @__PURE__ */ React.createElement("p", { className: "settings-panel-helper-text" }, "Authentication by password is disabled because your organization requires all accounts to authenticate through your Single Sign-On Provider (JumpCloud)."), !isSSO && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "settings-panel-input-group" }, /* @__PURE__ */ React.createElement("label", { className: "label" }, "Enter current password"), /* @__PURE__ */ React.createElement(
    Field,
    {
      onFocus: () => {
        setErrors({
          ...errors,
          currentPassword: ""
        });
      },
      autoComplete: "current-password",
      className: `password-input ${dirty && touched.currentPassword && errors.currentPassword ? "-errored" : ""}`,
      type: "password",
      name: "currentPassword"
    }
  ), dirty && touched.currentPassword && errors.currentPassword && /* @__PURE__ */ React.createElement("p", { className: "error-message" }, errors.currentPassword)), /* @__PURE__ */ React.createElement("div", { className: "settings-panel-fieldset" }, /* @__PURE__ */ React.createElement("div", { className: "settings-panel-input-group" }, /* @__PURE__ */ React.createElement("label", { className: "label" }, "Choose a new password"), /* @__PURE__ */ React.createElement(
    Field,
    {
      onFocus: () => {
        setErrors({
          ...errors,
          password: ""
        });
      },
      autoComplete: "new-password",
      className: `password-input ${dirty && touched.password && errors.password ? "-errored" : ""}`,
      type: "password",
      name: "password"
    }
  ), dirty && touched.password && errors.password && /* @__PURE__ */ React.createElement("p", { className: "error-message" }, errors.password)), /* @__PURE__ */ React.createElement("div", { className: "settings-panel-input-group" }, /* @__PURE__ */ React.createElement("label", { className: "label" }, "Confirm new password"), /* @__PURE__ */ React.createElement(
    Field,
    {
      onFocus: () => {
        setErrors({
          ...errors,
          confirmNewPassword: ""
        });
      },
      autoComplete: "new-password",
      className: `password-input ${dirty && touched.confirmNewPassword && errors.confirmNewPassword ? "-errored" : ""}`,
      type: "password",
      name: "confirmNewPassword"
    }
  ), dirty && touched.confirmNewPassword && errors.confirmNewPassword && /* @__PURE__ */ React.createElement("p", { className: "error-message" }, errors.confirmNewPassword))), /* @__PURE__ */ React.createElement(
    "button",
    {
      type: "submit",
      disabled: !dirty,
      className: "settings-panel-submit-button"
    },
    "Update password"
  ))))
);
export default Password;
