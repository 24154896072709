import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast
} from "@chakra-ui/react";
import { useBaseModal } from "../../shared/baseModals/useBaseModal";
import { MODALS } from "../../shared/baseModals/constants";
import { StatusIcon } from "../../shared/StatusIcon";
import { IonToast } from "../../shared/IonToast";
const ARCHIVE_PROJECTS_FAILED = "Could not archive project. Please try again later.";
export const ArchiveProjectModal = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const toast = useToast();
  const toastIdRef = useRef();
  const { close, isOpen, props } = useBaseModal(
    MODALS.archiveProjectConfirmation
  );
  if (!props?.updateProject || !props?.projectId || !props?.projectName) {
    return /* @__PURE__ */ React.createElement(Modal, { isOpen, onClose: close, size: "xl", isCentered: true }, /* @__PURE__ */ React.createElement(ModalOverlay, null), /* @__PURE__ */ React.createElement(ModalContent, { p: "24px 32px 32px" }, "Something is wrong"));
  }
  const { updateProject, projectId, projectName } = props;
  const handleModalClose = () => {
    setSubmitError(null);
    setIsSubmitting(false);
    close();
  };
  const handleSubmit = () => {
    setIsSubmitting(true);
    updateProject({
      projectId,
      updatedFields: { isArchived: true },
      onUpdateSuccess: () => {
        setIsSubmitting(false);
        handleModalClose();
        toastIdRef.current = toast({
          duration: 5e3,
          position: "bottom-left",
          render: () => /* @__PURE__ */ React.createElement(
            IonToast,
            {
              message: `Project ${projectName} successfully archived`,
              status: "success",
              toast,
              toastIdRef
            }
          )
        });
      },
      onUpdateError: () => {
        setIsSubmitting(false);
        setSubmitError(ARCHIVE_PROJECTS_FAILED);
      }
    });
  };
  return /* @__PURE__ */ React.createElement(Modal, { isOpen, onClose: handleModalClose, size: "xl", isCentered: true }, /* @__PURE__ */ React.createElement(ModalOverlay, null), /* @__PURE__ */ React.createElement(ModalContent, { p: "24px 32px 32px" }, /* @__PURE__ */ React.createElement(ModalHeader, { p: 0 }, /* @__PURE__ */ React.createElement(Text, { textStyle: "heading-1" }, "Archive project")), /* @__PURE__ */ React.createElement(ModalBody, { p: "24px 0" }, submitError && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Grid, { templateColumns: "1fr 20fr", bg: "#F9E3E1", p: 4 }, /* @__PURE__ */ React.createElement(StatusIcon, { variant: "WARNING" }), submitError), /* @__PURE__ */ React.createElement(Box, { pb: "5" })), /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3" }, "Archiving a project also cancels all the jobs from this project that are currently in queue."), /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3", mt: 4 }, "While archiving a project is a reversible action, the job cancellations are not."))), /* @__PURE__ */ React.createElement(ModalFooter, { p: 0 }, /* @__PURE__ */ React.createElement(Button, { variant: "grey", size: "lg", onClick: handleModalClose }, "Cancel"), /* @__PURE__ */ React.createElement(
    Button,
    {
      size: "lg",
      variant: "red",
      ml: "12px",
      isLoading: isSubmitting,
      onClick: handleSubmit
    },
    "I understand, archive this project"
  ))));
};
