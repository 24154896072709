import { useState, useEffect, useCallback } from "react";
import {
  deleteProjectMember,
  fetchProjectMembers,
  postAddProjectMembers
} from "./utils";
import { getStandardError } from "../../shared/utils/utils";
const FETCH_PROJECT_MEMBERS_FAILED = "Could not fetch project members. Please try again later.";
const DELETE_PROJECT_MEMBER_FAILED = "Could not remove project members. Please try again later.";
export const useProjectMembers = (projectId) => {
  const [isLoading, setIsLoading] = useState(true);
  const [projectMembers, setProjectMembers] = useState([]);
  const [error, setError] = useState(null);
  useEffect(() => {
    if (!projectId) return;
    fetchProjectMembers({
      projectId,
      onSuccess: (projectMembers2) => {
        setProjectMembers(projectMembers2);
        setIsLoading(false);
      },
      onError: () => {
        setError(FETCH_PROJECT_MEMBERS_FAILED);
        setIsLoading(false);
      }
    });
  }, [projectId]);
  const addProjectMembers = useCallback(
    ({ userIds, onAddSuccess, onAddError }) => {
      postAddProjectMembers({
        projectId,
        userIds,
        onSuccess: (newProjectMember) => {
          setProjectMembers([...projectMembers, ...newProjectMember]);
          onAddSuccess();
        },
        onError: (e) => {
          onAddError(getStandardError(e));
        }
      });
    },
    [projectId, projectMembers]
  );
  const removeProjectMember = useCallback(
    ({ userId, onRemoveSuccess, onRemoveError }) => {
      deleteProjectMember({
        projectId,
        userId,
        onSuccess: () => {
          const updatedProjectMembers = projectMembers.filter(
            (projectMember) => projectMember.id !== userId
          );
          setProjectMembers(updatedProjectMembers);
          onRemoveSuccess();
        },
        onError: () => {
          onRemoveError(DELETE_PROJECT_MEMBER_FAILED);
        }
      });
    },
    [projectId, projectMembers]
  );
  return {
    projectMembers,
    error,
    isLoading,
    addProjectMembers,
    removeProjectMember
  };
};
