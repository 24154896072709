import React, { useState } from "react";
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text
} from "@chakra-ui/react";
import { useBaseModal } from "../../shared/baseModals/useBaseModal";
import { MODALS } from "../../shared/baseModals/constants";
export const ConfirmApiKeyDeletionModal = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { close, isOpen, props } = useBaseModal(MODALS.confirmApiKeyDeletion);
  if (!props?.apiKey || !props?.revokeKey) {
    return /* @__PURE__ */ React.createElement(Modal, { isOpen, onClose: close, size: "xl", isCentered: true }, /* @__PURE__ */ React.createElement(ModalOverlay, null), /* @__PURE__ */ React.createElement(ModalContent, { p: "24px 32px 32px" }, "Something is wrong"));
  }
  const handleModalClose = () => {
    setIsSubmitting(false);
    close();
  };
  const handleSubmit = () => {
    props.revokeKey(props?.apiKey);
  };
  return /* @__PURE__ */ React.createElement(Modal, { isOpen, onClose: handleModalClose, size: "xl", isCentered: true }, /* @__PURE__ */ React.createElement(ModalOverlay, null), /* @__PURE__ */ React.createElement(ModalContent, { p: "24px 32px 32px" }, /* @__PURE__ */ React.createElement(ModalHeader, { p: 0 }, /* @__PURE__ */ React.createElement(Text, { textStyle: "heading-1" }, "Revoke this key?")), /* @__PURE__ */ React.createElement(ModalBody, { p: "24px 0" }, /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3" }, "When you revoke an API key, all integrations using this key will immediately lose access to the IonQ API. You will still be able to view and download old jobs results from our web interface."))), /* @__PURE__ */ React.createElement(ModalFooter, { p: 0 }, /* @__PURE__ */ React.createElement(Button, { variant: "grey", size: "lg", onClick: handleModalClose }, "Cancel"), /* @__PURE__ */ React.createElement(
    Button,
    {
      size: "lg",
      variant: "red",
      ml: "12px",
      isLoading: isSubmitting,
      onClick: handleSubmit
    },
    "I understand, revoke this key"
  ))));
};
