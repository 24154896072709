import React from "react";
import PropTypes from "prop-types";
import JSONArrow from "./JSONArrow";
import JSONNode from "./JSONNode";
import ItemRange from "./ItemRange";
import NodeIcon from "./NodeIcon";
import getCollectionEntries from "./utils/getCollectionEntries";
import stylingClassFromState from "./utils/stylingClassFromState";
function renderChildNodes(props, from, to) {
  const {
    nodeType,
    data,
    collectionLimit,
    circularCache,
    keyPath,
    postprocessValue,
    sortObjectKeys
  } = props;
  const childNodes = [];
  getCollectionEntries(
    nodeType,
    data,
    sortObjectKeys,
    collectionLimit,
    from,
    to
  ).forEach((entry) => {
    if (entry.to) {
      childNodes.push(
        /* @__PURE__ */ React.createElement(
          ItemRange,
          {
            ...props,
            key: `ItemRange--${entry.from}-${entry.to}`,
            from: entry.from,
            to: entry.to,
            renderChildNodes
          }
        )
      );
    } else {
      const { key, value } = entry;
      const isCircular = circularCache.indexOf(value) !== -1;
      const node = /* @__PURE__ */ React.createElement(
        JSONNode,
        {
          ...props,
          ...{ postprocessValue, collectionLimit },
          key: `Node--${key}`,
          keyPath: [key, ...keyPath],
          value: postprocessValue(value),
          circularCache: [...circularCache, value],
          isCircular,
          hideRoot: false
        }
      );
      if (node !== false) {
        childNodes.push(node);
      }
    }
  });
  return childNodes;
}
function getStateFromProps(props) {
  const expanded = props.shouldExpandNode && !props.isCircular ? props.shouldExpandNode(props.keyPath, props.data, props.level) : false;
  return {
    expanded
  };
}
export default class JSONNestedNode extends React.Component {
  static propTypes = {
    getItemString: PropTypes.func.isRequired,
    nodeTypeIndicator: PropTypes.any,
    nodeType: PropTypes.string.isRequired,
    data: PropTypes.any,
    hideRoot: PropTypes.bool.isRequired,
    createItemString: PropTypes.func.isRequired,
    collectionLimit: PropTypes.number,
    keyPath: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ).isRequired,
    labelRenderer: PropTypes.func.isRequired,
    shouldExpandNode: PropTypes.func,
    level: PropTypes.number.isRequired,
    sortObjectKeys: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    isCircular: PropTypes.bool,
    expandable: PropTypes.bool
  };
  static defaultProps = {
    data: [],
    circularCache: [],
    level: 0,
    expandable: true
  };
  constructor(props) {
    super(props);
    this.state = getStateFromProps(props);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextState = getStateFromProps(nextProps);
    if (getStateFromProps(this.props).expanded !== nextState.expanded) {
      this.setState(nextState);
    }
  }
  shouldComponentUpdate(nextProps, nextState) {
    return !!Object.keys(nextProps).find(
      (key) => key !== "circularCache" && (key === "keyPath" ? nextProps[key].join("/") !== this.props[key].join("/") : nextProps[key] !== this.props[key])
    ) || nextState.expanded !== this.state.expanded;
  }
  render() {
    const {
      getItemString,
      nodeTypeIndicator,
      nodeType,
      data,
      hideRoot,
      createItemString,
      collectionLimit,
      keyPath,
      labelRenderer,
      expandable
    } = this.props;
    const { expanded } = this.state;
    const renderedChildren = expanded || hideRoot && this.props.level === 0 ? renderChildNodes({ ...this.props, level: this.props.level + 1 }) : null;
    const itemType = /* @__PURE__ */ React.createElement(
      "span",
      {
        className: `nestedNodeItemType ${expanded ? "-expanded" : ""}`
      },
      nodeTypeIndicator
    );
    const renderedItemString = getItemString(
      nodeType,
      data,
      itemType,
      createItemString(data, collectionLimit)
    );
    const stylingArgs = [keyPath, nodeType, expanded, expandable];
    return hideRoot ? /* @__PURE__ */ React.createElement(
      "li",
      {
        className: `node -rootNode ${stylingClassFromState(
          ...stylingArgs
        )}`
      },
      /* @__PURE__ */ React.createElement(
        "ul",
        {
          className: `node -rootNode ${stylingClassFromState(
            ...stylingArgs
          )}`
        },
        renderedChildren
      )
    ) : /* @__PURE__ */ React.createElement("li", null, /* @__PURE__ */ React.createElement(
      "div",
      {
        className: `node -nestedNode ${stylingClassFromState(
          ...stylingArgs
        )}`
      },
      /* @__PURE__ */ React.createElement(
        "span",
        {
          onClick: this.handleClick,
          className: `label-group ${expandable ? "-expandable" : ""}`
        },
        expandable && /* @__PURE__ */ React.createElement(
          JSONArrow,
          {
            nodeType,
            expanded,
            onClick: this.handleClick
          }
        ),
        /* @__PURE__ */ React.createElement(NodeIcon, { nodeType, expanded }),
        /* @__PURE__ */ React.createElement(
          "label",
          {
            className: `label nestedNodeLabel ${stylingClassFromState(
              ...stylingArgs
            )}`,
            onClick: this.handleClick
          },
          labelRenderer(...stylingArgs)
        ),
        /* @__PURE__ */ React.createElement(
          "span",
          {
            className: `nestedNodeItemString ${expanded ? "-expanded" : ""} ${stylingClassFromState(...stylingArgs)}`,
            onClick: this.handleClick
          },
          renderedItemString
        )
      )
    ), /* @__PURE__ */ React.createElement(
      "ul",
      {
        className: `nestedNodeChildren ${stylingClassFromState(
          ...stylingArgs
        )}`
      },
      renderedChildren
    ));
  }
  handleClick = () => {
    if (this.props.expandable) {
      this.setState({ expanded: !this.state.expanded });
    }
  };
}
