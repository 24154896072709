import React from "react";
import { Box, useToast } from "@chakra-ui/react";
import { StatusIcon } from "../shared/StatusIcon";
export const useActionToast = () => {
  const toast = useToast();
  const toastSuccess = (successMessage) => {
    toast({
      position: "bottom-left",
      render: () => /* @__PURE__ */ React.createElement(
        Box,
        {
          color: "black",
          padding: "12px 16px",
          borderRadius: 5,
          bg: "#CEE7DE"
        },
        /* @__PURE__ */ React.createElement(StatusIcon, { variant: "SUCCESS" }),
        successMessage
      )
    });
  };
  return {
    toastSuccess
  };
};
