import React from "react";
import ReferenceLines from "./HistogramReferenceLines";
import Legend from "./HistogramLegend";
import Baseline from "./HistogramBaseline";
import BaselineLabels from "./HistogramBaselineLabels";
import LinesAndToolTips from "./HistogramLinesAndTooltips";
import "./Histogram.css";
export const Histogram = ({ height, histogram, qubitCount, shotCount }) => {
  const svgWidth = 1e3;
  const svgHeight = height ?? 300;
  const measurements = {
    verticalPadding: 30,
    horizontalPadding: 40,
    labelTextHeight: 12,
    labelLetterWidth: 8,
    tooltipTextHeight: 11,
    tooltipHeight: 21,
    tooltipVerticalOffset: 20,
    tooltipLetterWidth: 7.5,
    tooltipHorizontalPadding: 7.5
  };
  measurements.svgHeight = svgHeight;
  measurements.svgWidth = svgWidth;
  measurements.baselineYValue = measurements.svgHeight - (measurements.verticalPadding + measurements.labelTextHeight);
  measurements.graphHeight = measurements.svgHeight - (measurements.verticalPadding * 2 + measurements.labelTextHeight);
  measurements.graphWidth = measurements.svgWidth - measurements.horizontalPadding * 2;
  return /* @__PURE__ */ React.createElement("div", { className: "Histogram" }, /* @__PURE__ */ React.createElement(
    "svg",
    {
      viewBox: `0 0 ${measurements.svgWidth} ${measurements.svgHeight}`
    },
    /* @__PURE__ */ React.createElement(ReferenceLines, { measurements }),
    shotCount && /* @__PURE__ */ React.createElement(Legend, { shotCount, measurements }),
    /* @__PURE__ */ React.createElement(Baseline, { qubitCount, measurements }),
    /* @__PURE__ */ React.createElement(
      BaselineLabels,
      {
        qubitCount,
        measurements
      }
    ),
    /* @__PURE__ */ React.createElement(
      LinesAndToolTips,
      {
        histogram,
        qubitCount,
        shotCount,
        measurements
      }
    )
  ));
};
