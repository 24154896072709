import React from "react";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useClipboard,
  useToast
} from "@chakra-ui/react";
import { APIKeyToast } from "./APIKeyToast";
import { useBaseModal } from "../shared/baseModals/useBaseModal";
import { MODALS } from "../shared/baseModals/constants";
export const APIKeyCreatedModal = () => {
  const { close, isOpen, props = {} } = useBaseModal(MODALS.apiKeyCreated);
  const toast = useToast();
  const toastIdRef = React.useRef();
  const { setValue, onCopy, hasCopied } = useClipboard(props.apiKey);
  return /* @__PURE__ */ React.createElement(Modal, { size: "xl", isOpen, onClose: close, isCentered: true }, /* @__PURE__ */ React.createElement(ModalOverlay, null), /* @__PURE__ */ React.createElement(ModalContent, { borderRadius: 3, pb: 4 }, /* @__PURE__ */ React.createElement(ModalBody, null, /* @__PURE__ */ React.createElement(Text, { fontWeight: 500, fontSize: "28px", m: "10px 0" }, "API key successfully created!"), /* @__PURE__ */ React.createElement(
    Alert,
    {
      status: "warning",
      mb: "8px",
      borderRadius: "5px",
      bg: "#FFF0D1"
    },
    /* @__PURE__ */ React.createElement(AlertIcon, { color: "#B28013", boxSize: 4 }),
    /* @__PURE__ */ React.createElement(
      Text,
      {
        display: "flex",
        fontSize: "14px",
        fontWeight: 400,
        mr: 1
      },
      "For your security,"
    ),
    /* @__PURE__ */ React.createElement(Text, { display: "flex", fontSize: "14px", fontWeight: 500 }, "the secret key will be shown once (right now)")
  ), /* @__PURE__ */ React.createElement(Text, { mt: "20px", fontSize: "14px", fontWeight: 400 }, "API key is unique and non-recoverable. Copy it now and save it in a safe location. Alternatively,", " ", /* @__PURE__ */ React.createElement(
    "button",
    {
      onClick: () => {
        const a = document.createElement("a");
        const file = new Blob([props.apiKey], {
          type: "text/plain"
        });
        a.href = URL.createObjectURL(file);
        a.download = `${props.projectName?.split(" ").join("_") + "_" || ""}api-key.txt`;
        a.click();
      }
    },
    /* @__PURE__ */ React.createElement(
      Text,
      {
        color: "#1F60DD",
        display: "flex",
        fontSize: "14px",
        fontWeight: 400
      },
      "download key file"
    )
  )), /* @__PURE__ */ React.createElement(Text, { mt: "20px", fontSize: "14px", fontWeight: 500 }, "Your API key"), /* @__PURE__ */ React.createElement(
    Input,
    {
      type: "text",
      autoCorrect: "off",
      spellCheck: false,
      value: props.apiKey,
      maxW: 360,
      readOnly: true
    }
  ), /* @__PURE__ */ React.createElement(Box, { mt: 5 }, /* @__PURE__ */ React.createElement(
    Button,
    {
      size: "md",
      variant: "blue",
      mr: 3,
      onClick: () => {
        setValue(props.apiKey);
        onCopy();
        toastIdRef.current = toast({
          duration: 5e3,
          position: "bottom-left",
          render: () => /* @__PURE__ */ React.createElement(
            APIKeyToast,
            {
              message: "API key successfully copied! Keep it safe.",
              status: "success",
              toast,
              toastIdRef
            }
          )
        });
      }
    },
    hasCopied ? "Copied" : "Copy",
    " API key"
  ), /* @__PURE__ */ React.createElement(Button, { size: "md", variant: "outline", onClick: close }, "Close")))));
};
