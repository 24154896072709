import { useEffect, useState } from "react";
import { fetchOrgQuota } from "./utils";
import { getStandardError } from "../../shared/utils/utils";
export const useOrgQuotas = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [orgQuotas, setOrgQuotas] = useState([]);
  const [error, setError] = useState(null);
  useEffect(() => {
    setIsLoading(true);
    fetchOrgQuota({
      onSuccess: (res) => {
        setOrgQuotas(res);
        setIsLoading(false);
      },
      onError: (e) => {
        setError(getStandardError(e));
        setIsLoading(false);
      }
    });
  }, []);
  return {
    orgQuotas,
    error,
    isLoading
  };
};
