import React from "react";
import { IonVisualizerSequence } from "./IonVisualizerSequence";
import { IonVisualizerGateRow } from "./IonVisualizerGateRow";
import { IonVisualizerLineRow } from "./IonVisualizerLineRow";
import { IonVisualizerLabelRow } from "./IonVisualizerLabelRow";
export const IonVisualizerCircuitGroup = ({
  collapsedSequences,
  timeSlices,
  measurements,
  svgWidth,
  handleSequenceHeaderClick,
  color,
  isHorizontal,
  parentQubitsUsed
}) => {
  return timeSlices.map((op) => {
    switch (op.type) {
      case "sequence":
        return /* @__PURE__ */ React.createElement(
          IonVisualizerSequence,
          {
            key: op.id,
            id: op.id,
            height: op.height,
            verticalOffset: op.verticalOffset,
            ops: op.ops,
            name: op.name,
            qubitsUsed: op.qubitsUsed,
            svgWidth,
            measurements,
            sequenceColor: op.color ? op.color : color,
            collapsedSequences,
            handleSequenceHeaderClick
          }
        );
      case "gateRow":
        return /* @__PURE__ */ React.createElement(
          IonVisualizerGateRow,
          {
            key: op.id,
            height: op.height,
            verticalOffset: op.verticalOffset,
            ops: op.ops,
            measurements,
            svgWidth,
            isHorizontal,
            color
          }
        );
      case "line":
        return /* @__PURE__ */ React.createElement(
          IonVisualizerLineRow,
          {
            key: op.id,
            height: op.height,
            verticalOffset: op.verticalOffset,
            measurements,
            ops: op.ops,
            svgWidth,
            color,
            parentQubitsUsed
          }
        );
      case "label":
        return /* @__PURE__ */ React.createElement(
          IonVisualizerLabelRow,
          {
            key: op.id,
            height: op.height,
            verticalOffset: op.verticalOffset,
            measurements,
            ops: op.ops,
            svgWidth,
            color,
            parentQubitsUsed
          }
        );
      default:
        return null;
    }
  });
};
