import React from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalOverlay
} from "@chakra-ui/react";
import styles from "./Modal.module.scss";
export const BaseModal = ({
  isOpen,
  close,
  title,
  showClose = true,
  children
}) => {
  return /* @__PURE__ */ React.createElement(Modal, { isOpen, onClose: close, size: "xl", isCentered: true }, /* @__PURE__ */ React.createElement(ModalOverlay, null), /* @__PURE__ */ React.createElement(ModalContent, { className: styles.modal, padding: "5px" }, /* @__PURE__ */ React.createElement(ModalHeader, { fontSize: "1.75rem", fontWeight: 500 }, title), showClose && /* @__PURE__ */ React.createElement(ModalCloseButton, { mt: "5px" }), children));
};
