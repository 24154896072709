const suffixes = {
  javascript: "js",
  sqore: "sq",
  python: "py",
  json: "json"
};
const downloadCode = (code, name, language) => {
  const dataString = `data:text/${language};charset=utf-8,` + encodeURIComponent(code);
  const downloadAnchorNode = document.createElement("a");
  document.body.appendChild(downloadAnchorNode);
  downloadAnchorNode.setAttribute("href", dataString);
  downloadAnchorNode.setAttribute("target", "_self");
  downloadAnchorNode.setAttribute(
    "download",
    `${name}${suffixes[language] ? "." + suffixes[language] : ""}`
  );
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
};
export const downloadFile = ({ data, fileName, fileType }) => {
  const blob = new Blob([data], { type: fileType });
  const a = document.createElement("a");
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};
export default downloadCode;
