import React from "react";
import Spinner from "../../Spinner/Spinner";
import { Form, Field, Formik } from "../../FormikWrapper";
import * as Yup from "yup";
import "./QRModal.css";
const QRModal = ({ onHide, onActivate, qrcode }) => {
  return /* @__PURE__ */ React.createElement("div", { className: "QRModal", onClick: onHide }, /* @__PURE__ */ React.createElement("div", { className: "modal", onClick: (e) => e?.stopPropagation() }, /* @__PURE__ */ React.createElement("h3", { className: "headline" }, "Activate two-factor auth"), /* @__PURE__ */ React.createElement("p", { className: "helper-text" }, "Scan the image above with a two-factor authentication app on your phone."), /* @__PURE__ */ React.createElement(
    Formik,
    {
      submitAction: onActivate,
      initialValues: {
        passCode: ""
      },
      validationSchema: Yup.object().shape({
        passCode: Yup.string().required(
          "please provide pass code"
        )
      }),
      validateOnChange: false
    },
    ({ dirty, touched, setErrors, errors }) => /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Form, null, !qrcode ? /* @__PURE__ */ React.createElement("div", { className: "spinner-container" }, /* @__PURE__ */ React.createElement(Spinner, null)) : /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("img", { alt: "qr", src: qrcode }), /* @__PURE__ */ React.createElement("div", { className: "input-group" }, /* @__PURE__ */ React.createElement("label", null, "Code"), /* @__PURE__ */ React.createElement(
      Field,
      {
        name: "passCode",
        type: "text",
        className: `text-input ${dirty && touched.passCode && errors.passCode ? "-errored" : ""}`,
        onFocus: () => {
          setErrors({
            ...errors,
            passCode: ""
          });
        }
      }
    ), dirty && touched.passCode && errors.passCode && /* @__PURE__ */ React.createElement("p", { className: "error-message" }, errors.passCode))), /* @__PURE__ */ React.createElement("div", { className: "input-group" }, /* @__PURE__ */ React.createElement(
      "button",
      {
        className: "submit-button",
        type: "submit",
        disabled: !qrcode
      },
      "Activate"
    ), /* @__PURE__ */ React.createElement("button", { type: "button", onClick: onHide }, "Cancel"))))
  )));
};
const Multifactor = ({
  qrcode,
  enabled,
  enrolling,
  isSSO,
  onEnroll,
  onActivate,
  onCancel
}) => {
  if (isSSO) {
    return /* @__PURE__ */ React.createElement("div", { className: "settings-panel" }, /* @__PURE__ */ React.createElement("h5", { className: "settings-panel-internal-headline" }, "Multi-factor authentication"), /* @__PURE__ */ React.createElement("p", { className: "settings-panel-helper-text" }, "Multi-factor authentication is disabled because your organization requires all accounts to authenticate through your Single Sign-On Provider (JumpCloud)."));
  }
  return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", { className: "settings-panel" }, /* @__PURE__ */ React.createElement("h5", { className: "settings-panel-internal-headline" }, "Multi-factor authentication"), /* @__PURE__ */ React.createElement("p", { className: "settings-panel-helper-text" }, "Multi-factor authentication adds an additional layer of security to your account by requiring more than just a password to log in."), enabled ? /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement(
    "span",
    {
      style: {
        borderRadius: 4,
        padding: "7px",
        color: "white",
        backgroundColor: "green"
      }
    },
    "enabled"
  )) : /* @__PURE__ */ React.createElement(
    "button",
    {
      onClick: onEnroll,
      className: "settings-panel-submit-button"
    },
    "Enroll"
  )), enrolling && /* @__PURE__ */ React.createElement(
    QRModal,
    {
      onHide: onCancel,
      qrcode,
      onActivate
    }
  ));
};
export default Multifactor;
