import { extendTheme } from "@chakra-ui/react";
const theme = extendTheme({
  fonts: {
    heading: "Ubuntu-Mono",
    body: "Colfaxx"
  },
  textStyles: {
    "body-1": {
      // 15/22
      fontSize: "0.9375rem",
      fontWeight: "500",
      lineHeight: "1.375rem",
      fontFamily: "Colfaxx"
    },
    "body-2": {
      // 14/21
      fontSize: "0.875rem",
      fontWeight: "500",
      lineHeight: "1.3125rem",
      fontFamily: "Colfaxx"
    },
    "body-3": {
      // 14/21
      fontSize: "0.875rem",
      fontWeight: "400",
      lineHeight: "1.3125rem",
      fontFamily: "Colfaxx"
    },
    "body-4": {
      // 13/19
      fontSize: "0.8125rem",
      fontWeight: "500",
      lineHeight: "1.1875rem",
      fontFamily: "Colfaxx"
    },
    "body-5": {
      // 13/19
      fontSize: "0.8125rem",
      fontWeight: "400",
      lineHeight: "1.1875rem",
      fontFamily: "Colfaxx"
    },
    "body-6": {
      // 12/18
      fontSize: "0.75rem",
      fontWeight: "400",
      lineHeight: "1.125rem",
      fontFamily: "Colfaxx"
    },
    "body-7": {
      // 22/22
      fontSize: "1.375rem",
      fontWeight: "700",
      lineHeight: "1.375rem",
      fontFamily: "Ubuntu-Mono"
    },
    "body-8": {
      // 15/15
      fontSize: "0.9375rem",
      fontWeight: "400",
      lineHeight: "0.9375rem",
      fontFamily: "Ubuntu-Mono"
    },
    "body-9": {
      // 13/13
      fontSize: "0.8125rem",
      fontWeight: "400",
      lineHeight: "0.8125rem",
      fontFamily: "Ubuntu-Mono"
    },
    "body-table-name": {
      // 15/19
      fontSize: "0.9375rem",
      fontWeight: "400",
      lineHeight: "1.1875rem",
      fontFamily: "Ubuntu-Mono"
    },
    "heading-1": {
      // 28/34
      fontSize: "1.75rem",
      fontWeight: "500",
      lineHeight: "2.125rem",
      fontFamily: "Colfaxx"
    },
    "heading-2": {
      // 24/29
      fontSize: "1.5rem",
      fontWeight: "500",
      lineHeight: "1.8125rem",
      fontFamily: "Colfaxx"
    },
    "heading-3": {
      // 20/24
      fontSize: "1.25rem",
      fontWeight: "500",
      lineHeight: "1.5rem",
      fontFamily: "Colfaxx"
    },
    "heading-4": {
      // 18/22
      fontSize: "1.125rem",
      fontWeight: "500",
      lineHeight: "1.375rem",
      fontFamily: "Colfaxx"
    },
    "heading-5": {
      // 16/19
      fontSize: "1rem",
      fontWeight: "500",
      lineHeight: "1.1875rem",
      fontFamily: "Colfaxx"
    }
  },
  components: {
    Checkbox: {
      baseStyle: {
        control: {
          _checked: {
            backgroundColor: "uiBlue",
            borderColor: "uiBlue",
            _hover: {
              backgroundColor: "uiDarkBlue",
              borderColor: "uiDarkBlue"
            }
          },
          border: "1px solid #C0C0C3",
          borderColor: "#C0C0C3"
        }
      }
    },
    Tabs: {
      variants: {
        unstyled: {
          tab: {
            color: "gray.600",
            textStyle: "body-1",
            _selected: {
              borderBottomWidth: "2px",
              borderBottomColor: "orange.500",
              color: "black"
            }
          }
        }
      }
    },
    Badge: {
      baseStyle: {
        borderRadius: "5px",
        padding: "2px 8px",
        fontWeight: "500",
        fontFamily: "Ubuntu-Mono"
      }
    },
    Button: {
      baseStyle: {
        fontWeight: "500",
        lineHeight: "1.1875rem",
        fontFamily: "Colfaxx",
        padding: "0 12px !important",
        maxHeight: "28px"
      },
      sizes: {
        sm: {
          borderRadius: "5px",
          fontSize: "0.8125rem"
        },
        md: {
          borderRadius: "5px",
          fontSize: "0.8125rem",
          maxHeight: "32px"
        },
        lg: {
          maxHeight: "38px",
          borderRadius: "6px",
          fontSize: "0.9375rem",
          fontWeight: "500",
          lineHeight: "1.375rem"
        }
      },
      variants: {
        white: {
          border: "1px solid #dddddd",
          color: "ionDark",
          backgroundColor: "white",
          _hover: {
            backgroundColor: "gray.50"
          },
          _active: {
            backgroundColor: "gray.50"
          },
          _disabled: {
            _hover: {
              bg: "white !important"
            }
          }
        },
        grey: {
          _active: {
            bg: "gray.50"
          },
          _hover: {
            bg: "gray.50"
          },
          border: "1px solid #E0E0E3",
          borderColor: "gray.200",
          color: "ionDark",
          backgroundColor: "white",
          _disabled: {
            _hover: {
              bg: "gray.50"
            }
          }
        },
        blue: {
          _active: {
            bg: "uiDarkBlue"
          },
          _hover: {
            bg: "uiDarkBlue"
          },
          color: "white",
          backgroundColor: "uiBlue",
          _disabled: {
            _hover: {
              bg: "uiBlue !important"
            }
          }
        },
        red: {
          border: "1px solid uiRed",
          color: "white",
          backgroundColor: "uiRed",
          _hover: {
            backgroundColor: "uiDarkRed"
          },
          _active: {
            backgroundColor: "uiDarkRed"
          },
          _disabled: {
            _hover: {
              bg: "uiRed !important"
            }
          }
        },
        popover: {
          fontSize: "sm",
          backgroundColor: "white",
          _hover: { backgroundColor: "gray.50" },
          _active: {
            backgroundColor: "gray.50"
          },
          color: "#236BF6",
          width: "100%",
          align: "left",
          borderWidth: 0,
          borderBottomWidth: "1px",
          borderRadius: 0
        }
      }
    },
    Modal: {
      baseStyle: {
        dialog: {
          minWidth: "630px",
          width: "630px"
        }
      }
    },
    Progress: {
      baseStyle: {
        filledTrack: {},
        track: {
          borderRadius: "3px",
          bg: "gray.200"
        }
      },
      label: {}
    },
    Table: {
      variants: {
        ionq: {
          th: {
            borderBottom: "1px solid #e8e8e8",
            textTransform: "none",
            backgroundClip: "padding-box",
            fontSize: "14px",
            bg: "gray.50",
            fontFamily: "Colfaxx",
            color: "#666666",
            fontWeight: 500,
            letterSpacing: "-1",
            maxHeight: "44px",
            height: "44px",
            cursor: "pointer"
          },
          tr: {
            maxHeight: "44px",
            height: "44px",
            cursor: "pointer",
            _hover: { bg: "gray.50" }
          },
          td: {
            textTransform: "none",
            fontSize: "13px",
            bg: "transparent",
            color: "gray.900",
            borderBottom: "1px solid #ddd",
            textAlign: "left",
            fontFamily: "Ubuntu-Mono"
          },
          thead: {
            backgroundColor: "gray.50",
            color: "green",
            lineHeight: "24px",
            height: "24px"
          },
          caption: {}
        },
        white: {
          border: "1px solid #E0E0E3",
          borderColor: "gray.200",
          colorScheme: "white",
          color: "ionDark",
          backgroundColor: "white",
          _active: {
            backgroundColor: "gray.50"
          },
          _hover: {
            backgroundColor: "gray.50"
          }
        }
      }
    }
  },
  colors: {
    uiLightBlue: "#E6EEFD",
    uiBlue: "#236BF6",
    uiDarkBlue: "#1F60DD",
    uiLightRed: "#FDF2F2",
    uiRed: "#E52424",
    uiDarkRed: "#CD1D1D",
    uiLightGreen: "#D6F1E7",
    uiGreen: "#00AD6E",
    uiDarkGreen: "#00764B",
    uiLightOrange: "#FFE5DB",
    uiOrange: "#ED4B00",
    uiDarkOrange: "#BD3C00",
    uiLightYellow: "#FFF7E8",
    uiYellow: "#FFB81C",
    uiDarkYellow: "#B28013",
    ionDark: "#0f161b",
    gray: {
      50: "#FAFAFA",
      100: "#E9E9EB",
      200: "#E0E0E3",
      300: "#C0C0C3",
      400: "#A0A0A3",
      500: "#808083",
      600: "#606063",
      700: "#404043",
      800: "#202023",
      900: "#0F161B"
    },
    orange: {
      500: "#ED7C37"
    }
  }
});
export default theme;
