import React from "react";
import { ModalBody, Spinner, Center, Grid, Box } from "@chakra-ui/react";
import { StatusIcon } from "../StatusIcon";
import styles from "./Modal.module.scss";
export const BaseModalBody = ({
  isLoading,
  loadingError,
  errorBannerContent,
  children
}) => {
  if (isLoading) {
    return /* @__PURE__ */ React.createElement(ModalBody, { className: styles.modalBody }, /* @__PURE__ */ React.createElement(Center, null, /* @__PURE__ */ React.createElement(Spinner, null)));
  }
  if (loadingError) {
    return /* @__PURE__ */ React.createElement(ModalBody, { className: styles.modalBody }, /* @__PURE__ */ React.createElement(Center, null, loadingError));
  }
  return /* @__PURE__ */ React.createElement(ModalBody, { className: styles.modalBody }, errorBannerContent && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Grid, { templateColumns: "1fr 20fr", bg: "#F9E3E1", p: 4 }, /* @__PURE__ */ React.createElement(StatusIcon, { variant: "WARNING" }), errorBannerContent), /* @__PURE__ */ React.createElement(Box, { pb: "5" })), children);
};
