import React from "react";
import { drawTrace } from "./ionVisualizerUtils";
export const IonVisualizerGate = ({
  gate,
  measurements,
  sequenceColor,
  svgWidth,
  isHorizontal
}) => {
  const gateSize = 24;
  const gateType = gate.alias ? gate.alias.toUpperCase() : gate.type.toUpperCase();
  const multiQubit = gate.targets.length > 1;
  let gateColor;
  if (gate.color) {
    gateColor = gate.color;
  } else if (sequenceColor) {
    gateColor = sequenceColor;
  } else {
    gateColor = null;
  }
  const getGateAngle = (gateAngle) => {
    if (gateAngle && gateAngle / Math.PI !== 1) {
      return gateAngle.toString().substring(0, 5);
    } else return null;
  };
  const drawControls = (gate2, gateHeight, gateWidth = gateSize) => {
    const circleRadius = 5;
    const targets = gate2.targets.sort((a, b) => a - b);
    const controls = gate2.controls.sort((a, b) => a - b);
    const controlsAsDistances = controls.map(
      (control) => targets[0] - control
    );
    const controlsAndTargets = targets.concat(controls).sort((a, b) => a - b);
    const controlsAndTargetsAsDistances = controlsAndTargets.map(
      (point) => targets[0] - point
    );
    const farthestControl = Math.max(...controlsAndTargetsAsDistances);
    const nearestControl = Math.min(...controlsAndTargetsAsDistances);
    return /* @__PURE__ */ React.createElement("g", { transform: `translate(0,${gateHeight / 2})` }, /* @__PURE__ */ React.createElement(
      "line",
      {
        x1: measurements.wireWidth * nearestControl + gateWidth / 2,
        x2: measurements.wireWidth * farthestControl + gateWidth / 2,
        y1: "0",
        y2: "0",
        className: "control-line",
        style: { stroke: gateColor, opacity: 0.25 }
      }
    ), controlsAsDistances.map((control, i) => {
      return /* @__PURE__ */ React.createElement(
        "circle",
        {
          transform: `translate(${measurements.wireWidth * control + gateWidth / 2},0)`,
          key: i,
          className: "control-marker",
          cx: "0",
          cy: "0",
          r: circleRadius,
          style: { fill: gateColor }
        }
      );
    }));
  };
  const drawStandardGate = () => {
    const backgroundWidth = gateSize + (gateType.length - 1) * 10;
    const targets = gate.targets.sort((a, b) => a - b);
    return /* @__PURE__ */ React.createElement(
      "g",
      {
        className: `gate-${gateType}`,
        transform: `translate(${svgWidth - measurements.wireWidth * targets[0] - measurements.wireWidth + (measurements.wireWidth - backgroundWidth) / 2},${measurements.gateRowHeight / 2 - gateSize / 2})`
      },
      gate.controls.length !== 0 && drawControls(gate, gateSize, backgroundWidth),
      /* @__PURE__ */ React.createElement(
        "g",
        {
          transform: `rotate(${isHorizontal ? "90" : "0"}, ${backgroundWidth / 2}, ${gateSize / 2})`
        },
        /* @__PURE__ */ React.createElement(
          "rect",
          {
            className: "svg-gate",
            width: backgroundWidth,
            height: gateSize,
            x: "0",
            y: "0"
          }
        ),
        /* @__PURE__ */ React.createElement(
          "text",
          {
            className: "svg-gate-text",
            x: backgroundWidth / 2,
            y: gateSize / 2 + 6,
            style: { fill: gateColor, stroke: gateColor }
          },
          /* @__PURE__ */ React.createElement("tspan", { className: "svg-gate-name" }, gateType)
        ),
        getGateAngle(gate.rotation) && /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement(
          "rect",
          {
            className: "svg-gate",
            width: gateSize,
            height: "14",
            x: "0",
            y: "19"
          }
        ), /* @__PURE__ */ React.createElement(
          "text",
          {
            x: backgroundWidth / 2,
            y: "27",
            className: "svg-gate-rotation"
          },
          getGateAngle(gate.rotation)
        ))
      )
    );
  };
  const drawMultiQubitGate = () => {
    const targets = gate.targets.sort((a, b) => a - b);
    const max = Math.max(...targets);
    const min = Math.min(...targets);
    const occupiedQubitRange = Array.from(
      new Array(max - min + 1),
      (x, i) => i + min
    );
    const gateHeight = isHorizontal ? gateSize + (gateType.length - 1) * 10 : gateSize + 4;
    const gateWidth = measurements.wireWidth * (max - min) + measurements.wireWidth;
    const traceColor = gateColor || "#C8C8C8";
    const xOffset = svgWidth - (max + 1) * measurements.wireWidth;
    return /* @__PURE__ */ React.createElement(
      "g",
      {
        transform: `translate(${xOffset},${measurements.gateRowHeight / 2 - gateHeight / 2})`
      },
      /* @__PURE__ */ React.createElement(
        "g",
        {
          transform: `translate(${gateWidth / 2 - measurements.wireWidth / 2},0)`
        },
        gate.controls.length !== 0 && drawControls(gate, gateHeight, gateWidth)
      ),
      /* @__PURE__ */ React.createElement(
        "rect",
        {
          width: gateWidth,
          height: gateHeight,
          className: "svg-gate"
        }
      ),
      /* @__PURE__ */ React.createElement("g", { className: "sequence-traces" }, occupiedQubitRange.map((qubit, i) => {
        return drawTrace(
          qubit,
          targets,
          gateWidth,
          gateHeight,
          traceColor,
          measurements,
          i
        );
      })),
      /* @__PURE__ */ React.createElement(
        "text",
        {
          className: "svg-gate-text",
          transform: `${isHorizontal ? "rotate(90)" : ""}`,
          x: isHorizontal ? gateHeight / 2 : gateWidth / 2,
          y: isHorizontal ? -gateWidth / 2 + 6 : gateHeight / 2 + 6,
          style: { fill: gateColor, stroke: gateColor }
        },
        /* @__PURE__ */ React.createElement("tspan", { className: "svg-gate-name" }, gateType)
      )
    );
  };
  const drawCNOT = () => {
    const circleRadius = gateSize / 2 - 4;
    const gateSizeForControlWire = gateSize + 1;
    const targets = gate.targets.sort((a, b) => a - b);
    return /* @__PURE__ */ React.createElement(
      "g",
      {
        className: `gate-${gateType}`,
        transform: `translate(${svgWidth - measurements.wireWidth * targets[0] - measurements.wireWidth + (measurements.wireWidth - gateSize) / 2},${measurements.gateRowHeight / 2 - gateSize / 2})`
      },
      gate.controls.length && drawControls(gate, gateSizeForControlWire),
      /* @__PURE__ */ React.createElement(
        "circle",
        {
          className: "svg-gate",
          cx: gateSize / 2,
          cy: gateSize / 2,
          r: gateSize / 2
        }
      ),
      /* @__PURE__ */ React.createElement(
        "circle",
        {
          className: "cnot-marker",
          cx: gateSize / 2,
          cy: gateSize / 2,
          r: circleRadius,
          style: { stroke: gateColor }
        }
      ),
      /* @__PURE__ */ React.createElement("g", { transform: `translate(${gateSize / 2}, ${gateSize / 2})` }, /* @__PURE__ */ React.createElement(
        "line",
        {
          className: "cnot-marker",
          x1: circleRadius,
          x2: -circleRadius,
          y1: "0",
          y2: "0",
          style: { stroke: gateColor }
        }
      ), /* @__PURE__ */ React.createElement(
        "line",
        {
          className: "cnot-marker",
          x1: "0",
          x2: "0",
          y1: circleRadius,
          y2: -circleRadius,
          style: { stroke: gateColor }
        }
      ))
    );
  };
  const drawSWAP = () => {
    const markerLineLength = 16;
    const backgroundLineLength = 24;
    const targets = gate.targets.sort((a, b) => a - b);
    const lineStart = measurements.wireWidth * (targets[0] - targets[1]) + gateSize / 2;
    const lineVerticalDisplacement = gateSize / 2;
    return /* @__PURE__ */ React.createElement(
      "g",
      {
        className: `gate-${gateType}`,
        transform: `translate(${svgWidth - measurements.wireWidth * targets[0] - measurements.wireWidth + (measurements.wireWidth - gateSize) / 2},${measurements.gateRowHeight / 2 - gateSize / 2})`
      },
      /* @__PURE__ */ React.createElement(
        "line",
        {
          x1: gateSize / 2,
          x2: lineStart,
          y1: lineVerticalDisplacement,
          y2: lineVerticalDisplacement,
          className: "swap-control-line",
          style: { stroke: gateColor, opacity: 0.25 }
        }
      ),
      targets.map((target, i) => {
        const xOffset = measurements.wireWidth * (targets[0] - target);
        return /* @__PURE__ */ React.createElement("g", { key: i, transform: `translate(${xOffset},0)` }, /* @__PURE__ */ React.createElement(
          "rect",
          {
            className: "swap-sizing-box",
            width: gateSize,
            height: gateSize
          }
        ), /* @__PURE__ */ React.createElement(
          "g",
          {
            transform: `translate(${gateSize / 2 - backgroundLineLength / 2},${gateSize / 2})`
          },
          /* @__PURE__ */ React.createElement(
            "line",
            {
              className: "swap-background",
              transform: `rotate(45 ${backgroundLineLength / 2} 0)`,
              x1: "0",
              x2: backgroundLineLength,
              y1: "0",
              y2: "0"
            }
          ),
          /* @__PURE__ */ React.createElement(
            "line",
            {
              className: "swap-background",
              transform: `rotate(-45 ${backgroundLineLength / 2} 0)`,
              x1: "0",
              x2: backgroundLineLength,
              y1: "0",
              y2: "0"
            }
          )
        ), /* @__PURE__ */ React.createElement(
          "g",
          {
            transform: `translate(${gateSize / 2 - markerLineLength / 2},${gateSize / 2})`
          },
          /* @__PURE__ */ React.createElement(
            "line",
            {
              className: "swap-marker",
              transform: `rotate(45 ${markerLineLength / 2} 0)`,
              x1: "0",
              x2: markerLineLength,
              y1: "0",
              y2: "0",
              style: { stroke: gateColor }
            }
          ),
          /* @__PURE__ */ React.createElement(
            "line",
            {
              className: "swap-marker",
              transform: `rotate(-45 ${markerLineLength / 2} 0)`,
              x1: "0",
              x2: markerLineLength,
              y1: "0",
              y2: "0",
              style: { stroke: gateColor }
            }
          )
        ));
      }),
      gate.controls.length && drawControls(gate, gateSize)
    );
  };
  if (multiQubit) {
    switch (gateType) {
      case "SWAP":
        return drawSWAP();
      default:
        return drawMultiQubitGate();
    }
  } else {
    switch (gateType) {
      case "X":
      case "NOT":
        return gate.controls.length ? drawCNOT() : drawStandardGate();
      case "H":
      case "Y":
      case "Z":
      case "S":
      case "T":
      case "RX":
      case "RY":
      case "RZ":
        return drawStandardGate();
      default:
        return drawStandardGate();
    }
  }
};
