import React, { useState } from "react";
import { Grid, GridItem, Input, Text } from "@chakra-ui/react";
import { MODALS } from "../shared/baseModals/constants";
import { BaseModal, BaseModalBody, BaseModalFooter } from "../shared/Modal";
import { useBaseModal } from "../shared/baseModals/useBaseModal";
import api from "../../api.js";
import { useActionToast } from "../CancelJobConfirmationModal/useActionToast.js";
export const CancelMultipleJobsConfirmationModal = () => {
  const { toastSuccess } = useActionToast();
  const { close, isOpen, props } = useBaseModal(
    MODALS.cancelMultipleJobsConfirmation
  );
  const [ctaValue, setCTAValue] = useState("");
  return /* @__PURE__ */ React.createElement(BaseModal, { title: "Cancel jobs", isOpen, close }, /* @__PURE__ */ React.createElement(BaseModalBody, null, /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3", pb: "16px" }, "Are you sure you want to cancel ", props?.jobs?.length ?? 0, " ", "jobs across ", props?.projects?.length ?? 0, " projects? Canceling these jobs will prevent their execution and will not incur any additional usage. This action cannot be undone."), /* @__PURE__ */ React.createElement(Grid, { templateColumns: "1fr 3fr", gap: "2 0", pb: "16px" }, /* @__PURE__ */ React.createElement(GridItem, { textStyle: "body-2" }, "jobs"), /* @__PURE__ */ React.createElement(GridItem, { textStyle: "body-3" }, props?.jobs?.length ?? 0), /* @__PURE__ */ React.createElement(GridItem, { textStyle: "body-2" }, "projects"), /* @__PURE__ */ React.createElement(GridItem, { textStyle: "body-3" }, props?.projects?.length ?? 0)), /* @__PURE__ */ React.createElement(Text, { textStyle: "body-2" }, "Please type the phrase \u2018Cancel these jobs\u2019 to confirm"), /* @__PURE__ */ React.createElement(
    Input,
    {
      placeholder: "Type here to confirm",
      type: "email",
      onInput: (e) => {
        setCTAValue(e.target.value);
      }
    }
  ), /* @__PURE__ */ React.createElement(
    BaseModalFooter,
    {
      isDanger: true,
      onClose: close,
      onCtaClick: () => {
        if (props && props.jobs && props.ctaPreAction && props.ctaSuccessAction && props.ctaErrorAction) {
          props.ctaPreAction();
          Promise.all(
            props.jobs.map((jobId) => {
              return api.cancelJob(jobId);
            })
          ).then(() => {
            toastSuccess(
              `${props.jobs.length} jobs successfully cancelled`
            );
            props.ctaSuccessAction();
          }, props.ctaErrorAction).then(() => {
            close();
            setCTAValue("");
          });
        }
      },
      ctaLabel: "I understand, cancel these jobs",
      isCtaDisabled: ctaValue !== "Cancel these jobs"
    }
  )));
};
