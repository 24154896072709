import { Center, CloseButton, Flex, Text } from "@chakra-ui/react";
import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";
import React from "react";
export const APIKeyToast = ({ message, status, toast, toastIdRef }) => {
  const isSuccess = status === "success";
  function close() {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current);
    }
  }
  return /* @__PURE__ */ React.createElement(
    Flex,
    {
      justifyContent: "space-between",
      padding: 4,
      backgroundColor: isSuccess ? "#CEE7DE" : "#F9E3E1",
      maxHeight: "48px",
      borderRadius: "5px",
      marginBottom: "24px",
      marginLeft: "24px"
    },
    /* @__PURE__ */ React.createElement(Center, null, isSuccess ? /* @__PURE__ */ React.createElement(CheckCircleIcon, { color: "#00794A" }) : /* @__PURE__ */ React.createElement(WarningIcon, { color: "#D34233" })),
    /* @__PURE__ */ React.createElement(
      Text,
      {
        fontSize: "14px",
        fontWeight: 400,
        color: "#333333",
        marginLeft: "8px"
      },
      message
    ),
    /* @__PURE__ */ React.createElement(Center, null, /* @__PURE__ */ React.createElement(
      CloseButton,
      {
        size: "sm",
        onClick: close,
        color: "#333333",
        marginLeft: "8px"
      }
    ))
  );
};
