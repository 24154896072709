import React from "react";
import { drawTrace } from "./ionVisualizerUtils";
import { IonVisualizerCircuitGroup } from "./IonVisualizerCircuitGroup";
export const IonVisualizerSequence = ({
  id,
  height,
  verticalOffset,
  ops,
  name,
  qubitsUsed,
  svgWidth,
  measurements,
  sequenceColor,
  collapsedSequences,
  handleSequenceHeaderClick
}) => {
  const sequenceIsCollapsed = collapsedSequences.includes(id);
  const max = Math.max(...qubitsUsed);
  const min = Math.min(...qubitsUsed);
  const sequenceWidth = measurements.wireWidth * (max - min) + measurements.wireWidth;
  const sequenceBoxYOffset = verticalOffset;
  const sequenceGatesOffset = verticalOffset;
  const sequenceBoxXOffset = svgWidth - measurements.wireWidth * max - measurements.wireWidth;
  const occupiedQubitRange = Array.from(
    new Array(max - min + 1),
    (x, i) => i + min
  );
  return /* @__PURE__ */ React.createElement("g", { key: id, className: `sequence -${id}`, transform: `translate(0,0)` }, /* @__PURE__ */ React.createElement(
    "g",
    {
      className: "sequence-box",
      transform: `translate(${sequenceBoxXOffset},${sequenceBoxYOffset})`
    },
    /* @__PURE__ */ React.createElement(
      "rect",
      {
        width: sequenceWidth,
        height,
        className: "sequence-background",
        style: sequenceColor ? { fill: sequenceColor, opacity: 0.1 } : {}
      }
    ),
    /* @__PURE__ */ React.createElement(
      "g",
      {
        className: "sequence-header",
        onClick: handleSequenceHeaderClick.bind(void 0, id)
      },
      /* @__PURE__ */ React.createElement(
        "rect",
        {
          className: "sequence-clickable-area",
          width: sequenceWidth,
          height: "30"
        }
      ),
      /* @__PURE__ */ React.createElement(
        "text",
        {
          className: "sequence-name",
          transform: "translate(10,20)",
          style: sequenceColor ? { fill: sequenceColor } : {}
        },
        name
      )
    ),
    /* @__PURE__ */ React.createElement("g", { className: "sequence-traces" }, occupiedQubitRange.map((qubit, i) => {
      return drawTrace(
        qubit,
        qubitsUsed,
        sequenceWidth,
        height,
        sequenceColor,
        measurements,
        i
      );
    }))
  ), !sequenceIsCollapsed && /* @__PURE__ */ React.createElement(
    "g",
    {
      className: "sequence-gates",
      transform: `translate(0,${sequenceGatesOffset})`
    },
    /* @__PURE__ */ React.createElement(
      IonVisualizerCircuitGroup,
      {
        timeSlices: ops,
        measurements,
        svgWidth,
        handleSequenceHeaderClick,
        collapsedSequences,
        color: sequenceColor,
        parentQubitsUsed: qubitsUsed
      }
    )
  ));
};
