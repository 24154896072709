import React from "react";
const HistogramBaselineLabels = ({ qubitCount, measurements }) => {
  return /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement(
    "text",
    {
      className: "baseline-label",
      textAnchor: "start",
      x: measurements.horizontalPadding - 4,
      y: measurements.svgHeight - (measurements.verticalPadding - 3)
    },
    "0".repeat(qubitCount)
  ), /* @__PURE__ */ React.createElement(
    "text",
    {
      className: "baseline-label",
      textAnchor: "end",
      x: measurements.svgWidth - measurements.horizontalPadding + 3,
      y: measurements.svgHeight - (measurements.verticalPadding - 1)
    },
    "1".repeat(qubitCount)
  ));
};
export default HistogramBaselineLabels;
