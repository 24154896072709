import React from "react";
const HistogramReferenceLines = ({ measurements }) => {
  const lineSpacing = measurements.graphHeight / 10;
  let referenceLines = [];
  for (let i = 0; i <= 10; i++) {
    referenceLines.push(
      /* @__PURE__ */ React.createElement(
        "line",
        {
          className: "reference-line",
          x1: measurements.horizontalPadding,
          x2: measurements.svgWidth - measurements.horizontalPadding,
          y1: measurements.baselineYValue - lineSpacing * i,
          y2: measurements.baselineYValue - lineSpacing * i,
          key: i
        }
      )
    );
  }
  return /* @__PURE__ */ React.createElement("g", null, referenceLines);
};
export default HistogramReferenceLines;
