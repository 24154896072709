import React, { useEffect, useState } from "react";
import { OrganizationContext } from "./context";
import { fetchOrg } from "../../Projects/hooks/utils";
import { getStandardError } from "../utils/utils";
export const OrganizationContextProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [organization, setData] = useState(null);
  const [error, setError] = useState(null);
  const [startRefetch, setStartRefetch] = useState(true);
  useEffect(() => {
    if (!startRefetch) return;
    setIsLoading(true);
    fetchOrg({
      onSuccess: (res) => {
        setData(res);
        setIsLoading(false);
        setError(null);
        setStartRefetch(false);
      },
      onError: (e) => {
        setError(getStandardError(e));
        setIsLoading(false);
        setStartRefetch(false);
      }
    });
  }, [startRefetch, setStartRefetch]);
  const refetch = () => {
    setStartRefetch(true);
  };
  return /* @__PURE__ */ React.createElement(
    OrganizationContext.Provider,
    {
      value: { organization, isLoading, error, refetch }
    },
    children
  );
};
