export default `.generated-svg .svg-wire, .generated-svg .svg-wire-measurement-icon rect, .generated-svg .svg-wire-measurement-icon path, .generated-svg .svg-wire-measurement-icon line {
  stroke: #E8E8E8;
  stroke-width: 2px;
  stroke-miterlimit: 10;
  fill: none; }

.generated-svg .initialization-line {
  stroke: #DDDDDD;
  stroke-width: 1.3px;
  stroke-miterlimit: 10;
  fill: none; }

.generated-svg .svg-wire-measurement-icon rect {
  fill: #FFFFFF; }

.generated-svg .svg-wire-label {
  font-size: 14px;
  font-weight: 800;
  font-family: "Ubuntu-Mono", monospace;
  fill: #BBBBBB;
  text-anchor: middle; }

.generated-svg .svg-wire-label.-horizontal {
  text-anchor: end; }

.generated-svg .svg-wire-initialization-label {
  font-size: 11px;
  font-weight: 800;
  font-family: "Ubuntu-Mono", monospace;
  fill: #DDDDDD;
  text-anchor: middle; }

.generated-svg .svg-wire-label-bg {
  fill: transparent; }

.generated-svg .sequence {
  fill: rgba(0, 0, 0, 0.03); }

.generated-svg .svg-gate {
  fill: #FFFFFF; }

.generated-svg .svg-gate-text, .generated-svg .svg-gate-rotation {
  font-size: 18px;
  font-weight: 900;
  font-family: system-ui, Helvetica, sans-serif;
  text-rendering: optimizeLegibility;
  fill: #666666;
  text-anchor: middle;
  stroke: #666666;
  stroke-width: .7; }

.generated-svg .svg-gate-rotation {
  font-size: 7.5px;
  font-weight: 300; }

.generated-svg .control-marker {
  fill: #666666; }

.generated-svg .control-line, .generated-svg .swap-control-line {
  stroke: #BBBBBB;
  stroke-width: 2; }

.generated-svg .swap-marker {
  stroke: #666666;
  stroke-width: 2.5; }

.generated-svg .swap-background {
  stroke: #FFFFFF;
  stroke-width: 8; }

.generated-svg .swap-sizing-box {
  fill: transparent; }

.generated-svg .cnot-marker {
  fill: transparent;
  stroke: #666666;
  stroke-width: 2.5; }

.generated-svg .label-text {
  font-size: 15px;
  font-family: "Ubuntu-Mono", monospace;
  font-weight: 900;
  text-rendering: auto;
  fill: #666666;
  text-anchor: left;
  transition: fill .2s ease-in-out;
  alignment-baseline: middle; }

.generated-svg .sequence-name {
  font-size: 14px;
  font-weight: 900;
  font-family: system-ui, Helvetica, sans-serif;
  text-rendering: optimizeLegibility;
  fill: #666666;
  text-anchor: left;
  transition: fill .2s ease-in-out; }

.generated-svg .sequence-header:hover {
  cursor: pointer; }
  .generated-svg .sequence-header:hover .sequence-name {
    cursor: pointer;
    fill: #999999; }

.generated-svg .sequence-clickable-area {
  fill: transparent;
  transition: fill .2s ease-in-out; }
  .generated-svg .sequence-clickable-area:hover {
    fill: rgba(0, 0, 0, 0.03); }

.generated-svg .trace-line {
  stroke: #999999;
  stroke-width: 2; }

.generated-svg .rule-line {
  stroke: #999999;
  stroke-width: 3; }
`;
