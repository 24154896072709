import { Box, Center, Text } from "@chakra-ui/react";
import React from "react";
export const DefaultEmptyView = () => /* @__PURE__ */ React.createElement(
  Box,
  {
    borderRadius: "5px",
    bg: "white",
    boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.05)",
    border: "1px solid #e8e8e8",
    borderBottom: "2px solid #e8e8e8",
    borderColor: "gray.200"
  },
  /* @__PURE__ */ React.createElement(Center, null, /* @__PURE__ */ React.createElement(Text, { textStyle: "body-8", color: "gray.600", p: "16px" }, "No items found"))
);
