import React from "react";
import { Form, Field, Formik } from "../../FormikWrapper";
import * as Yup from "yup";
const Email = ({ email, onSubmit }) => /* @__PURE__ */ React.createElement(
  Formik,
  {
    submitAction: onSubmit,
    enableReinitialize: true,
    initialValues: {
      email
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email("please enter a valid email address").required("email address is required")
    }),
    validateOnChange: false
  },
  ({ dirty, touched, setErrors, errors, status, isSubmitting }) => /* @__PURE__ */ React.createElement("div", null, status && status.success && /* @__PURE__ */ React.createElement("div", { className: "success-box" }, status.success), errors.global && /* @__PURE__ */ React.createElement("div", { className: "error-box" }, errors.global), /* @__PURE__ */ React.createElement(Form, { className: "settings-panel" }, /* @__PURE__ */ React.createElement("h5", { className: "settings-panel-internal-headline" }, "Email address"), /* @__PURE__ */ React.createElement("p", { className: "settings-panel-helper-text" }, "This will change the email address that you use to log into the IonQ Quantum Cloud Console."), /* @__PURE__ */ React.createElement("div", { className: "settings-panel-input-group" }, /* @__PURE__ */ React.createElement("label", { className: "label" }, "Email"), /* @__PURE__ */ React.createElement(
    Field,
    {
      onFocus: () => {
        setErrors({ ...errors, email: "" });
      },
      className: `text-input ${dirty && touched.email && errors.email ? "-errored" : ""}`,
      type: "text",
      name: "email"
    }
  ), dirty && touched.email && errors.email && /* @__PURE__ */ React.createElement("p", { className: "error-message" }, errors.email)), /* @__PURE__ */ React.createElement(
    "button",
    {
      type: "submit",
      disabled: !dirty,
      className: "settings-panel-submit-button"
    },
    isSubmitting ? "Updating email..." : "Update email"
  )))
);
export default Email;
