import React, { Component } from "react";
import {
  Form as OriginalForm,
  Field as OriginalField,
  FieldArray as OriginalFieldArray,
  Formik as OriginalFormik
} from "formik";
export const makeSubmitAction = (method) => {
  return (values, actions) => {
    method({ values, actions });
  };
};
export const Form = OriginalForm;
export const Field = OriginalField;
export const FieldArray = OriginalFieldArray;
export class Formik extends Component {
  render() {
    return /* @__PURE__ */ React.createElement(
      OriginalFormik,
      {
        onSubmit: makeSubmitAction(this.props.submitAction),
        ...this.props
      }
    );
  }
}
