import React, { Component } from "react";
const defaultProps = {
  expanded: false
};
const iconPaths = {
  array: "M3.25 13 7 13 7 11.5 4.75 11.5 4.75 4.5 7 4.5 7 3 3.25 3 3.25 13 M9 3 9 4.5 11.25 4.5 11.25 11.5 9 11.5 9 13 12.75 13 12.75 3 9 3z",
  "array-expanded": "M9.01 4.5 9.01 3 5.26 3 5.26 13 9.01 13 9.01 11.5 6.76 11.5 6.76 4.5 9.01 4.5z",
  boolean: "M11.38 10.27 11.38 4.79 9.88 4.79 9.88 10.27 7.92 10.27 7.92 11.77 13.33 11.77 13.33 10.27 11.38 10.27 M8.19 4.73 2.78 4.73 2.78 6.23 4.74 6.23 4.74 11.71 6.24 11.71 6.24 6.23 8.19 6.23 8.19 4.73z",
  duration: "M11,4.32a4.57,4.57,0,0,0-1-.62L9.36,5.06a3.07,3.07,0,0,1,.69.43 M10.85,6.45a2.41,2.41,0,0,1,.15.33l1.39-.57a3.49,3.49,0,0,0-.23-.48,5.07,5.07,0,0,0-.34-.55l-1.21.89C10.69,6.19,10.77,6.31,10.85,6.45 M12.7,7.36l-1.49.2a3.37,3.37,0,0,1,0,.44,2.36,2.36,0,0,1,0,.37l1.49.18a5.12,5.12,0,0,0,0-.56A4.44,4.44,0,0,0,12.7,7.36 M11,9.16a3.48,3.48,0,0,1-.35.66,3.37,3.37,0,0,1-.62.68,3.58,3.58,0,0,1-.45.31A3.28,3.28,0,0,1,8,11.24,3.23,3.23,0,0,1,5.11,9.43,2.82,2.82,0,0,1,4.92,9,3,3,0,0,1,4.76,8V8a4,4,0,0,1,.09-.69,3.25,3.25,0,0,1,.38-.92,3.19,3.19,0,0,1,.3-.42,3.26,3.26,0,0,1,1.08-.83L6.86,5a2.9,2.9,0,0,1,1-.21,3.35,3.35,0,0,1,.71,0l.27-1.47A4.11,4.11,0,0,0,8,3.27H7.91a1.76,1.76,0,0,0-.23,0v0a4.88,4.88,0,0,0-1.15.22v0l-.19.09c-.13,0-.25.1-.37.15s-.15.06-.23.11L5.45,4h0a4.43,4.43,0,0,0-.91.76h0a4,4,0,0,0-.44.56.61.61,0,0,0-.07.1c-.06.1-.13.19-.19.3h0a4.53,4.53,0,0,0-.44,1.09h0a.8.8,0,0,0,0,.15,1.16,1.16,0,0,1,0,.18A4.78,4.78,0,0,0,3.26,8h0a4.45,4.45,0,0,0,.16,1.16h0a4.26,4.26,0,0,0,.22.63l.06.13c0,.11.1.23.16.34h0a4.71,4.71,0,0,0,6.41,1.9h0l.09-.05a5.47,5.47,0,0,0,.63-.44.24.24,0,0,0,.08-.06h0a4.73,4.73,0,0,0,.79-.88h0l.12-.19.13-.21a4.11,4.11,0,0,0,.21-.42c0-.05.05-.09.07-.14h0l0-.09 M6.85,6.33V8.75l.63.55,2.87-.42a3,3,0,0,0,.1-1.12L8,8.12V6.33z",
  function: "M7.13,4.92a.4.4,0,0,1,.46-.32.38.38,0,0,1,.26.16.45.45,0,0,1,.05.36l0,.47H9.37l0-.27a1.91,1.91,0,0,0-1.53-2.2,1.89,1.89,0,0,0-2.2,1.53L5.27,6.8H4L3.75,8.3H5l-.51,2.79A.39.39,0,0,1,4,11.4a.35.35,0,0,1-.21-.11L2.75,12.34a1.86,1.86,0,0,0,1,.54l.34,0A1.9,1.9,0,0,0,6,11.35l.55-3H7.68L8,6.8H6.79 M13 7.74 12.24 6.98 11.63 6.98 10.67 7.94 9.71 6.98 9.1 6.98 8.35 7.74 9.61 9 8.35 10.27 9.16 11.09 9.65 11.09 10.67 10.06 11.7 11.09 12.18 11.09 13 10.27 11.73 9 13 7.74z",
  iterable: "M10.09,3.08H9.34v1.5h.75c.43,0,1.41,0,1.41,3.42s-1,3.42-1.41,3.42H9.34v1.5h.75C13,12.92,13,9.22,13,8S13,3.08,10.09,3.08 M3,8c0,1.22,0,4.92,2.91,4.92h.75v-1.5H5.92c-.42,0-1.41,0-1.41-3.42s1-3.42,1.41-3.42h.75V3.08H5.92C3,3.08,3,6.78,3,8z",
  "iterable-expanded": "M8.31,4.58h.75V3.08H8.31c-2.9,0-2.9,3.7-2.9,4.92s0,4.92,2.9,4.92h.75v-1.5H8.31c-.42,0-1.4,0-1.4-3.42S7.89,4.58,8.31,4.58z",
  "iterable-like": "M10.09,3.08H9.34v1.5h.75c.43,0,1.41,0,1.41,3.42s-1,3.42-1.41,3.42H9.34v1.5h.75C13,12.92,13,9.22,13,8S13,3.08,10.09,3.08 M3,8c0,1.22,0,4.92,2.91,4.92h.75v-1.5H5.92c-.42,0-1.41,0-1.41-3.42s1-3.42,1.41-3.42h.75V3.08H5.92C3,3.08,3,6.78,3,8z",
  "iterable-like-expanded": "M8.31,4.58h.75V3.08H8.31c-2.9,0-2.9,3.7-2.9,4.92s0,4.92,2.9,4.92h.75v-1.5H8.31c-.42,0-1.4,0-1.4-3.42S7.89,4.58,8.31,4.58z",
  link: "M5.58 4.37 5.58 5.87 8.97 5.87 3.77 11.06 4.94 12.23 10.13 7.03 10.13 10.42 11.63 10.42 11.63 4.37 5.58 4.37z",
  null: "M13,4.09,11.91,3,10.23,4.71A3.94,3.94,0,0,0,8,4,4,4,0,0,0,4,8a3.93,3.93,0,0,0,.69,2.24L3,11.91,4.09,13l1.66-1.66A3.93,3.93,0,0,0,8,12a4,4,0,0,0,4-4,3.94,3.94,0,0,0-.7-2.24ZM5.5,8A2.5,2.5,0,0,1,8,5.51a2.53,2.53,0,0,1,1.14.29L5.79,9.15A2.44,2.44,0,0,1,5.5,8ZM8,10.5a2.44,2.44,0,0,1-1.14-.29L10.2,6.87A2.53,2.53,0,0,1,10.49,8,2.5,2.5,0,0,1,8,10.5z",
  number: "M11.59,3l-.38,2.25H13v1.5H11l-.42,2.5H13v1.5H10.29L9.92,13H8.4l.37-2.25H6.29L5.92,13H4.4l.37-2.25H3V9.25H5l.42-2.5H3V5.25H5.69L6.07,3H7.59L7.21,5.25H9.69L10.07,3ZM9.44,6.75H7l-.42,2.5H9z",
  object: "M12.94,7.25a.76.76,0,0,1-.75-.75V5.12a2.13,2.13,0,0,0,0-.25A2.11,2.11,0,0,0,10.07,3H9V4.5h1.18a.53.53,0,0,1,.53.53V6.4c0,.26.17,1.07,1.54,1.43v.34c-1.37.36-1.53,1.17-1.54,1.43V11a.53.53,0,0,1-.53.53H9V13h1.09a2.11,2.11,0,0,0,2.09-1.87,2.13,2.13,0,0,0,0-.25V9.5a.76.76,0,0,1,.75-.75h.83V7.25 M3.82,4.87a2.13,2.13,0,0,0,0,.25V6.5A.75.75,0,0,1,3,7.25H2.21v1.5H3a.75.75,0,0,1,.75.75v1.38a2.13,2.13,0,0,0,0,.25A2.12,2.12,0,0,0,5.92,13H7V11.5H5.83A.54.54,0,0,1,5.29,11V9.6c0-.26-.17-1.07-1.54-1.43V7.83C5.12,7.47,5.28,6.66,5.29,6.4V5a.54.54,0,0,1,.54-.53H7V3H5.92A2.12,2.12,0,0,0,3.82,4.87z",
  "object-expanded": "M4.61,4.87a2.13,2.13,0,0,0,0,.25V6.5a.75.75,0,0,1-.75.75H3v1.5h.83a.75.75,0,0,1,.75.75v1.38a2.13,2.13,0,0,0,0,.25A2.12,2.12,0,0,0,6.71,13H7.79V11.5H6.61A.53.53,0,0,1,6.08,11V9.6c0-.26-.17-1.07-1.54-1.43V7.83C5.91,7.47,6.07,6.66,6.08,6.4V5a.53.53,0,0,1,.53-.53H7.79V3H6.71A2.12,2.12,0,0,0,4.61,4.87z",
  "status-canceled": "M11.85 5.21 10.79 4.15 8 6.94 5.21 4.15 4.15 5.21 6.94 8 4.15 10.79 5.21 11.85 8 9.06 10.79 11.85 11.85 10.79 9.06 8 11.85 5.21z",
  "status-completed": "M11.97 3.92 6.07 9.81 4.94 7.45 3.59 8.09 5.63 12.38 13.03 4.98 11.97 3.92z",
  "status-errored": "M6.97,11.97a1.03,1.03 0 1,0 2.06,0a1.03,1.03 0 1,0 -2.06,0 M8.75 9.71 8.95 6.73 8.95 3.15 7.05 3.15 7.05 6.73 7.25 9.71 8.75 9.71z",
  "status-pending": "M10.32,8a1.02,1.02 0 1,0 2.04,0a1.02,1.02 0 1,0 -2.04,0 M6.98,8a1.02,1.02 0 1,0 2.04,0a1.02,1.02 0 1,0 -2.04,0 M3.64,8a1.02,1.02 0 1,0 2.04,0a1.02,1.02 0 1,0 -2.04,0z",
  "status-running": "M6,5.6l-1-1.16A4.68,4.68,0,0,0,4,5.81l1.32.72A3.11,3.11,0,0,1,6,5.6 M3.41,8.11a4.54,4.54,0,0,0,.38,1.74l1.37-.61a2.93,2.93,0,0,1-.25-1.17 M12.17,6.06a4.69,4.69,0,0,0-1.05-1.44l-1,1.1a3.19,3.19,0,0,1,.71,1 M8.83,11l.4,1.45a4.5,4.5,0,0,0,1.59-.8L9.9,10.44A2.93,2.93,0,0,1,8.83,11 M5.26,11.69a4.69,4.69,0,0,0,1.61.77L7.24,11a3.07,3.07,0,0,1-1.08-.51 M11.1,8a3.18,3.18,0,0,1-.23,1.18l1.38.57A4.59,4.59,0,0,0,12.6,8 M7.25,3.46,7.49,5a3,3,0,0,1,1.2,0L9,3.52A4.6,4.6,0,0,0,7.25,3.46z",
  string: "M5.52,8.17V7.1a2.18,2.18,0,0,1,.42-1.45,1.59,1.59,0,0,1,1.21-.42V3.73a3,3,0,0,0-2.32.92A3.59,3.59,0,0,0,4,7.17v5H7.35v-4H5.52 M11.5,8.17H10.25V7.1a2.18,2.18,0,0,1,.42-1.45,1.59,1.59,0,0,1,1.21-.42V3.73a3,3,0,0,0-2.32.92,3.59,3.59,0,0,0-.81,2.52v5h3.33v-4z",
  symbol: "M13,6.76l-3.43-.5L8,3.16,6.47,6.26,3,6.76,5.52,9.17l-.58,3.41L8,11l3.06,1.61-.58-3.41ZM9.47,10.39,8,9.62l-1.47.77.28-1.64L5.62,7.6l1.65-.24L8,5.87l.73,1.49,1.65.24L9.19,8.75z",
  timestamp: "M8.75 4.38h1.5v1.5h-1.5zM7.25 2.88h1.5v1.5h-1.5zM10.25 2.88h1.5v1.5h-1.5zM4.25 2.88h1.5v1.5h-1.5 M11.75 4.38v7h-7.5v-7h-1.5v8.5h10.5v-8.5h-1.5 M5.75 4.38h1.5v1.5h-1.5zM5.75 7.38h1.5v2.5h-1.5zM8.75 7.38h1.5v2.5h-1.5z",
  undefined: "M8 3.19A2.79 2.79 0 0 0 5.22 6v.75h1.5V6A1.28 1.28 0 1 1 8 7.25a.76.76 0 0 0-.75.75v2.19h1.5V8.65A2.78 2.78 0 0 0 8 3.19 M7.12,12a0.88,0.88 0 1,0 1.76,0a0.88,0.88 0 1,0 -1.76,0z"
};
class NodeIcon extends Component {
  render() {
    const icon = this.props.expanded ? `${this.props.nodeType.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase()}-expanded` : this.props.nodeType.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
    return /* @__PURE__ */ React.createElement("span", { className: `NodeIcon -${icon}` }, /* @__PURE__ */ React.createElement("svg", { className: "icon-svg", viewBox: "0 0 16 16" }, /* @__PURE__ */ React.createElement("title", null, icon), /* @__PURE__ */ React.createElement("path", { d: iconPaths[icon] })));
  }
}
NodeIcon.defaultProps = defaultProps;
export default NodeIcon;
