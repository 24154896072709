import React, { useState } from "react";
import JsonTree from "./JsonTree/JsonTree";
import AceEditor from "react-ace";
import Settings from "./ConsoleSettings";
import SimulationProgressBar from "./SimulationProgressBar";
import { Histogram } from "../Histogram";
import StdoutViewer from "../Jobs/StdoutViewer";
import "./Console.css";
import "ace-builds/src-noconflict/mode-json";
import "../../lib/theme-ionq";
const Console = (props) => {
  let {
    consoleData,
    autoRun,
    job,
    simulating,
    simulationStep,
    simulationTotalSteps,
    compileError
  } = props;
  const [viewMode, setViewMode] = useState("explorer");
  consoleData = consoleData || job || {};
  const hasOutput = true;
  const cantCompile = !!compileError;
  const notInBrowser = compileError && compileError.indexOf("no import allowed") > -1;
  const consoleHeight = props.consoleHeight ? props.consoleHeight : null;
  const prettyPrintJSON = (raw) => {
    const stringified = JSON.stringify(raw, null, "    ");
    return stringified.substring(2, stringified.length - 2);
  };
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      className: "Console",
      style: {
        height: props.consoleHeight,
        marginBottom: props.removeBottomPadding ? 0 : null
      }
    },
    /* @__PURE__ */ React.createElement("div", null, !hasOutput && /* @__PURE__ */ React.createElement("div", { className: "no-output-message" }, "no output"), hasOutput && /* @__PURE__ */ React.createElement("div", null, cantCompile && autoRun && notInBrowser && /* @__PURE__ */ React.createElement("div", { className: "no-output-message" }, "Circuit cannot be simulated in browser."), cantCompile && autoRun && !notInBrowser && /* @__PURE__ */ React.createElement("div", { className: "no-output-message" }, "Circuit could not be simulated due to the following error:", /* @__PURE__ */ React.createElement("p", { className: "no-output-error-message" }, compileError)), (!autoRun || !cantCompile) && viewMode === "histogram" && /* @__PURE__ */ React.createElement(
      "div",
      {
        className: "histogram-view-container",
        style: { height: consoleHeight }
      },
      /* @__PURE__ */ React.createElement(
        Histogram,
        {
          histogram: consoleData.histogram,
          qubitCount: consoleData.qubitCount
        }
      )
    ), (!autoRun || !cantCompile) && viewMode === "console" && /* @__PURE__ */ React.createElement(
      "div",
      {
        className: "console-scroll-container",
        style: { height: consoleHeight }
      },
      /* @__PURE__ */ React.createElement(
        StdoutViewer,
        {
          output: consoleData.console
        }
      )
    ), (!autoRun || !cantCompile) && viewMode === "explorer" && /* @__PURE__ */ React.createElement(
      "div",
      {
        className: "json-tree-scroll-container",
        style: { height: consoleHeight }
      },
      /* @__PURE__ */ React.createElement(JsonTree, { data: consoleData })
    ), (!autoRun || !cantCompile) && viewMode === "JSON" && /* @__PURE__ */ React.createElement(
      "div",
      {
        className: "json-view-container",
        style: { height: consoleHeight }
      },
      /* @__PURE__ */ React.createElement(
        AceEditor,
        {
          setOptions: { useWorker: false },
          mode: "json",
          theme: "ionq",
          readOnly: true,
          showGutter: false,
          showPrintMargin: false,
          fontSize: 16,
          lineHeight: 1.625,
          ref: () => {
          },
          name: "JSONRawView",
          value: prettyPrintJSON(consoleData),
          editorProps: {
            $blockScrolling: true
          },
          wrapEnabled: true,
          key: props.consoleHeight + props.consoleWidth,
          maxLines: Infinity,
          style: {
            lineHeight: "1.625",
            width: props.consoleWidth ? props.consoleWidth - 10 : "100%",
            height: consoleHeight,
            fontFamily: "Ubuntu-Mono"
            //apparently necessary so it calculates sizes correctly on first load
          }
        }
      )
    ))),
    /* @__PURE__ */ React.createElement(
      Settings,
      {
        consoleData,
        autoRun,
        viewMode,
        setViewMode
      }
    ),
    /* @__PURE__ */ React.createElement(
      SimulationProgressBar,
      {
        simulating: simulationTotalSteps > 0 && simulating,
        simulationStep,
        simulationTotalSteps
      }
    )
  );
};
export default Console;
