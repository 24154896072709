import React, { useState } from "react";
import { Button, Flex, Text, useToast } from "@chakra-ui/react";
import { useBaseModal } from "../../shared/baseModals/useBaseModal";
import { MODALS } from "../../shared/baseModals/constants";
import { BaseModal, BaseModalBody } from "../../shared/Modal";
import { IonToast } from "../../shared/IonToast";
export const RemoveUserFromProjectModal = ({ removeProjectMember }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const toast = useToast();
  const toastIdRef = React.useRef();
  const { isOpen, close, props } = useBaseModal(MODALS.removeMemberFromProject);
  const handleCloseModal = () => {
    setSubmitError(null);
    setIsSubmitting(false);
    close();
  };
  const handleSubmit = () => {
    setIsSubmitting(true);
    removeProjectMember({
      userId: props.userId,
      onRemoveSuccess: () => {
        setIsSubmitting(false);
        handleCloseModal();
        toastIdRef.current = toast({
          duration: 5e3,
          position: "bottom-left",
          render: () => /* @__PURE__ */ React.createElement(
            IonToast,
            {
              message: `${props?.userName} was successfully deleted from ${props?.projectName}`,
              status: "success",
              toast,
              toastIdRef
            }
          )
        });
      },
      onRemoveError: (error) => {
        setIsSubmitting(false);
        setSubmitError(error);
      }
    });
  };
  return /* @__PURE__ */ React.createElement(
    BaseModal,
    {
      title: "Remove from project",
      isOpen,
      close: handleCloseModal,
      hideClose: true
    },
    /* @__PURE__ */ React.createElement(BaseModalBody, { errorBannerContent: submitError }, /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3" }, `Are you sure you want to remove ${props?.userName} from the project?`), /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3" }, "When you remove a project member from the project, their access to the project will be immediately revoked and they won\u2019t be able to submit jobs."), /* @__PURE__ */ React.createElement(Flex, { justifyContent: "end", m: "24px 0" }, /* @__PURE__ */ React.createElement(
      Button,
      {
        variant: "white",
        size: "lg",
        onClick: handleCloseModal,
        mr: "12px"
      },
      "Cancel"
    ), /* @__PURE__ */ React.createElement(
      Button,
      {
        variant: "red",
        size: "lg",
        onClick: handleSubmit,
        isLoading: isSubmitting
      },
      "I understand, remove this member"
    )))
  );
};
