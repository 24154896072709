import React from "react";
import "./StdoutViewer.css";
const StdoutViewer = (props) => {
  return /* @__PURE__ */ React.createElement("div", { className: "StdoutViewer" }, /* @__PURE__ */ React.createElement("div", { className: "console" }, props.output && props.output.length > 0 && props.output.map((line) => {
    return Object.keys(line).map((key, i) => {
      if (key.toLowerCase() === "stderr") {
        return /* @__PURE__ */ React.createElement(
          "p",
          {
            className: "stderr",
            key: `${line[key]}-${i}`
          },
          line[key]
        );
      } else {
        return /* @__PURE__ */ React.createElement(
          "p",
          {
            className: "stdout",
            key: `${line[key]}-${i}`
          },
          line[key]
        );
      }
    });
  }), (props.output || []).length === 0 && /* @__PURE__ */ React.createElement("p", { className: "null" }, "no output")));
};
export default StdoutViewer;
