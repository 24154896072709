import { useCallback, useEffect, useState } from "react";
import { fetchProjects, postCreateProject, postUpdateProject } from "./utils";
const FETCH_PROJECTS_FAILED = "Could not fetch projects. Please try again later.";
const UPDATE_PROJECT_FAILED = "Could not update projects. Please try again later.";
const CREATE_PROJECT_FAILED_ERROR_MAP = {
  bad_request_project_budget_exceeds_organization_budget: "Cannot create a project with project budget exceeding organization quota."
};
const CREATE_PROJECT_FAILED_DEFAULT = "Could not create new projects. Please try again later.";
export const useProjects = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [error, setError] = useState(null);
  useEffect(() => {
    fetchProjects({
      onSuccess: (projects2) => {
        setProjects(projects2);
        setIsLoading(false);
      },
      onError: () => {
        setError(FETCH_PROJECTS_FAILED);
        setIsLoading(false);
      }
    });
  }, []);
  const updateProject = useCallback(
    ({ projectId, updatedFields, onUpdateSuccess, onUpdateError }) => {
      postUpdateProject({
        id: projectId,
        updatedFields,
        onSuccess: () => {
          const updatedProjects = projects.map((project) => {
            if (project.id === projectId) {
              return {
                ...project,
                ...updatedFields
              };
            }
            return project;
          });
          setProjects(updatedProjects);
          onUpdateSuccess();
        },
        onError: () => {
          onUpdateError(UPDATE_PROJECT_FAILED);
        }
      });
    },
    [projects]
  );
  const createProject = useCallback(
    ({
      name,
      description,
      quotaLimitUsd,
      onCreateSuccess,
      onCreateError
    }) => {
      postCreateProject({
        content: {
          name,
          description: description.trim() || void 0,
          quotaLimitUsd
        },
        onSuccess: (newProject) => {
          setProjects([...projects, newProject]);
          onCreateSuccess(newProject);
        },
        onError: (e) => {
          const errMsg = CREATE_PROJECT_FAILED_ERROR_MAP[e.message] ?? CREATE_PROJECT_FAILED_DEFAULT;
          onCreateError(errMsg);
        }
      });
    },
    [projects]
  );
  return {
    updateProject,
    createProject,
    projects,
    error,
    isLoading
  };
};
