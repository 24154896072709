import React from "react";
import PropTypes from "prop-types";
import JSONNode from "./JSONNode";
import "./JsonTree.css";
const identity = (value) => value;
const expandRootNode = (keyName, data, level) => level === 0;
const defaultItemString = (type, data, itemType, itemString) => /* @__PURE__ */ React.createElement("span", null, itemString);
const defaultLabelRenderer = ([label]) => `${label}:`;
const noCustomNode = () => false;
export default class JSONTree extends React.Component {
  static propTypes = {
    data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    hideRoot: PropTypes.bool,
    theme: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    keyPath: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
    postprocessValue: PropTypes.func,
    sortObjectKeys: PropTypes.oneOfType([PropTypes.func, PropTypes.bool])
  };
  static defaultProps = {
    shouldExpandNode: expandRootNode,
    hideRoot: true,
    keyPath: ["root"],
    getItemString: defaultItemString,
    labelRenderer: defaultLabelRenderer,
    valueRenderer: identity,
    postprocessValue: identity,
    isCustomNode: noCustomNode,
    collectionLimit: 50
  };
  shouldComponentUpdate(nextProps) {
    return !!Object.keys(nextProps).find(
      (k) => k === "keyPath" ? nextProps[k].join("/") !== this.props[k].join("/") : nextProps[k] !== this.props[k]
    );
  }
  render() {
    const {
      data: value,
      keyPath,
      postprocessValue,
      hideRoot,
      ...rest
    } = this.props;
    return /* @__PURE__ */ React.createElement("ul", { className: "JsonTree" }, /* @__PURE__ */ React.createElement(
      JSONNode,
      {
        ...{ postprocessValue, hideRoot, ...rest },
        keyPath: hideRoot ? [] : keyPath,
        value: postprocessValue(value)
      }
    ));
  }
}
