import React from "react";
import PropTypes from "prop-types";
import JSONArrow from "./JSONArrow";
export default class ItemRange extends React.Component {
  static propTypes = {
    styling: PropTypes.func.isRequired,
    from: PropTypes.number.isRequired,
    to: PropTypes.number.isRequired,
    renderChildNodes: PropTypes.func.isRequired,
    nodeType: PropTypes.string.isRequired
  };
  constructor(props) {
    super(props);
    this.state = { expanded: false };
    this.handleClick = this.handleClick.bind(this);
  }
  render() {
    const { from, to, renderChildNodes, nodeType } = this.props;
    return this.state.expanded ? /* @__PURE__ */ React.createElement("div", null, renderChildNodes(this.props, from, to)) : /* @__PURE__ */ React.createElement("div", { className: "itemRange", onClick: this.handleClick }, /* @__PURE__ */ React.createElement(
      JSONArrow,
      {
        nodeType,
        expanded: false,
        onClick: this.handleClick,
        arrowStyle: "double"
      }
    ), `${from} ... ${to}`);
  }
  handleClick() {
    this.setState({ expanded: !this.state.expanded });
  }
}
