import React from "react";
export const drawTrace = (qubit, qubitsUsed, width, height, color, measurements, i) => {
  const traceHorizontalOffset = width - measurements.wireWidth / 2 - measurements.wireWidth * i;
  const gapWidth = 10;
  const traceLength = 5;
  const traceStyle = color ? { stroke: color } : {};
  return /* @__PURE__ */ React.createElement(
    "g",
    {
      key: qubit,
      className: "sequence-trace",
      transform: `translate(${traceHorizontalOffset},0)`
    },
    qubitsUsed.includes(qubit) ? /* @__PURE__ */ React.createElement("g", { className: "top-trace" }, /* @__PURE__ */ React.createElement(
      "line",
      {
        className: "trace-line",
        x1: -measurements.wireWidth / 2,
        x2: measurements.wireWidth / 2,
        y1: "0",
        y2: "0",
        style: traceStyle
      }
    ), /* @__PURE__ */ React.createElement(
      "line",
      {
        className: "trace-line",
        x1: "0",
        x2: "0",
        y1: "0",
        y2: -traceLength,
        style: traceStyle
      }
    )) : /* @__PURE__ */ React.createElement("g", { className: "top-trace" }, /* @__PURE__ */ React.createElement(
      "line",
      {
        className: "trace-line",
        x1: -measurements.wireWidth / 2,
        x2: -measurements.wireWidth / 2 + measurements.wireWidth / 2 - gapWidth / 2,
        y1: "0",
        y2: "0",
        style: traceStyle
      }
    ), /* @__PURE__ */ React.createElement(
      "line",
      {
        className: "trace-line",
        x1: measurements.wireWidth / 2,
        x2: measurements.wireWidth / 2 - measurements.wireWidth / 2 + gapWidth / 2,
        y1: "0",
        y2: "0",
        style: traceStyle
      }
    )),
    qubitsUsed.includes(qubit) ? /* @__PURE__ */ React.createElement("g", { className: "bottom-trace" }, /* @__PURE__ */ React.createElement(
      "line",
      {
        className: "trace-line",
        x1: -measurements.wireWidth / 2,
        x2: measurements.wireWidth / 2,
        y1: height,
        y2: height,
        style: traceStyle
      }
    ), /* @__PURE__ */ React.createElement(
      "line",
      {
        className: "trace-line",
        x1: "0",
        x2: "0",
        y1: height,
        y2: height + traceLength,
        style: traceStyle
      }
    )) : /* @__PURE__ */ React.createElement("g", { className: "bottom-trace" }, /* @__PURE__ */ React.createElement(
      "line",
      {
        className: "trace-line",
        x1: -measurements.wireWidth / 2,
        x2: -measurements.wireWidth / 2 + measurements.wireWidth / 2 - gapWidth / 2,
        y1: height,
        y2: height,
        style: traceStyle
      }
    ), /* @__PURE__ */ React.createElement(
      "line",
      {
        className: "trace-line",
        x1: measurements.wireWidth / 2,
        x2: measurements.wireWidth / 2 - measurements.wireWidth / 2 + gapWidth / 2,
        y1: height,
        y2: height,
        style: traceStyle
      }
    ))
  );
};
