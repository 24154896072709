import React from "react";
export const IonVisualizerLabelRow = ({
  height,
  verticalOffset,
  measurements,
  ops,
  svgWidth,
  color,
  parentQubitsUsed
}) => {
  const max = Math.max(...parentQubitsUsed);
  const min = Math.min(...parentQubitsUsed);
  const labelWidth = measurements.wireWidth * (max - min) + measurements.wireWidth;
  const labelXOffset = svgWidth - measurements.wireWidth * max - measurements.wireWidth;
  const labelStartLocation = verticalOffset + height / 2;
  const label = ops[0].label;
  const labelLines = label.text.length;
  color = label.color ? label.color : color;
  return /* @__PURE__ */ React.createElement("g", { transform: `translate(0,${labelStartLocation})` }, /* @__PURE__ */ React.createElement(
    "rect",
    {
      className: "label-background",
      style: { fill: color, opacity: 0.1 },
      x: labelXOffset,
      y: -(measurements.labelYPadding * 2 + measurements.labelLineHeight * labelLines) / 2,
      width: labelWidth,
      height: measurements.labelLineHeight * labelLines + measurements.labelYPadding * 2
    }
  ), /* @__PURE__ */ React.createElement(
    "g",
    {
      transform: `translate(0,${measurements.labelYPadding - measurements.labelLineHeight * labelLines / 2 + 2})`
    },
    label.text.map((line, i) => {
      return /* @__PURE__ */ React.createElement(
        "text",
        {
          key: i,
          className: "label-text",
          x: labelXOffset + measurements.labelXPadding,
          y: measurements.labelLineHeight * i,
          style: { fill: color }
        },
        line
      );
    })
  ));
};
