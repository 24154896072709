import React, { Component } from "react";
import "./SimulationProgressBar.css";
class SimulationProgressBar extends Component {
  render() {
    const { simulating, simulationStep, simulationTotalSteps } = this.props;
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        className: `SimulationProgressBar ${simulating ? "-active" : "-inactive"}`
      },
      /* @__PURE__ */ React.createElement(
        "div",
        {
          className: "bar",
          style: {
            width: `${simulationStep / simulationTotalSteps * 100}%`,
            "--transition-speed": `${1 / simulationTotalSteps * 5 > 0.3 ? 0.3 : 1 / simulationTotalSteps * 5}s`
          }
        }
      )
    );
  }
}
export default SimulationProgressBar;
