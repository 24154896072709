import React from "react";
import { Checkmark, Alert } from "../shared/Icons";
import styles from "./StatusIcon.module.scss";
export const StatusIcon = ({ variant }) => {
  switch (variant) {
    case "SUCCESS":
      return /* @__PURE__ */ React.createElement(Checkmark, { className: styles.success });
    case "WARNING":
      return /* @__PURE__ */ React.createElement(Alert, { className: styles.warning });
    default:
      return null;
  }
};
