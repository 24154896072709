import React from "react";
import PropTypes from "prop-types";
import { Text } from "@chakra-ui/react";
import { MdCircle } from "react-icons/md";
import { Icon } from "@chakra-ui/icons";
const statuses = {
  available: "Available",
  unavailable: "Unavailable"
};
function SystemStatus(props) {
  const status = props.status || "unavailable";
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    Icon,
    {
      as: MdCircle,
      boxSize: "9px",
      mb: "1px",
      color: status === "available" ? "uiGreen" : "uiRed"
    }
  ), /* @__PURE__ */ React.createElement(
    Text,
    {
      as: "span",
      ml: 1,
      textStyle: "body-8",
      color: "gray.600",
      className: "system-status-text"
    },
    statuses[status]
  ));
}
SystemStatus.propTypes = {
  status: PropTypes.oneOf(["available", "unavailable"])
};
export default SystemStatus;
