import { Text } from "@chakra-ui/react";
import React from "react";
import { IonTooltip } from "../IonTooltip/index.js";
const MAX_NAME_LENGTH = 70;
export const NameCell = ({ name }) => {
  const element = /* @__PURE__ */ React.createElement(
    Text,
    {
      as: "span",
      textStyle: "body-table-name",
      color: "ionDark",
      noOfLines: 2,
      wordBreak: "break-word",
      maxW: "456px",
      minW: "190px"
    },
    name
  );
  return name?.length > MAX_NAME_LENGTH ? /* @__PURE__ */ React.createElement(IonTooltip, { label: name }, element) : element;
};
