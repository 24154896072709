import React from "react";
const HistogramLegend = ({ shotCount, measurements }) => {
  const legendText = shotCount + " shots";
  return /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement(
    "rect",
    {
      className: "legend-background",
      x: measurements.horizontalPadding,
      y: measurements.baselineYValue - measurements.graphHeight - measurements.labelTextHeight / 2 - 2,
      width: legendText.length * measurements.labelLetterWidth,
      height: measurements.labelTextHeight + 5
    }
  ), /* @__PURE__ */ React.createElement(
    "text",
    {
      className: "legend",
      x: measurements.horizontalPadding,
      y: measurements.baselineYValue - measurements.graphHeight + measurements.labelTextHeight / 2 - 2
    },
    legendText
  ));
};
export default HistogramLegend;
