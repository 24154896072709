import React from "react";
import { IonVisualizerGate } from "./IonVisualizerGate";
export const IonVisualizerGateRow = ({
  verticalOffset,
  ops,
  measurements,
  svgWidth,
  isHorizontal,
  color
}) => {
  return /* @__PURE__ */ React.createElement("g", { transform: `translate(0,${verticalOffset})` }, ops.map((gate, i) => {
    return /* @__PURE__ */ React.createElement(
      IonVisualizerGate,
      {
        key: i,
        gate: gate.gate,
        measurements,
        svgWidth,
        sequenceColor: color,
        isHorizontal
      }
    );
  }));
};
