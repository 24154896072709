import React, { Fragment } from "react";
import { useFormik } from "formik";
import isCidr from "is-cidr";
import { isIP } from "is-ip";
import "./Allowlist.scss";
const AllowlistListItem = ({ ip, onRemoveIp }) => {
  const formik = useFormik({
    initialValues: { ip },
    onSubmit: onRemoveIp
  });
  return /* @__PURE__ */ React.createElement("div", { className: "ips-table-row" }, /* @__PURE__ */ React.createElement("div", { className: "ip-column" }, ip), /* @__PURE__ */ React.createElement("div", { className: "actions-column" }, /* @__PURE__ */ React.createElement("form", { onSubmit: formik.handleSubmit }, /* @__PURE__ */ React.createElement("button", { className: "remove-ip-icon", type: "submit" }, /* @__PURE__ */ React.createElement(
    "svg",
    {
      fill: "#000000",
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 24 24",
      width: "24px",
      height: "24px"
    },
    /* @__PURE__ */ React.createElement("path", { d: "M 10 2 L 9 3 L 4 3 L 4 5 L 5 5 L 5 20 C 5 20.522222 5.1913289 21.05461 5.5683594 21.431641 C 5.9453899 21.808671 6.4777778 22 7 22 L 17 22 C 17.522222 22 18.05461 21.808671 18.431641 21.431641 C 18.808671 21.05461 19 20.522222 19 20 L 19 5 L 20 5 L 20 3 L 15 3 L 14 2 L 10 2 z M 7 5 L 17 5 L 17 20 L 7 20 L 7 5 z M 9 7 L 9 18 L 11 18 L 11 7 L 9 7 z M 13 7 L 13 18 L 15 18 L 15 7 L 13 7 z" })
  )))));
};
const Allowlist = (props) => {
  const { list, onUpdateUserIpWhitelist } = props;
  const removeIpFromWhitelist = (values, actions) => {
    onUpdateUserIpWhitelist({
      values: { ipAllowlist: list.filter((v) => v !== values.ip) },
      actions
    });
  };
  const formik = useFormik({
    initialValues: { ip: "" },
    validate: (values) => {
      const errors = {};
      const isValidAddr = [isIP, isCidr].map((f) => f(values.ip)).some((v) => !!v);
      if (!isValidAddr) errors.ip = "Invalid IP address";
      return errors;
    },
    onSubmit: (values, actions) => {
      onUpdateUserIpWhitelist({
        values: { ipAllowlist: [...list, values.ip] },
        actions
      });
    },
    validateOnChange: false
  });
  return /* @__PURE__ */ React.createElement("div", { className: "Allowlist" }, /* @__PURE__ */ React.createElement("div", { className: "ips-table" }, list?.length === 0 && /* @__PURE__ */ React.createElement("p", { className: "message-no-ips" }, "There are no IP addresses on the allow list"), list?.length > 0 && /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "ips-table-head" }, /* @__PURE__ */ React.createElement("div", { className: "ip-column" }, "IP Address / Range")), list.map((ip) => /* @__PURE__ */ React.createElement(
    AllowlistListItem,
    {
      key: ip,
      ip,
      onRemoveIp: removeIpFromWhitelist
    }
  ))), /* @__PURE__ */ React.createElement("form", { onSubmit: formik.handleSubmit }, /* @__PURE__ */ React.createElement("div", { className: "ips-table-row -actions" }, /* @__PURE__ */ React.createElement("div", { className: "ip-column" }, /* @__PURE__ */ React.createElement(
    "input",
    {
      name: "ip",
      className: "text-input",
      type: "text",
      onChange: formik.handleChange,
      value: formik.values.ip,
      placeholder: "IP or CIDR range. e.g.: 54.13.21.1 or 10.10.10.0/24"
    }
  ), formik.errors.ip && /* @__PURE__ */ React.createElement("div", { className: "ip-error-messsage" }, formik.errors.ip)), /* @__PURE__ */ React.createElement("div", { className: "actions-column" }, /* @__PURE__ */ React.createElement("button", { className: "add-button", type: "submit" }, "Add"))))));
};
export default Allowlist;
