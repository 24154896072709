import { MdPreliminary } from "./Icons/MdPreliminary";
import { MdCancel, MdCheckCircle, MdError, MdPending } from "react-icons/md";
import { Center, Tag, Text } from "@chakra-ui/react";
import { Icon } from "@chakra-ui/icons";
import React from "react";
export const JobStatusTag = ({ status }) => {
  const STATUS_ICON_MAP = {
    canceled: MdCancel,
    completed: MdCheckCircle,
    failed: MdError,
    ready: MdPreliminary,
    running: MdPending,
    submitted: MdPending
  };
  const isCompleted = status === "completed";
  const isFailed = status === "failed";
  const colorScheme = isCompleted ? "green" : isFailed ? "red" : "gray";
  const icon = STATUS_ICON_MAP[status] || MdPending;
  return /* @__PURE__ */ React.createElement(Tag, { colorScheme, size: "md", borderRadius: "5px" }, /* @__PURE__ */ React.createElement(Center, { h: "24px" }, /* @__PURE__ */ React.createElement(Icon, { as: icon, boxSize: "14px", viewBox: "0 0 14 14", mr: "6px" }), /* @__PURE__ */ React.createElement(Text, { textStyle: "body-9", textTransform: "lowercase" }, status)));
};
