import React from "react";
import PropTypes from "prop-types";
import stylingClassFromState from "./utils/stylingClassFromState";
const JSONArrow = ({ arrowStyle, expanded, nodeType, onClick }) => /* @__PURE__ */ React.createElement(
  "div",
  {
    className: `arrowContainer ${expanded ? "-expanded" : ""}`,
    onClick
  },
  /* @__PURE__ */ React.createElement(
    "span",
    {
      className: `arrow ${stylingClassFromState(
        nodeType
      )} ${stylingClassFromState(arrowStyle)}`
    }
  )
);
JSONArrow.propTypes = {
  arrowStyle: PropTypes.oneOf(["single", "double"]),
  expanded: PropTypes.bool.isRequired,
  nodeType: PropTypes.string.isRequired
};
JSONArrow.defaultProps = {
  arrowStyle: "single"
};
export default JSONArrow;
