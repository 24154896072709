const sampleProgram = `//first, we need to allocate a named register of qubits
qubit r[4];

// and declare any constants we might need 
float pi = 3.141592653589793238462;

//then, we need to add some gates...
:H(r[0]); //semicolons are required!

//note that when you reference the qubits in your register, they are zero-indexed. \u2014 referencing r[4] here would throw an error, because it would be the fifth qubit in the register, and we only delcared four to begin with.

//for most gates, you can reference a control qubit as the second argument
:NOT(r[3], r[0]);


//or an angle \u2014 sqore will intelligently guess which you mean.
:X(r[3], pi/2); 


//to write a gate with a control AND an angle, the angle goes third in the list of arguments
:Y(r[2], r[0], pi/4); 


//the swap gate takes two target qubits, grouped with curly braces.
:SWAP({r[1], r[3]}); 


//you can also specify multiple control qubits this way.
:NOT(r[2],{r[0], r[1]}); //hey, a toffoli!


// once there are no more gate operations to run, sqore implicitly measures all qubits, mapping the output of each qubit in the register to a classical bit in the same position in the bitstring.
//for more information on writing programs in sqore, examples of common circuits written in sqore, and basics on how quantum circuits work, check out the sqore user guide
`;
export default sampleProgram;
