export default function objType(keyOrIndex, obj) {
  const type = Object.prototype.toString.call(obj).slice(8, -1);
  const isURL = (str) => {
    var pattern = new RegExp("^https?://");
    return pattern.test(str);
  };
  if (type === "Object" && typeof obj[Symbol.iterator] === "function") {
    return "Iterable";
  }
  if (type === "WeakMap" || type === "WeakSet") {
    return "Object";
  }
  if (type === "Map" || type === "Set") {
    return "IterableLike";
  }
  if (type === "String" && isURL(obj)) {
    return "Link";
  }
  if (type === "String" && (obj === "true" || obj === "false")) {
    return "Boolean";
  }
  if (type === "Number" && (keyOrIndex === "request" || keyOrIndex === "response")) {
    return "Timestamp";
  }
  if (type === "Number" && (keyOrIndex === "compilation_time" || keyOrIndex === "execution_time")) {
    return "Duration";
  }
  if (type === "String" && keyOrIndex === "status") {
    switch (obj) {
      case "completed":
        return "StatusCompleted";
      case "canceled":
        return "StatusCanceled";
      case "errored":
        return "StatusErrored";
      case "submitted":
      case "ready":
        return "StatusPending";
      case "running":
        return "StatusRunning";
      default:
        return "String";
    }
  }
  return type;
}
