import { useState, useEffect } from "react";
import { useApi } from "../../../Api/ApiProvider";
export const useJobProgram = (jobId) => {
  const [isLoading, setIsLoading] = useState(true);
  const [program, setProgram] = useState([{}]);
  const [error, setError] = useState(null);
  const { Api, isLoading: isApiLoading } = useApi();
  useEffect(() => {
    if (!jobId || isApiLoading) return;
    setIsLoading(true);
    Api.getJobProgram({
      jobId,
      onSuccess: (res) => {
        setProgram(res);
        setIsLoading(false);
      },
      onError: (e) => {
        setError(e);
        setIsLoading(false);
      }
    });
  }, [jobId, Api, isApiLoading]);
  return {
    program,
    error,
    isLoading
  };
};
